import {createSlice, isAnyOf} from "@reduxjs/toolkit";
import {authApi} from '@api/authApi';
import {init} from '@redux/actions'

import matchUnauthorized from '@slices/matchers/matchUnauthorized';

import {
  selectAccessToken,
  selectRefreshToken,
  selectCreationTime,
  selectExpirationTime,
  selectIsAccessTokenRefreshing,
  selectIsAccessTokenExists,
  selectIsAccessTokenActive,
  selectAccessTokenLifetime,
} from '@redux/selectors';

const initialState = {
  accessToken: null,
  refreshToken: null,
  creationTime: null,
  expirationTime: null,
  isLoaded: false,
  isRefreshing: false,
};

const auth = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {

    saveCurrentAuthData(state) {
      const payload = {};
      payload.accessToken = state.accessToken;
      payload.refreshToken = state.refreshToken;
      payload.creationTime = state.creationTime;
      payload.expirationTime = state.expirationTime;

      localStorage.setItem('auth', JSON.stringify(payload));
    },

  },
  extraReducers: (builder) => {

    builder.addMatcher(
      init.match,
      function (state) {
        const auth = JSON.parse(localStorage.getItem('auth'));

        if (auth) {
          state.accessToken = auth.accessToken;
          state.refreshToken = auth.refreshToken;
          state.creationTime = auth.creationTime;
          state.expirationTime = auth.expirationTime;
          state.isLoaded = true;
        } else {
          state.isLoaded = false;
        }

        return state;
      },
    );

    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      function (state, action) {
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
        state.creationTime = action.payload.creationTime;
        state.expirationTime = action.payload.expirationTime;
        state.isLoaded = true;

        localStorage.setItem('auth', JSON.stringify(action.payload));

        return state;
      },
    );

    builder.addMatcher(
      authApi.endpoints.refreshToken.matchPending,
      function (state) {
        state.isRefreshing = true;
        return state;
      },
    );

    builder.addMatcher(
      authApi.endpoints.refreshToken.matchFulfilled,
      function (state, action) {
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
        state.creationTime = action.payload.creationTime;
        state.expirationTime = action.payload.expirationTime;
        state.isLoaded = true;
        state.isRefreshing = false;

        localStorage.setItem('auth', JSON.stringify(action.payload));

        return state;
      },
    );

    builder.addMatcher(
      isAnyOf(
        authApi.endpoints.refreshToken.matchRejected,
        matchUnauthorized,
      ),
      function (state) {
        state.isLoaded = false;
        state.isRefreshing = false;

        localStorage.setItem('auth', null);

        return state;
      },
    );

    builder.addMatcher(
      authApi.endpoints.refreshToken.matchRejected,
      function (state) {
        state.isLoaded = false;
        state.isRefreshing = false;

        localStorage.setItem('auth', null);

        return state;
      },
    );

  },
});

export default auth.reducer;

export const {
  saveCurrentAuthData,
} = auth.actions;

export {
  selectAccessToken,
  selectRefreshToken,
  selectCreationTime,
  selectExpirationTime,
  selectIsAccessTokenRefreshing,
  selectIsAccessTokenExists,
  selectIsAccessTokenActive,
  selectAccessTokenLifetime,
} from 'redux/selectors';
