import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {useTranslation} from "react-i18next";

import errors from "@yup/errors";
import validators from '@yup/index';

import FormFields from './FormFields';

import {
  useMemo,
  useCallback,
  useEffect,
} from 'react';

import {
  Formik,
  Form,
} from 'formik';

import {
  useLocation,
  useHistory,
} from 'react-router-dom';

import {
  useLazyChangePasswordQuery,
} from '@api/authApi';

import {
  AuthForm,
} from '@components/index';

const ResetPasswordSchema = Yup.object().shape({
  [FormFields.NEW_PASSWORD]: Yup
    .string()
    .required(errors.required)
    .concat(validators.password),
  [FormFields.CONFIRM_PASSWORD]: Yup
    .string()
    .required(errors.required)
    .concat(validators.password)
    .test(
      'passwords equality',
      errors.passwordsAreNotEqual,
      (value, context) => context.parent[FormFields.NEW_PASSWORD] === context.parent[FormFields.CONFIRM_PASSWORD]
    ),
});

const initialValues = {
  [FormFields.NEW_PASSWORD]: '',
  [FormFields.CONFIRM_PASSWORD]: '',
  ['form']: '',
};

ResetPasswordFragment.propTypes = {
  className: PropTypes.string,
  pathToMainScreen: PropTypes.string.isRequired,
};

function ResetPasswordFragment({className, pathToMainScreen, ...props}) {

  const {t} = useTranslation();

  const history = useHistory();

  const {search} = useLocation()

  const token = useMemo(
    () => new URLSearchParams(search).get('token'),
    [search],
  );

  const [
    triggerChangePassword,
    result,
  ] = useLazyChangePasswordQuery();

  const {
    error,
    isSuccess,
    isFetching,
  } = result || {};

  const onSubmitHandler = useCallback(
    (fields) => handleOnSubmit(fields, token, triggerChangePassword),
    [token],
  );

  useEffect(
    () => handleSuccess(isSuccess, isFetching, pathToMainScreen, history),
    [isSuccess, isFetching, pathToMainScreen, history]
  );

  return (
    <AuthForm
      {...props}
      className={className}
      title={t('reset-password-title')}
      description={t('reset-password-description')}
    >
      <Formik
        validationSchema={ResetPasswordSchema}
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
      >
        {({errors, touched, handleSubmit, setFieldError}) => {

          useEffect(
            () => handleServerSideError(error, setFieldError),
            [error],
          );

          return (
            <Form>
              <AuthForm.InputField
                name={FormFields.NEW_PASSWORD}
                type={'password'}
                autoComplete={'new-password'}
                label={t('New Password')}
              />
              <AuthForm.InputField
                name={FormFields.CONFIRM_PASSWORD}
                type={'password'}
                autoComplete={'new-password'}
                label={t('Confirm New Password')}
              />
              <AuthForm.FormError errors={errors} touched={touched}/>
              <AuthForm.Button
                onClick={handleSubmit}
                inputType={'submit'}
              >
                {t('Reset password')}
              </AuthForm.Button>
            </Form>
          );
        }}
      </Formik>
    </AuthForm>
  );
}

function handleOnSubmit(fields, token, triggerChangePassword) {
  triggerChangePassword({
    ...fields,
    token: token,
  });
}

function handleServerSideError(error, setFieldError) {
  if (!error || !error.fields) return;

  for (const field of  error.fields) {
    if (!field.error) continue;
    setFieldError(field.name, field.error);
  }
}

function handleSuccess(isSuccess, isFetching, pathToMainScreen, history) {
  if (isSuccess && !isFetching) {
    history.push(pathToMainScreen);
  }
}

export default ResetPasswordFragment;