import React from 'react';
import PropTypes from 'prop-types';

import {
  useMemo,
} from 'react';

import {
  Table,
} from "@components/index";

HeaderRow.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.object.isRequired,
};

function HeaderRow({className, columns, ...props}) {

  const isHeaderRowVisible = useMemo(
    () => getIsHeaderRowVisible(columns),
    [columns],
  );

  if (!isHeaderRowVisible) return null;

  return (
    <Table.HeaderRow {...props}>
      {
        Object.values(columns).map(column => {
          return (
            <Table.Cell
              {...column.titleCell.props}
              className={[
                column.className,
                className,
              ].join(' ')}
              type={column.titleCell.type}
              name={column.titleCell.name}
              value={column.titleCell.value}
              key={column.titleCell.name}
            />
          );
        })
      }
    </Table.HeaderRow>
  );
}

function getIsHeaderRowVisible(columns) {
  const filledHeader = Object
    .values(columns)
    .find(column => column.titleCell.value);

  return !!filledHeader;
}

export default HeaderRow;