import mappers from '@mappers/index';
import utils from '@utils/index';
import {api} from "@api/index";

const resource = 'dashboard';

export const documentsAPI = api.injectEndpoints({
  endpoints: (builder) => ({

    getMissingDocuments: builder.query({
      queryFn: async (arg, api1, extraOptions, baseQuery) => {
        const params = utils.createQueryParams();

        if (arg.page) {
          params.add('page', arg.page + 1);
        } else {
          params.add('page', 1);
        }

        const response = await baseQuery({
          url: resource + '/missing-documents',
          params: params.finish(),
        });

        const mapper  = utils.createPaginatedMapper(mappers.mapMissingDocuments, 'documents');

        return utils.handleQueryFnResponse(response, mapper);
      }
    }),

  }),
});

export const {
  useLazyGetMissingDocumentsQuery,
} = documentsAPI;