import mappers from '@mappers/index';
import utils from '@utils/index';
import tags from '@api/tags';
import {api} from "@api/index";

const resource = 'user';

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({

    getUser: builder.query({
      queryFn: async (arg, store, extraOptions, baseQuery) => {
        const response = await baseQuery(resource + '/');
        return utils.handleQueryFnResponse(response, mappers.mapUser);
      },
      providesTags: [tags.USER],
    }),

  }),
});

export const {
  useGetUserQuery,
} = userApi;