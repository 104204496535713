import React from "react";
import PropTypes from 'prop-types';

import {
  Table,
} from "@components/index";

RadioButtonRow.propTypes = {
  className: PropTypes.string,
  cells: PropTypes.object.isRequired,
  columns: PropTypes.object.isRequired,
  value: PropTypes.bool,
  componentsAdditionalProps: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};

RadioButtonRow.defaultProps = {
  componentsAdditionalProps: {},
};

/**
 *
 * @param className
 * @param cells - should has a Table.Cell.type.CHECKBOX type cell
 * @param columns
 * @param value
 * @param componentsAdditionalProps
 * @param onClick
 * @param props
 * @returns {JSX.Element}
 */
function RadioButtonRow({className, cells, columns, value, componentsAdditionalProps, onClick, ...props}) {
  return (
    <Table.Row {...props} onClick={onClick}>
      {
        Object.values(columns).map(column => {

          const cell = cells[column.name] || {};

          const finalValue = cell.type === Table.Cell.type.RADIO_BUTTON
            ? value
            : cell.value;

          return (
            <Table.Cell
              {...cell.props}
              {...componentsAdditionalProps[cell.type]}
              className={[
                className,
                column.className,
              ].join(' ')}
              type={cell.type}
              value={finalValue}
              key={column.name}
              onChange={onClick}
            />
          );
        })
      }
    </Table.Row>
  );
}

export default RadioButtonRow;