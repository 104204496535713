import './AuthForm.scss';

import React from 'react';
import PropTypes from 'prop-types';

FormError.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.object.isRequired,
};

function FormError({className, errors, touched, ...props}) {

  const formErrorMessage = touched['form'] && errors['form'];

  if (!formErrorMessage) return null;

  return (
    <p
      {...props}
      className={[
        'auth-form__form-error',
        className,
      ].join(' ')}
    >
      {formErrorMessage}
    </p>
  );
}

export default FormError;