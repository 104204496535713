class SelectOptionView {
  static default = {
    value: null,
    label: '',
  }

  static parse(model) {
    if (!model) return SelectOptionView.default;
    return {
      value: model.id,
      label: model.name,
    };
  }
}

export default SelectOptionView;