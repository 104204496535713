import i18next from 'i18next';

import DefaultDefaultTransactionColumnType from "@types/DefaultTransactionColumnType";

import mapInvoiceLinks from './mapInvoiceLinks';

import {
  createTextCell,
  createInvoicesCell,
  createBookingCell,
  createBackLightedTextCell,
} from '@utils/dataCreators';

export default function mapDefaultTransactions(transactions, pagination) {
  return transactions.map((transaction, key) => {

    const index = pagination.currentPage * pagination.pageSize + key + 1;

    const
      isOpenAmountExists = !!transaction.open_amount,
      isStatusNotACashRegister = transaction.status_key !== 5,
      isStatusNotPrivate = transaction.status_key !== 6;

    return {
      id: transaction.id,
      cells: {
        [DefaultDefaultTransactionColumnType.type.INDEX]:
          createTextCell(DefaultDefaultTransactionColumnType.type.INDEX, String(index)),

        [DefaultDefaultTransactionColumnType.type.INVOICE]:
          isStatusNotACashRegister
            ? (
              createInvoicesCell(
                DefaultDefaultTransactionColumnType.type.INVOICE,
                mapInvoiceLinks(transaction.invoice),
                createAddButtonPayload(transaction.id, transaction.open_amount),
                isOpenAmountExists && isStatusNotACashRegister && isStatusNotPrivate,
              )
            )
            : (
              createTextCell(
                DefaultDefaultTransactionColumnType.type.INVOICE,
                i18next.t('Cash Register'),
              )
            ),

        [DefaultDefaultTransactionColumnType.type.BOOKING]:
          createBookingCell(DefaultDefaultTransactionColumnType.type.BOOKING, String(transaction.status_key)),

        [DefaultDefaultTransactionColumnType.type.NAME]:
          createTextCell(DefaultDefaultTransactionColumnType.type.NAME, transaction.name),

        [DefaultDefaultTransactionColumnType.type.USAGE]:
          createTextCell(DefaultDefaultTransactionColumnType.type.USAGE, transaction.description),

        [DefaultDefaultTransactionColumnType.type.DATE]:
          createTextCell(DefaultDefaultTransactionColumnType.type.DATE, transaction.payment_date), // todo: map date

        [DefaultDefaultTransactionColumnType.type.PRICE]:
          createAmountCell(DefaultDefaultTransactionColumnType.type.PRICE, transaction.amount),

        [DefaultDefaultTransactionColumnType.type.LEFT_PRICE]:
          createTextCell(DefaultDefaultTransactionColumnType.type.LEFT_PRICE, transaction.open_amount),
      }
    };
  });
}

function createAmountCell(column, amount) {
  const value = mapAmountToNumber(amount);

  if (value > 0) {
    return createBackLightedTextCell(column, amount);
  }

  return createTextCell(column, amount);
}

function mapAmountToNumber(amount) {
  try {
    const cleared = amount
      .replace('€', '')
      .replace('EUR', '')
      .replaceAll('.', '')
      .replace(',', '.')
      .trim();

    return Number(cleared);
  } catch (error) {
    return NaN;
  }
}

function createAddButtonPayload(transactionId, openAmount) {
  return {
    transactionId,
    openAmount,
  };
}
