import {selectLanguage} from "@slices/locales";

function addLanguageHeader(headers, api) {
  const
    state = api.getState(),
    language = selectLanguage(state);

  headers.set('language', language);
}

export default addLanguageHeader;