import React from 'react';
import PropTypes from 'prop-types';

import {
  Booking,
} from '@components/index';

import '../Table.scss';

BookingCell.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string.isRequired,
};

function BookingCell({className, status, ...props}) {
  return (
    <Booking
      {...props}
      className={[
        'table__booking-cell',
        className,
      ].join(' ')}
      status={status}
    />
  );
}

export default BookingCell;