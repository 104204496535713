import React from 'react';
import PropTypes from 'prop-types';

import {
  Title,
} from '@components/index';

import './Popup.scss';
import svgs from '@assets/svgs';

Header.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  Icon: PropTypes.func,
  onCloseButtonClick: PropTypes.func.isRequired,
};

function Header({className, title, Icon, onCloseButtonClick, ...props}) {

  const isIconGot = !!Icon;

  return (
    <header
      {...props}
      className={[
        'popup__header',
        isIconGot ? 'popup__header_with_icon' : null,
        className,
      ].join(' ')}
    >
      {
        isIconGot
          ? <Icon className={'popup__icon'}/>
          : null
      }
      <Title
        textProps={{
          className: 'popup__title',
        }}
      >{title}</Title>
      <svgs.Close className={'popup__close-button'} onClick={onCloseButtonClick}/>
    </header>
  );
}

export default Header;