import React from "react";
import PropTypes from "prop-types";

import {
  useCallback,
  useEffect,
  useRef,
} from 'react';

import {
  useTableObject,
} from "@hooks/index";

import {
  PaginatedTable,
} from "@components/index";

const propTypes = {
  additionalSearchParams: PropTypes.object,
};

const defaultProps = {
  additionalSearchParams: {},
};

function WithContainerTable(PaginatedTable, useLazyQuery, createColumns, getEntriesAndPagination) {
  WithContainerTableNewComponent.propTypes = propTypes;
  WithContainerTableNewComponent.defaultProps = defaultProps;

  function WithContainerTableNewComponent({additionalSearchParams, ...props}) {
    const [
      trigger,
      result,
      lastPromiseInfo,
    ] = useLazyQuery();

    const {
      data: response,
      isFetching,
    } = result || {};

    const {
      entries,
      pagination,
    } = getEntriesAndPagination(response);

    const {currentPage, pageSize, totalCount} = pagination || {};

    const table = useTableObject(createColumns, entries);

    const onSearchSubmitHandler = useCallback(
      (fields) => handleSearchSubmit(fields, lastPromiseInfo, additionalSearchParams, trigger),
      [lastPromiseInfo, additionalSearchParams],
    );

    const onPageChangeHandler = useCallback(
      (page) => handlePageChange(page, trigger, additionalSearchParams, trigger),
      [lastPromiseInfo, additionalSearchParams],
    );

    const onPageChangeHandlerRef = useRef(onPageChangeHandler);
    onPageChangeHandlerRef.current = onPageChangeHandler;

    useEffect(
      () => trigger({...additionalSearchParams}),
      [],
    );

    return (
      <PaginatedTable
        {...props}
        table={table}
        isLoading={isFetching}
        currentPage={currentPage || 0}
        pageSize={pageSize}
        totalCount={totalCount}
        onSubmit={onSearchSubmitHandler}
        onCurrentPageChange={onPageChangeHandler}
      />
    );
  }

  return WithContainerTableNewComponent;
}

function handleSearchSubmit(fields, lastPromiseInfo, additionalSearchParams, trigger) {
  trigger({
    ...lastPromiseInfo.lastArg,
    ...additionalSearchParams,
    ...fields,
  });
}

function handlePageChange(page, lastPromiseInfo, additionalSearchParams, trigger) {
  trigger({
    ...lastPromiseInfo.lastArg,
    ...additionalSearchParams,
    page: page,
  });
}

export default WithContainerTable;