import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

import types from '@types/index';

import {
  BlockMessage,
} from '@components/index';

AvailabilityMessage.propTypes = {
  className: PropTypes.string,
  availability: types.Availability.isRequired,
  onCloseButtonClick: PropTypes.func.isRequired,
};

function AvailabilityMessage({className, availability, onCloseButtonClick, ...props}) {

  const {t} = useTranslation();

  if (availability.isConnectionAvailable && availability.amount === availability.invoicesAmount) return null;

  if (availability.isConnectionAvailable) {
    return (
      <BlockMessage
        {...props}
        className={[
          'invoice-checkbox__availability-message',
          className,
        ].join(' ')}
        type={BlockMessage.type.ATTENTION}
        onCloseButtonClick={onCloseButtonClick}
      >
        <BlockMessage.Paragraph>{t('The amount in invoice does not match with transaction')}:</BlockMessage.Paragraph>
        <BlockMessage.Paragraph>{t('Amount invoice')}: {availability.invoicesAmount}</BlockMessage.Paragraph>
        <BlockMessage.Paragraph>{t('Amount transaction')}: {availability.amount}</BlockMessage.Paragraph>
      </BlockMessage>
    );
  }

  return (
    <BlockMessage
      {...props}
      className={[
        'invoice-checkbox__availability-message',
        className,
      ].join(' ')}
      type={BlockMessage.type.ERROR}
      onCloseButtonClick={onCloseButtonClick}
    >
      {t('The amount should not be more than')} {availability.amount}
    </BlockMessage>
  );
}

export default AvailabilityMessage;