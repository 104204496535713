import React from 'react';
import PropTypes from 'prop-types';

import '../Table.scss';

HighlightedTextCell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
};

function HighlightedTextCell({className, children, ...props}) {
  return (
    <div
      {...props}
      className={[
        'table__highlighted-text-cell',
        className,
      ].join(' ')}
    >
      <div className={'highlighted-text-cell'}>
        <p className={'highlighted-text-cell__text'}>{children}</p>
      </div>
    </div>
  );
}

export default HighlightedTextCell;
