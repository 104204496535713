import PropTypes from "prop-types";

const options = {
  INDEX: 'INDEX',
  STATUS: 'STATUS',
  INVOICE: 'INVOICE',
  RECEIPT: 'RECEIPT',
  BANK_NAME: 'BANK_NAME',
  USAGE: 'USAGE',
  DATE: 'DATE',
  NET_AMOUNT: 'NET_AMOUNT',
  GROSS_AMOUNT: 'GROSS_AMOUNT',
  RECEIPT_TYPE: 'RECEIPT_TYPE',
}

const BookingColumnType = {
  propType: PropTypes.oneOf(Object.values(options)),
  type: options,
};

export default BookingColumnType;