import {createSlice} from "@reduxjs/toolkit";
import {nanoid} from "nanoid";

const initialState = {
  scheduledRedirectionId: null,
  entities: {},
};

const redirection = createSlice({
  name: 'redirection',
  initialState: initialState,
  reducers: {

    schedule: (state, action) => {
      const scheduledRedirectionId = getScheduledRedirectionId(state);

      const redirection = action.payload;
      redirection.id = nanoid();

      if (scheduledRedirectionId) {
        redirection.description = 'Another redirection has already been planned';
      } else if (!redirection.url) {
        redirection.description = 'The redirection is incorrect';
      } else {
        state.scheduledRedirectionId = redirection.id;
      }

      state.entities[redirection.id] = redirection;
    }
  },
});

export default redirection.reducer;

export const {
  schedule,
} = redirection.actions;

export const getScheduledRedirectionId = (state) => state.scheduledRedirectionId;
export const getEntities = (state) => state.entities;

export const selectScheduledRedirectionI = (state) => getScheduledRedirectionId(state.redirection);
export const selectEntities = (state) => getEntities(state.redirection);

export const selectRedirectionUrl = (state) => {
  const scheduledRedirectionId = selectScheduledRedirectionI(state);
  const entities = selectEntities(state);

  if (!scheduledRedirectionId) return;

  return entities[scheduledRedirectionId].url;
};