import React from 'react';
import {nanoid} from "nanoid";

import createLogColumns from './createLogColumns';

import {
  useState,
  useCallback,
} from 'react';

import {
  LogPopup,
} from '@components/index';

function WithLogPopup(Component) {
  function WithLogPopupNewComponent(props) {

    const [info, setInfo] = useState({});

    const onShowLogPopupHandler = useCallback(
      () => handleOnShow(setInfo),
      [],
    );

    const onHideLogPopupHandler = useCallback(
      () => handleOnHide(setInfo),
      [],
    );

    return (
      <>
        <Component
          {...props}
          onShowLogPopup={onShowLogPopupHandler}
        />
        {
          info.key
            ? (
              <LogPopup
                key={info.key}
                isVisible={info.isVisible}
                columnsFactory={createLogColumns}
                onClose={onHideLogPopupHandler}
              />
            )
            : null
        }
      </>
    );
  }

  return WithLogPopupNewComponent;
}

function handleOnShow(setInfo) {
  setInfo({
    key: nanoid(),
    isVisible: true,
  });
}

function handleOnHide(setInfo) {
  setInfo((prevState) => ({
    ...prevState,
    isVisible: false,
  }));
}

export default WithLogPopup;