import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import {
  useMemo,
  useCallback,
} from 'react';

import {
  Formik,
} from 'formik';

import {
  Table,
} from "@components/index";

import {
  useSubmitWithFrequencyLimit,
} from "@hooks/index";

FormRow.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.object.isRequired,
  componentsAdditionalProps: PropTypes.object,
  columnsAdditionalProps: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

FormRow.defaultProps = {
  componentsAdditionalProps: {},
  columnsAdditionalProps: {},
};

function FormRow({className, columns, componentsAdditionalProps, columnsAdditionalProps, onSubmit, ...props}) {

  const defaults = useMemo(
    () => getDefaultValues(columns),
    [columns],
  );

  const validationSchema = useMemo(
    () => getValidationSchema(columns),
    [columns],
  );

  const isVisible = useMemo(
    () => getIsVisible(columns),
    [columns],
  );

  if (!isVisible) return null;

  return (
    <Table.Row {...props}>
      <Formik
        initialValues={defaults}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={onSubmit}
      >
        {({values, errors, handleChange, handleSubmit}) => {

          const [submitCallback] = useSubmitWithFrequencyLimit(handleSubmit, 1000);

          const onChangeHandler = useCallback(
            (...args) => handleChange(...args),
            [handleChange],
          );

          useEffect(
            () => submitCallback(),
            [values, submitCallback],
          );

          return (
            Object.values(columns).map(column => {
              return (
                <Table.Cell
                  fieldProps={{
                    ...column.fieldCell.props,
                    ...componentsAdditionalProps[column.fieldCell?.props?.type],
                    ...columnsAdditionalProps[column.name],
                  }}
                  className={[
                    column.className,
                    className,
                  ].join(' ')}
                  type={column.fieldCell.type}
                  name={column.name}
                  value={values[column.fieldCell.name]}
                  error={errors[column.fieldCell.name]}
                  key={column.name}
                  onChange={onChangeHandler}
                />
              );
            })
          )
        }}
      </Formik>
    </Table.Row>
  );
}

function getDefaultValues(columns) {
  return Object.keys(columns).reduce((defaults, columnName) => {

      let value = '';

      if (columns[columnName].fieldCell.type === Table.Cell.type.DATE_RANGE_PICKER_FIELD) {
        value = [];
      }

      defaults[columnName] = value;

      return defaults;

    }, {});
}

function getValidationSchema(columns) {
  const shape = Object.keys(columns).reduce((schema, columnName) => {

    const currentColumn = columns[columnName];

    if (!currentColumn.fieldCell) return schema;

    schema[columnName] = currentColumn.fieldCell.validator;

    return schema;

  }, {});

  return Yup.object().shape(shape);
}

function getIsVisible(columns) {
  const field = Object.values(columns).find(column => column.fieldCell.type);
  return !!field;
}

export default FormRow;