import React from 'react';

import {
  useState,
  useCallback,
} from 'react';

import {
  useLazyGetInvoiceQuery,
} from '@api/invoice';

import {
  ViewInvoicePopup,
} from "@components/index";

function WithViewInvoicePopup(Component) {
  function NewComponent (props) {

    const [currentInvoiceId, setCurrentInvoiceId] = useState(-1);

    const isOpen = currentInvoiceId > -1;

    const [
      triggerGetInvoice,
      {
        data: invoice,
        isSuccess: isInvoiceLoaded,
        isFetching: isInvoiceFetching,
      },
    ] = useLazyGetInvoiceQuery();

    const showHandler = useCallback(
      (invoiceId) => handleOnShow(invoiceId, triggerGetInvoice, setCurrentInvoiceId),
      [triggerGetInvoice, setCurrentInvoiceId],
    );

    const hideHandler = useCallback(
      () => handleOnHide(setCurrentInvoiceId),
      [setCurrentInvoiceId],
    );

    const isCurrentInvoiceLoaded = String(currentInvoiceId) === invoice?.id;

    return (
      <>
        <Component
          {...props}
          onShowViewInvoicePopup={showHandler}
        />
        {
          isInvoiceLoaded && !isInvoiceFetching && isOpen
            ? (
              <ViewInvoicePopup
                key={invoice}
                id={invoice.id}
                isVisible={isCurrentInvoiceLoaded}
                pdfPath={invoice.filePath}
                info={invoice.info}
                amount={invoice.amount}
                onBackgroundClick={hideHandler}
                onCloseButtonClick={hideHandler}
              />
            )
            : null
        }
      </>
    );
  }

  return NewComponent;
}

function handleOnShow(invoiceId, triggerGetInvoice, setCurrentInvoiceId) {
  if (invoiceId < -1) return;

  triggerGetInvoice(invoiceId)
  setCurrentInvoiceId(invoiceId);
}

function handleOnHide(setCurrentInvoiceId) {
  setCurrentInvoiceId(-1);
}

function getPdf(invoice, triggerGetPdf) {
  if (!invoice || !invoice.filePath) return;

  triggerGetPdf(invoice.filePath);
}

export default WithViewInvoicePopup;