import i18n from 'i18next';

import {
  initReactI18next,
} from 'react-i18next';

import {
  registerLocale,
} from 'react-datepicker';

import {
  de as datepickerDe,
} from "date-fns/locale";

import de from '@assets/locales/de/index.json';

const developmentResource = {
  translation: de,
};

i18n
  .use(initReactI18next)
  .init({
    lng: "de",
    debug: false,
    resources: {
      development: developmentResource,
    },
    interpolation: {
      escapeValue: false,
    },
    keySeparator: '>',
    nsSeparator: '|',
  });

registerLocale('de', datepickerDe);
// registerLocale('en', datepickerEn);

export default i18n;