import React from 'react';

import GlobalLoader from "./GlobalLoader";

import {
  userApi,
} from '@api/user';

import {
  localesAPI,
} from '@api/locales';

function GlobalLoaderGate({children}) {

  const {
    isLoading: isUserLoading,
  } = userApi.endpoints.getUser.useQueryState();

  const {
    isUninitialized: isLocalUninitialized,
    isLoading: isLocaleLoading,
  } = localesAPI.endpoints.getLocal.useQueryState();

  return (
    <>
      {children}
      <GlobalLoader isVisible={isUserLoading || isLocaleLoading || isLocalUninitialized}/>
    </>
  );
}

export default GlobalLoaderGate;