import PropTypes from "prop-types";

const options = {
  EMPLOYEE: 'EMPLOYEE',
  MISSING_FILES: 'MISSING_FILES',
};

const MissingDocumentColumn = {
  propType: PropTypes.oneOf(Object.values(options)),
  type: options,
};

export default MissingDocumentColumn;

