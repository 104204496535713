import './TopBar.scss';

import React from 'react';
import {useTranslation} from "react-i18next";

import createLanguages from './createLanguages';

import {
  useMemo,
  useCallback,
} from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  useWithDispatchCallback,
} from '@hooks/index';

import {
  selectLanguage,
  setLanguage,
} from '@slices/locales';

import {
  selectName,
} from '@slices/user';

import {
  LanguageSelect,
  UserButton,
} from '@components/index';

function TopBar({className, ...props}) {

  const {t} = useTranslation();

  const languageName = useSelector(selectLanguage);

  const userName = useSelector(selectName);

  const languages = useMemo(
    () => createLanguages(t),
    [t],
  );

  const language = useMemo(
    () => getCurrentLanguage(languageName, languages),
    [languageName, languages]
  );

  const setLanguageCallback = useWithDispatchCallback(setLanguage);

  const onChangeHandler = useCallback(
    (language) => setLanguageCallback(language.name),
    [setLanguageCallback],
  );

  return (
    <div
      {...props}
      className={[
        'top-bar',
        className,
      ].join(' ')}
    >
      <LanguageSelect
        className={'top-bar__language-select'}
        activeLanguage={language}
        languages={languages}
        onChange={onChangeHandler}
      />
      <UserButton name={userName}/>
    </div>
  );
}

function getCurrentLanguage(languageName, languages) {
  return languages.find((l) => l.name === languageName);
}

export default TopBar;