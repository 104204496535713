import {createSelector} from '@reduxjs/toolkit';

export const selectAccessToken = (state) => state.auth.accessToken;
export const selectRefreshToken = (state) => state.auth.refreshToken;
export const selectCreationTime = (state) => state.auth.creationTime;
export const selectExpirationTime = (state) => state.auth.expirationTime;

export const selectIsAccessTokenLoaded = (state) => state.auth.isLoaded;
export const selectIsAccessTokenRefreshing = (state) => state.auth.isRefreshing;

export const selectIsAccessTokenExists = createSelector(
  selectAccessToken,
  selectIsAccessTokenLoaded,
  (accessToken, isAccessTokenLoaded) => isAccessTokenLoaded && !!accessToken,
);

export const selectAccessTokenLifetime = createSelector(
  selectCreationTime,
  selectExpirationTime,
  (creationTime, expirationTime) => {
    if (!creationTime) return 0;
    if (!expirationTime) return 0;

    const creationDate = new Date(creationTime);
    const expirationDate = new Date(expirationTime);

    return expirationDate.getTime() - creationDate.getTime();
  },
);

export const selectIsAccessTokenActive = (state) => {
  const expirationTime = selectExpirationTime(state);
  const lifetime = selectAccessTokenLifetime(state);

  if (!expirationTime) return false;

  const expirationDate = new Date(expirationTime + '+00:00');

  const delta = expirationDate.getTime() - Date.now();

  const minDelta = lifetime > 10*1000 ? 2*1000 : 0;

  return delta > minDelta;
}