import {
  useMemo,
} from 'react';

function useCurrentErrorSelector(result) {
  if (!result) return null;

  const {
    error,
    isFetching,
    isError,
  } = result;

  const currentError = useMemo(
    () => getCurrentError(error, isFetching, isError),
    [error, isFetching, isError],
  );

  return currentError;
}

function getCurrentError(error, isFetching, isError) {
  if (isFetching) return null;
  if (!isError) return null;
  return error;
}

export default useCurrentErrorSelector;