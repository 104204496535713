import React from 'react';
import PropTypes from 'prop-types'

import './UserButton.scss';
import svgs from "@assets/svgs";

UserButton.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  Icon: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]),
};

UserButton.defaultProps = {
  Icon: svgs.Profile,
};

function UserButton({className, name, Icon, ...props}) {
  return (
    <button
      {...props}
      className={[
        'user-button',
        className,
      ].join(' ')}
    >
      <Icon className={'user-button__icon'}/>
      <p className={'user-button__name'}>{name}</p>
      <svgs.Chevron className={'user-button__arrow'}/>
    </button>
  );
}

export default UserButton;