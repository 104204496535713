import './ConnectInvoicePopup.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

import types from '@types/index';
import createInvoiceColumns from "./createInvoiceColumns";
import AvailabilityMessage from './AvailabilityMessage';

import {
  useState,
  useEffect,
  useCallback,
} from 'react';

import {
  Popup,
  Button,
  Pagination,
  AutoGeneratedCheckboxTable,
} from '@components/index';

import {
  useTableObject,
} from '@hooks/index';

ConnectInvoicePopup.propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  invoices: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  availability: types.Availability,
  isAvailabilityFetching: PropTypes.bool,
  isConnectButtonLoading: PropTypes.bool,
  onConnectButtonClick: PropTypes.func.isRequired,
  onCloseButtonClick: PropTypes.func.isRequired,
  onBackgroundClick: PropTypes.func.isRequired,
  onCurrentPageChange: PropTypes.func.isRequired,
  onSearchFormSubmit: PropTypes.func.isRequired,
  onSelectInvoicesFormSubmit: PropTypes.func.isRequired,
};

ConnectInvoicePopup.defaultProps = {
  invoices: [],
  currentPage: 1,
  pageSize: 0,
  totalCount: 0,
};

function ConnectInvoicePopup({
                               className,
                               isVisible,
                               invoices,
                               currentPage,
                               pageSize,
                               totalCount,
                               isLoading,
                               availability,
                               isAvailabilityFetching,
                               isConnectButtonLoading,
                               onConnectButtonClick,
                               onCloseButtonClick,
                               onBackgroundClick,
                               onCurrentPageChange,
                               onSearchFormSubmit,
                               onSelectInvoicesFormSubmit,
                               ...props}) {

  const [isAvailabilityVisible, setIsAvailabilityVisible] = useState(false);
  const [lastSelectInvoicesFormSubmitResult, setLastSelectInvoicesFormSubmitResult] = useState(false);

  const {t} = useTranslation();

  const table = useTableObject(createInvoiceColumns, invoices);

  const onCloseButtonClickHandler = useCallback(
    () => handleOnCloseButtonClick(setIsAvailabilityVisible),
    [setIsAvailabilityVisible],
  );

  const onConnectButtonClickHandler = useCallback(
    () => handleOnConnectButtonClick(
      isAvailabilityFetching,
      lastSelectInvoicesFormSubmitResult,
      availability,
      onConnectButtonClick,
      setIsAvailabilityVisible,
    ),
    [
      isAvailabilityFetching,
      lastSelectInvoicesFormSubmitResult,
      availability,
      onConnectButtonClick,
    ],
  );

  const onSelectInvoicesFormSubmitHandler = useCallback(
    (fields) => handleOnSelectInvoicesFormSubmit(fields, onSelectInvoicesFormSubmit, setLastSelectInvoicesFormSubmitResult),
    [onSelectInvoicesFormSubmit],
  );

  useEffect(
    () => handleNewAvailabilityEffect(availability, setIsAvailabilityVisible),
    [availability],
  );

  useEffect(
    () => handleNewInvoicesEffect(invoices, setLastSelectInvoicesFormSubmitResult),
    [invoices],
  );

  return (
    <Popup {...props} isVisible={isVisible} onBackgroundClick={onBackgroundClick}>
      <Popup.Container className={'connect-invoice-popup__container'}>
        <Popup.Header title={'Connect Invoice'} onCloseButtonClick={onCloseButtonClick}/>
        <Popup.Content
          className={'connect-invoice-popup__content'}
          isLoading={isConnectButtonLoading}
        >
          {
            isAvailabilityVisible && availability
              ? <AvailabilityMessage availability={availability} onCloseButtonClick={onCloseButtonClickHandler}/>
              : null
          }
          <AutoGeneratedCheckboxTable
            className={'connect-invoice-popup__table'}
            isLoading={isLoading}
            table={table}
            onSearchFormSubmit={onSearchFormSubmit}
            onSelectInvoicesFormSubmit={onSelectInvoicesFormSubmitHandler}
          />
          <Pagination
            className={'connect-invoice-popup__pagination'}
            selectedPageIndex={currentPage}
            pageSize={pageSize}
            fullAmount={totalCount}
            isLoading={isLoading}
            onPageNumberClick={onCurrentPageChange}
          />
        </Popup.Content>
        <Popup.Footer className={'connect-invoice-popup__footer'}>
          <Button
            className={'connect-invoice-popup__footer-button'}
            onClick={onConnectButtonClickHandler}
            isLoading={isConnectButtonLoading}
          >
            {t('Connect Invoice')}
          </Button>
          <Button
            className={'connect-invoice-popup__footer-button'}
            type={Button.type.PRIMARY_OUTLINE}
            onClick={onCloseButtonClick}
          >
            {t('Cancel')}
          </Button>
        </Popup.Footer>
      </Popup.Container>
    </Popup>
  );
}

function handleOnCloseButtonClick(setIsAvailabilityVisible) {
  setIsAvailabilityVisible(false);
}

function handleNewAvailabilityEffect(availability, setIsAvailabilityVisible) {
  if (availability) {
    setIsAvailabilityVisible(true);
  }
}

function handleOnConnectButtonClick(
  isAvailabilityFetching,
  lastSelectInvoicesFormSubmitResult,
  availability,
  onConnectButtonClick,
  setIsAvailabilityVisible) {

  if (isAvailabilityFetching) return;

  if (availability && availability.isConnectionAvailable) {
    onConnectButtonClick(lastSelectInvoicesFormSubmitResult);
  } else {
    setIsAvailabilityVisible(true);
  }
}

function handleOnSelectInvoicesFormSubmit(fields, onSelectInvoicesFormSubmit, setLastSelectInvoicesFormSubmitResult) {
  setLastSelectInvoicesFormSubmitResult(fields);
  onSelectInvoicesFormSubmit(fields);
}

function handleNewInvoicesEffect(invoices, setLastSelectInvoicesFormSubmitResult) {
  setLastSelectInvoicesFormSubmitResult({
    checkedList: [],
  });
}

export default ConnectInvoicePopup;