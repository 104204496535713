import PropTypes from 'prop-types';

const options = {
  OPEN: '1',
  FINISHED: '2',
};

const PaymentStatus = {
  propType: PropTypes.oneOf(Object.values(options)),
  status: options,
};

export default PaymentStatus;