import {nanoid} from "nanoid";

import LogColumn from "@types/LogColumn";

import {
  createTextCell,
} from '@utils/dataCreators';

function mapLogs(response) {
  return response.map((description) => ({
    id: nanoid(),
    cells: {

      [LogColumn.type.QUICK_DESCRIPTION]:
        createTextCell(
          LogColumn.type.QUICK_DESCRIPTION,
          description,
        ),

    }
  }));
}

export default mapLogs;