import React from 'react';
import {useTranslation} from "react-i18next";

import {
  InputField,
  SelectField,
  OutputFormikSelectField,
} from "@components/index";

function CreateInvoicePaymentForm({
                                    receiverOptions,
                                    userOptions,
                                    descriptionOptions,
                                    typeTransferOptions,
                                    isTypeTransferOptionsLoading,
                                    onGetTypeTransferOptions,
                                  }) {

  const {t} = useTranslation();

  return (
    <>
      <OutputFormikSelectField
        className={'create-payment-popup__field'}
        name={'receiver'}
        label={t('Receiver')}
        options={receiverOptions}
      />
      <OutputFormikSelectField
        className={'create-payment-popup__field'}
        name={'name'}
        label={t('Name')}
        options={userOptions}
      />
      <OutputFormikSelectField
        className={'create-payment-popup__field'}
        name={'description'}
        label={t('Description')}
        options={descriptionOptions}
      />
      <InputField
        className={'create-payment-popup__field'}
        name={'amount'}
        label={t('Amount')}
      />
      <SelectField
        className={'create-payment-popup__field'}
        name={'typeTransfer'}
        label={t('Type Transfer')}
        options={typeTransferOptions}
        isLoading={isTypeTransferOptionsLoading}
        onGetOptions={onGetTypeTransferOptions}
      />
      <InputField
        className={'create-payment-popup__field'}
        name={'usage'}
        label={t('Usage')}
      />
    </>
  );
}

export default CreateInvoicePaymentForm;