import PaymentColumnType from '@types/PaymentColumnType';

import {
  createTextCell,
  createConfirmUserCell
} from "@utils/dataCreators";

export default function mapPayments(payments) {
  console.log('payment', payments);
  return payments.map((payment) => ({
    id: payment.id,
    recipientId: payment.recipient_id,
    cells: {
      [PaymentColumnType.type.STATUS]:
        createTextCell([PaymentColumnType.type.STATUS], payment.status),

      [PaymentColumnType.type.RECEIVER]:
        createTextCell([PaymentColumnType.type.RECEIVER], payment.receiver),

      [PaymentColumnType.type.AMOUNT]:
        createTextCell([PaymentColumnType.type.AMOUNT], payment.amount),

      [PaymentColumnType.type.DESCRIPTION]:
        createTextCell([PaymentColumnType.type.DESCRIPTION], payment.description),

      [PaymentColumnType.type.USAGE]:
        createTextCell([PaymentColumnType.type.USAGE], payment.usage),

      [PaymentColumnType.type.INVOICE_PREVIEW]:
        createTextCell([PaymentColumnType.type.INVOICE_PREVIEW], payment.invoiceFilePath),

      [PaymentColumnType.type.CONFIRM_PAYMENTS]:
        createConfirmUserCell(
          [PaymentColumnType.type.CONFIRM_PAYMENTS],
          payment.id,
          mapConfirmUsers(payment.confirm_user),
        ),
      },
  }));
}

export function mapConfirmUsers(users) {
  return users.map(user => ({
    id: user.id,
    name: user.name,
    hasConfirmed: !!user.status,
  }));
}