import React from 'react';
import {nanoid} from 'nanoid';

import {
  useState,
  useCallback,
  useEffect,
} from 'react';

import {
  useLazyGetAvailableInvoicesByTransactionIdQuery,
} from '@api/invoice';

import {
  useLazyGetInvoicesConnectionAvailabilityQuery,
  useConnectInvoicesMutation,
  useMoveTransactionIntoCashRegisterMutation,
  useMakeTransactionPrivateMutation,
} from '@api/transactions';

import {
  ConnectInvoicePopup,
  SmallConnectInvoicePopup,
} from '@components/index';

function WithConnectInvoicePopup(Component) {
  function NewComponentWithConnectInvoicePopup(props) {

    const [smallPopupInfo, setSmallPopupInfo] = useState({});
    const [largePopupInfo, setLargePopupInfo] = useState({});

    const isSmallVisible = !!smallPopupInfo.isVisible;
    const isLargeVisible = !!largePopupInfo.isVisible;

    const [
      triggerGetInvoices,
      invoicesResult,
      invoicesLastPromiseInfo,
    ] = useLazyGetAvailableInvoicesByTransactionIdQuery();

    const {
      data: invoicesResponse,
      isFetching: areInvoicesFetching,
    } = invoicesResult;

    const {
      invoices,
      pagination,
    } = invoicesResponse || {};

    const {
      currentPage,
      totalCount,
      pageSize,
    } = pagination || {};

    const [
      triggerGetConnectionAvailability,
      availabilityResult,
    ] = useLazyGetInvoicesConnectionAvailabilityQuery();

    const {
      data: availability,
      isFetching: isAvailabilityFetching,
    } = availabilityResult;

    const [
      triggerConnectInvoices,
      connectInvoiceResult,
    ] = useConnectInvoicesMutation();

    const {
      isSuccess: isConnectInvoiceSuccess,
      isLoading: isConnectInvoiceLoading,
    } = connectInvoiceResult;

    const [
      triggerMoveTransactionIntoCashRegister,
      moveTransactionIntoCashRegisterResult,
    ] = useMoveTransactionIntoCashRegisterMutation();

    const {
      isSuccess: isMoveTransactionIntoCashRegisterSuccess,
      isLoading: isMoveTransactionIntoCashRegisterLoading,
    } = moveTransactionIntoCashRegisterResult || {};

    const [
      triggerMakeTransactionPrivate,
      makeTransactionPrivateResult,
    ] = useMakeTransactionPrivateMutation();

    const {
      isSuccess: isMakeTransactionPrivateSuccess,
      isLoading: isMakeTransactionPrivateLoading,
    } = makeTransactionPrivateResult || {};

    const showHandler = useCallback(
      (transactionId, openAmount) => handleShow(transactionId, openAmount, isSmallVisible, setSmallPopupInfo),
      [isSmallVisible, setSmallPopupInfo],
    );

    const hideHandler = useCallback(
      () => handleHide(setSmallPopupInfo),
      [setSmallPopupInfo],
    );

    const showAllHandler = useCallback(
      () => handleShowAll(
        smallPopupInfo,
        isLargeVisible,
        largePopupInfo,
        invoicesLastPromiseInfo,
        hideHandler,
        triggerGetInvoices,
        setLargePopupInfo,
        ),
      [
        smallPopupInfo,
        isLargeVisible,
        largePopupInfo,
        invoicesLastPromiseInfo,
        hideHandler,
      ],
    );

    const hideAllHandler = useCallback(
      () => handleHideAll(setLargePopupInfo),
      [setLargePopupInfo],
    );

    const onCurrentPageChangeHandler = useCallback(
      (page) => handleOnCurrentPageChange(page, largePopupInfo, invoicesLastPromiseInfo, triggerGetInvoices),
      [largePopupInfo, invoicesLastPromiseInfo],
    );

    const onSearchFormSubmitHandler = useCallback(
      (fields) => handleOnSearchFormSubmit(fields, largePopupInfo, invoicesLastPromiseInfo, triggerGetInvoices),
      [largePopupInfo, invoicesLastPromiseInfo],
    );

    const onSelectInvoicesFormSubmitHandler = useCallback(
      (fields) => handleOnSelectInvoicesFormSubmit(fields, largePopupInfo, triggerGetConnectionAvailability, setLargePopupInfo),
      [largePopupInfo],
    );

    const onConnectButtonClickHandler = useCallback(
      (invoiceIds) => handleOnConnectButtonClick(invoiceIds, largePopupInfo, triggerConnectInvoices),
      [largePopupInfo],
    );

    const onCashRegisterButtonClickHandler = useCallback(
      () => handleTriggerMoveTransactionIntoCashRegister(smallPopupInfo, triggerMoveTransactionIntoCashRegister),
      [smallPopupInfo],
    );

    const onPrivateButtonClickHandler = useCallback(
      () => handleTriggerMakeTransactionPrivate(smallPopupInfo, triggerMakeTransactionPrivate),
      [smallPopupInfo],
    );

    useEffect(
      () => handleSuccessConnection(isConnectInvoiceSuccess, hideAllHandler),
      [isConnectInvoiceSuccess, hideAllHandler],
    );

    useEffect(
      () => handleTriggerMoveTransactionIntoCashRegisterFinish(
        isMoveTransactionIntoCashRegisterSuccess,
        isMoveTransactionIntoCashRegisterLoading,
        hideHandler,
        hideAllHandler,
      ),
      [
        isMoveTransactionIntoCashRegisterSuccess,
        isMoveTransactionIntoCashRegisterLoading,
        hideHandler,
        hideAllHandler,
      ],
    );

    useEffect(
      () => handleMakeTransactionPrivateFinish(
        isMakeTransactionPrivateSuccess,
        isMakeTransactionPrivateLoading,
        hideHandler,
        hideAllHandler,
      ),
      [
        isMakeTransactionPrivateSuccess,
        isMakeTransactionPrivateLoading,
        hideHandler,
        hideAllHandler,
      ],
    );

    const currentAvailability = largePopupInfo.isAvailabilityWasRequested && !isAvailabilityFetching
      ? availability
      : null;

    return (
      <>
        <Component
          {...props}
          onShowConnectInvoicePopup={showHandler}
        />
        <SmallConnectInvoicePopup
          isVisible={isSmallVisible}
          openAmount={smallPopupInfo.openAmount}
          isPrivateButtonLoading={isMakeTransactionPrivateLoading}
          isCashRegisterButtonLoading={isMoveTransactionIntoCashRegisterLoading}
          onShowAllButtonClick={showAllHandler}
          onCancelButtonClick={hideHandler}
          onCloseButtonClick={hideHandler}
          onCashRegisterButtonClick={onCashRegisterButtonClickHandler}
          onPrivateButtonClick={onPrivateButtonClickHandler}
        />
        {
          largePopupInfo.key
            ? (
              <ConnectInvoicePopup
                key={largePopupInfo.key}
                isVisible={isLargeVisible}
                invoices={invoices}
                currentPage={currentPage}
                pageSize={pageSize}
                totalCount={totalCount}
                isLoading={areInvoicesFetching}
                transaction={largePopupInfo.transactionId}
                availability={currentAvailability}
                isAvailabilityFetching={isAvailabilityFetching}
                isConnectButtonLoading={isConnectInvoiceLoading}
                onCloseButtonClick={hideAllHandler}
                onBackgroundClick={hideAllHandler}
                onConnectButtonClick={onConnectButtonClickHandler}
                onCurrentPageChange={onCurrentPageChangeHandler}
                onSearchFormSubmit={onSearchFormSubmitHandler}
                onSelectInvoicesFormSubmit={onSelectInvoicesFormSubmitHandler}
              />
            )
            : null
        }
      </>
    );
  }

  return NewComponentWithConnectInvoicePopup;
}

function handleShow(transactionId, openAmount, isSmallVisible, setSmallPopupInfo) {
  if (isSmallVisible || transactionId < 0) return;

  setSmallPopupInfo({
    transactionId,
    openAmount,
    isVisible: true,
  });
}

function handleHide(setSmallPopupInfo) {
  setSmallPopupInfo((prevState) => ({
    ...prevState,
    isVisible: false,
  }));
}

function handleShowAll(
  smallPopupInfo,
  isLargeVisible,
  largePopupInfo,
  invoicesLastPromiseInfo,
  hideHandler,
  triggerGetInvoices,
  setLargePopupInfo) {

  if (isLargeVisible) return;

  triggerGetInvoices({
    transactionId: smallPopupInfo.transactionId,
    page: 0,
  });

  hideHandler();

  setLargePopupInfo({
    transactionId: smallPopupInfo.transactionId,
    openAmount: smallPopupInfo.openAmount,
    isVisible: true,
    isAvailabilityWasRequested: false,
    key: nanoid(),
  });
}

function handleHideAll(setLargePopupInfo) {
  setLargePopupInfo((prevState) => ({
    ...prevState,
    isVisible: false,
  }));
}

function handleOnCurrentPageChange(page, largePopupInfo, invoicesLastPromiseInfo, triggerGetInvoices) {

  const lastProps = largePopupInfo.transactionId === invoicesLastPromiseInfo.lastArg.transactionId
    ? invoicesLastPromiseInfo.lastArg
    : {};

  triggerGetInvoices({
    ...lastProps,
    page: page,
  });
}

function handleOnSearchFormSubmit(fields, largePopupInfo, invoicesLastPromiseInfo, triggerGetInvoices) {
  const lastProps = largePopupInfo.transactionId === invoicesLastPromiseInfo.lastArg.transactionId
    ? invoicesLastPromiseInfo.lastArg
    : {};

  triggerGetInvoices({
    ...lastProps,
    ...fields,
    page: 0,
  });
}

function handleOnSelectInvoicesFormSubmit(fields, largePopupInfo, triggerGetConnectionAvailability, setLargePopupInfo) {
  setLargePopupInfo(prevState => ({
    ...prevState,
    isAvailabilityWasRequested: true,
  }));

  triggerGetConnectionAvailability({
    transactionId: largePopupInfo.transactionId,
    invoiceIds: Array.from(fields.checkedList),
  });
}

function handleOnConnectButtonClick(fields, largePopupInfo, triggerConnectInvoices) {
  triggerConnectInvoices({
    transactionId: largePopupInfo.transactionId,
    invoiceIds: Array.from(fields.checkedList),
  });
}

function handleSuccessConnection(isConnectInvoiceSuccess, hideAllHandler) {
  if (isConnectInvoiceSuccess) {
    hideAllHandler();
  }
}

function handleTriggerMoveTransactionIntoCashRegister(smallPopupInfo, triggerMoveTransactionIntoCashRegister) {
  if (smallPopupInfo.transactionId < 0) return;
  triggerMoveTransactionIntoCashRegister(smallPopupInfo.transactionId);
}

function handleTriggerMoveTransactionIntoCashRegisterFinish(
  isMoveTransactionIntoCashRegisterSuccess,
  isMoveTransactionIntoCashRegisterLoading,
  hideHandler,
  hideAllHandler) {

  if (isMoveTransactionIntoCashRegisterLoading) return;

  if (isMoveTransactionIntoCashRegisterSuccess) {
    hideHandler();
    hideAllHandler();
  }
}

function handleTriggerMakeTransactionPrivate(smallPopupInfo, triggerMakeTransactionPrivate) {
  if (smallPopupInfo.transactionId < 0) return;
  triggerMakeTransactionPrivate(smallPopupInfo.transactionId);
}

function handleMakeTransactionPrivateFinish(
  isMakeTransactionPrivateSuccess,
  isMakeTransactionPrivateLoading,
  hideHandler,
  hideAllHandler) {

  if (isMakeTransactionPrivateLoading) return;

  if (isMakeTransactionPrivateSuccess) {
    hideHandler();
    hideAllHandler();
  }
}

export default WithConnectInvoicePopup;