import {
  isAllOf,
  isRejected,
} from "@reduxjs/toolkit";

function isServerErrorMatcher(action) {
  // redux payload is an error object returned from queryFn and available as error in hooks
  return isServerError(action.payload);
}

function isServerError(error) {
  if (!error) return false;
  return !!error.isServerError;
}

const isRejectedWithServerError = isAllOf(
  isRejected,
  isServerErrorMatcher,
);

export default isRejectedWithServerError;