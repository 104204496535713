import React from 'react';
import PropTypes from 'prop-types';

import types from '@types/index';

import OutputField from "./OutputField";

import {
  useMemo,
} from 'react';

OutputSelectField.propTypes = {
  value: PropTypes.any,
  options: PropTypes.arrayOf(types.SelectOption),
};

OutputSelectField.defaultProps = {
  options: [],
};

function OutputSelectField({value, options, ...props}) {

  const outputValue = useMemo(
    () => getOutputValue(value, options),
      [value, options],
  );

  return (
    <OutputField
      {...props}
      value={outputValue}
    />
  );
}

function getOutputValue(value, options) {
  if (!options) return '';

  const option = options.find((option) => option.value === value);

  if (!option || !option.label) return '';
  return option.label;
}

export default OutputSelectField;