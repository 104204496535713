import types from '@types/index';

import mapErrorDescription from './mapErrorDescription';
import mapErrorType from './mapErrorType';
import mapFieldsErrors from './mapFieldsErrors';

export default function mapError(error) {

  const noNetworkError = mapNoNetworkError(error);

  if (noNetworkError) return noNetworkError;

  /**
   * false if the error was created on fronted
   * true if the error was created on fronted for server error emulation
   */
  if ('isServerError' in error) return error;

  const data = error.data || {};

  return {
    status: error.status,
    name: data.name,
    code: data.code,
    serverType: data.type,
    isServerError: true,
    title: data.title,
    message: data.message,
    type: mapErrorType(error),
    description: mapErrorDescription(error),
    fields: mapFieldsErrors(data.fields),
  };
}

function mapNoNetworkError(error) {
  if (error.name === "TypeError" && error.message === "Failed to fetch") {
    return types.Error.create(types.ErrorType.type.NETWORK_CONNECTION_FAIL);
  }
}