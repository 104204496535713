import React from "react";
import PropTypes from "prop-types";

import {
  Link as RRDLink,
} from "react-router-dom";

import "./Link.scss";

const propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  isDark: PropTypes.bool,
  isActive: PropTypes.bool,
  SvgIcon: PropTypes.object.isRequired,
};

function Link({className, name, to, isDark, isActive, SvgIcon}, ref) {
  return (
    <RRDLink
      className={[
        'link',
        isDark ? 'link_dark' : null,
        isActive ? 'link_active' : null,
        className,
      ].join(' ')}
      to={to}
      ref={ref}
    >
      <SvgIcon className={"link__icon"}/>
      <p className={"link__text"}>{name}</p>
    </RRDLink>
  );
}

const LinkWithRef =  React.forwardRef(Link);

LinkWithRef.propTypes = propTypes;

export default LinkWithRef;