import './AutoGeneratedTable.scss';

import React from 'react';
import PropTypes from 'prop-types';

import Row from './Row';
import AutoGeneratedTableFrame from './AutoGeneratedTableFrame';

AutoGeneratedTable.propTypes = {
  className: PropTypes.string,
  table: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  componentsAdditionalProps: PropTypes.object,
  columnsAdditionalProps: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

function AutoGeneratedTable({className, table, isLoading, componentsAdditionalProps, columnsAdditionalProps,
                              onSubmit, ...props}) {

  return (
    <AutoGeneratedTableFrame
      {...props}
      className={className}
      columns={table.columns}
      isLoading={isLoading}
      componentsAdditionalProps={componentsAdditionalProps}
      columnsAdditionalProps={columnsAdditionalProps}
      onSearchFormSubmit={onSubmit}
    >
      {
        table.rows.map((row) => {
          return <Row
            cells={row.cells}
            columns={table.columns}
            key={row.id}
            componentsAdditionalProps={componentsAdditionalProps}
          />;
        })
      }
    </AutoGeneratedTableFrame>
  );
}

export default AutoGeneratedTable;