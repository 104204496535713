import React from 'react';
import PropTypes from 'prop-types';

import SectionDropdown from './SectionDropdown';

import {
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';

SectionDropdownContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
};

function SectionDropdownContainer({children, ...props}) {

  const [isOpen, setIsOpen] = useState(false);

  const restChildrenContainerRef = useRef(null);

  const restChildrenContainerStyle = useMemo(
    () => getHeightStyle(isOpen, restChildrenContainerRef),
    [isOpen],
  );

  const [alwaysVisibleChildren, restChildren] = useMemo(
    () => splitChildrenInTwoArrays(children, 4), // there are 4 items into a row
    [children],
  );

  const onToggleButtonClickHandler = useCallback(
    () => handleToggleButtonClick(setIsOpen),
    [],
  );

  return <SectionDropdown
    {...props}
    restChildrenContainerStyle={restChildrenContainerStyle}
    alwaysVisibleChildren={alwaysVisibleChildren}
    restChildren={restChildren}
    isOpen={isOpen}
    onToggleButtonClick={onToggleButtonClickHandler}
    restChildrenContainerRef={restChildrenContainerRef}
  />;
}

function splitChildrenInTwoArrays(children, delimiter) {
  if (!children) {
    return [
      [],
      [],
    ];
  }

  return [
    children.slice(0, delimiter),
    children.slice(delimiter),
  ];
}

function handleToggleButtonClick(setIsOpen) {
  setIsOpen((prevState) => !prevState);
}

function getHeightStyle(isOpen, restChildrenContainerRef) {
  if (!restChildrenContainerRef.current) return null;

  const height = isOpen ? restChildrenContainerRef.current.scrollHeight : 0;

  return {
    height: height + 'px',
  };
}

export default SectionDropdownContainer;