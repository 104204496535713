import PropTypes from "prop-types";

const options = {
  INDEX: 'index',
  INVOICE_LINKS: 'invoice-links',
  CREATED_AT: 'createdAt',
  NAME: 'name',
  USAGE: 'usage',
  PRICE: 'price',
  PAYMENT_DATE: 'payment_date',
};

const CashRegisterTransactionColumnType = {
  propType: PropTypes.oneOf(Object.values(options)),
  type: options,
};

export default CashRegisterTransactionColumnType;