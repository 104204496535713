import i18next from "i18next";

import SelectOptionModel from "@mappers/SelectOptionModel";
import UserModel from "@mappers/UserModel";
import ConfirmationModel from "@mappers/ConfirmationModel";
import InvoiceFilePathModel from "@mappers/InvoiceFilePathModel";

class PaymentModel {
  static default = {
    id: null,
    amount: '',
    usage: '',
    isOuterInvoice: false,
    transfer: SelectOptionModel.default,
    output_type: SelectOptionModel.default,
    creation_type: SelectOptionModel.default,
    invoice: SelectOptionModel.default,
    invoiceFilePath: InvoiceFilePathModel.default,
    status: SelectOptionModel.default,
    receiver: UserModel.default,
    confirmationUsers: [],
  };

  static parse(response) {
    return {
      ...PaymentModel.default,
      id: response.id,
      amount: response.amount,
      usage: response.usage,
      isOuterInvoice: response.receiver.id === null,
      transfer: SelectOptionModel.parse(response.transfer),
      output_type: SelectOptionModel.parse(response.output_type),
      creation_type: SelectOptionModel.parse(response.creation_type),
      invoice: SelectOptionModel.parse(response.invoice),
      invoiceFilePath: InvoiceFilePathModel.parse(response.invoice),
      status: SelectOptionModel.parse(response.status),
      receiver: UserModel.parse(response.receiver),
      confirmationUsers: (response.confirm_user || []).map(ConfirmationModel.parse),
    }
  }

  static parseInvoicePayment(invoiceResponse, descriptionOptionResponse) {

    const recieverType = {
      id: -1,
      name: i18next.t('Invoice'),
    }

    const receiver = {
      id: -1,
      name: invoiceResponse.receiver,
      type: SelectOptionModel.parse(recieverType),
    }

    const invoice = {
      id: invoiceResponse.id,
      name: '',
    }

    const creation_type = {
      id: -1,
      name: descriptionOptionResponse.name,
    }

    return {
      ...PaymentModel.default,
      isOuterInvoice: true,
      receiver: UserModel.parse(receiver),
      creation_type: SelectOptionModel.parse(creation_type),
      amount: invoiceResponse.amount,
      usage: invoiceResponse.description,
      invoice: SelectOptionModel.parse(invoice),
    }
  }
}

export default PaymentModel;