import './GlobalLoader.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {
  WithVisibility,
} from 'hocs/index';

import {
  Loader,
} from '@components/index';

GlobalLoader.propTypes = {
  className: PropTypes.string,
};

function GlobalLoader({className, ...props}) {

  return (
    <div
      {...props}
      className={[
        'global-loader',
        className,
      ].join(' ')}
    >
      <Loader/>
    </div>
  );
}

export default WithVisibility(GlobalLoader);