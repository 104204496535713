import svgs from "@assets/svgs";
import {Language} from "@classes/index";

function createLanguages(t) {
  return [
    new Language('en', 'En', t('English'), svgs.English),
    new Language('de', 'De', t('Deutch'), svgs.German),
  ];
}

export default createLanguages;