import './Popup.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {
  Loader,
} from '@components/index';

Content.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

function Content({className, children, isLoading, ...props}) {

  if (!children) return null;

  return (
    <div
      {...props}
      className={[
        'popup__content',
        isLoading ? 'popup__content_loading' : null,
        className,
      ].join(' ')}
    >
      {children}
      <Loader
        className={'popup__loader'}
        size={Loader.size.SMALL}
      />
    </div>
  );
}

export default Content;