import '../Table.scss';

import React from 'react';
import PropTypes from 'prop-types';

import types from '@types/index';

const textClassNames = {
  [types.PaymentStatus.status.OPEN]: 'table__open-payment-amount',
  [types.PaymentStatus.status.FINISHED]: 'table__finished-payment-amount',
};

PaymentAmount.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  paymentStatus: types.PaymentStatus.isRequired,
};

function PaymentAmount({className, children, paymentStatus, ...props}) {

  const textClassName = textClassNames[paymentStatus];

  return (
    <div
      {...props}
      className={[
        'table__text-cell',
        className,
      ].join(' ')}
    >
      <p className={textClassName}>{children}</p>
    </div>
  );
}

export default PaymentAmount;