import React from 'react';
import PropTypes from 'prop-types';

import {
  InvoiceLinks,
} from '@components/index';

import '../Table.scss';

InvoiceLinksCell.propTypes = {
  className: PropTypes.string,
};

function InvoiceLinksCell({className, status, ...props}) {
  return (
    <InvoiceLinks
      {...props}
      className={[
        'table__invoice-links-cell',
        className,
      ].join(' ')}
    />
  );
}

export default InvoiceLinksCell;