import './CashRegisterBlock.scss';

import React from 'react';
import PropTypes from "prop-types";

CashRegisterBlock.propTypes = {
  className: PropTypes.string,
  label1: PropTypes.node.isRequired,
  label2: PropTypes.node.isRequired,
  value1: PropTypes.node.isRequired,
  value2: PropTypes.node.isRequired,
  iconClassName: PropTypes.string,
  Icon: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.element,
  ]).isRequired,
};

function CashRegisterBlock({
                         className,
                         label1,
                         label2,
                         value1,
                         value2,
                         comment,
                         iconClassName,
                         Icon,
                         ...props}) {

  return (
    <div
      {...props}
      className={[
        'block-property',
        className
      ].join(' ')}
    >
      <div className={'block-property__content'}>
        <Icon
          className={[
            'block-property__icon',
            iconClassName
          ].join(' ')}
        />
        <div>
          <p className={'block-property__label'}>{label1}</p>
          <p className={'block-property__value'}>{value1}</p>
        </div>
        <div>
          <p className={'block-property__label'}>{label2}</p>
          <p className={'block-property__value'}>{value2}</p>
        </div>
      </div>
      {
        comment
          ? (
            <div className={'block-property__footer'}>
              <p className={'block-property__comment'}>
                {comment}
              </p>
            </div>
          )
          : null
      }
    </div>
  );
}

export default CashRegisterBlock;