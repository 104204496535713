import PropTypes from 'prop-types';

import Row from '@types/Row';

const TabledObject = {
  propType: PropTypes.exact({
    title: PropTypes.node.isRequired,
    row: Row.propType.isRequired,
  }),
}

export default TabledObject;