import React from 'react';
import PropTypes from 'prop-types';

import './Popup.scss';

Footer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

function Footer({className, children, ...props}) {
  return (
    <footer
      {...props}
      className={[
        'popup__footer',
        className,
      ].join(' ')}
    >
      {children}
    </footer>
  );
}

export default Footer;