import React from 'react';

import {
  useEffect,
} from 'react';

import {
  useSelector,
} from "react-redux";

import {
  selectRedirectionUrl,
} from "@slices/redirection";

function RedirectionGate({children}) {

  const url = useSelector(selectRedirectionUrl);

  useEffect(
    () => handleRedirection(url),
    [url],
  );

  return children;
}

function handleRedirection(url) {
  if (!url) return;
  window.location.replace(url)
}

export default RedirectionGate;