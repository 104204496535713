import React from 'react';

import '../Table.scss';

function EmptyCell({...props}) {
  return (
    <div{...props}/>
  );
}

export default EmptyCell;