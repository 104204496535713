import PaymentColumnType from "@types/PaymentColumnType";
import {createConfirmUserCell, createTextCell, createButtonCell} from "@utils/dataCreators";
import {mapConfirmUsers} from "@mappers/mapPayments";

class PaymentView {

  /**
   * @param payment: instance of PaymentModel
   */
  static parse(payment) {
    return {
      id: payment.id,
      recipientId: payment.receiver.id,
      cells: {
        [PaymentColumnType.type.STATUS]:
          createTextCell([PaymentColumnType.type.STATUS], payment.status.name),

        [PaymentColumnType.type.RECEIVER]:
          createTextCell([PaymentColumnType.type.RECEIVER], payment.receiver.name),

        [PaymentColumnType.type.AMOUNT]:
          createTextCell([PaymentColumnType.type.AMOUNT], payment.amount),

        [PaymentColumnType.type.DESCRIPTION]:
          createTextCell([PaymentColumnType.type.DESCRIPTION], payment.output_type.name),

        [PaymentColumnType.type.USAGE]:
          createTextCell([PaymentColumnType.type.USAGE], payment.usage),

        [PaymentColumnType.type.INVOICE_PREVIEW]:
          createButtonCell([PaymentColumnType.type.INVOICE_PREVIEW], payment.invoiceFilePath),

        [PaymentColumnType.type.CONFIRM_PAYMENTS]:
          createConfirmUserCell(
            [PaymentColumnType.type.CONFIRM_PAYMENTS],
            payment.id,
            mapConfirmUsers(payment.confirmationUsers),
          ),
      },
    }
  }
}

export default PaymentView;