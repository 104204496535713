function renderByPermission(permissions, permission, component) {
  if (!permissions) return;

  const hasPermission = permissions.includes(permission);

  if (!hasPermission) return null;

  return component;
}

export default renderByPermission;