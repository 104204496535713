import React from 'react';
import PropTypes from 'prop-types';

import LogPopup from './LogPopup';

import {
  useEffect,
} from 'react';

import {
  useLazyGetLogsQuery,
} from '@api/logs';

import {
  useTableObject,
} from '@hooks/index';

LogPopupContainer.propTypes = {
  isVisible: PropTypes.string.isRequired,
  columnsFactory: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function LogPopupContainer({isVisible, columnsFactory, onClose, ...props}) {

  const [
    trigger,
    result,
  ] = useLazyGetLogsQuery();

  const {
    data: rows,
    isFetching,
  } = result || {};

  const table = useTableObject(columnsFactory, rows);

  useEffect(
    () => trigger(),
    [],
  );

  return (
    <LogPopup
      {...props}
      isVisible={isVisible}
      table={table}
      isLoading={isFetching}
      onClose={onClose}
    />
  );
}

export default LogPopupContainer;