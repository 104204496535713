import i18next from "i18next";

import validators from '@yup/index';
import AvailableInvoiceColumnType from '@types/AvailableInvoiceColumnType';
import {createColumn} from "@utils/dataCreators";
import {Table} from '@components/index'

function createInvoiceColumns() {
  return {
    [AvailableInvoiceColumnType.type.CHECKBOX]: createColumn(
      AvailableInvoiceColumnType.type.CHECKBOX,
      '',
      'connect-invoice-popup__checkbox-cell',
    ),

    [AvailableInvoiceColumnType.type.RECEIVER]: createColumn(
      AvailableInvoiceColumnType.type.RECEIVER,
      i18next.t('Receiver'),
      'connect-invoice-popup__receiver-cell',
      {type: Table.Cell.type.INPUT_FIELD, validator: validators.receiver},
    ),

    [AvailableInvoiceColumnType.type.INVOICE_NUMBER]: createColumn(
      AvailableInvoiceColumnType.type.INVOICE_NUMBER,
      i18next.t('Invoice Number'),
      'connect-invoice-popup__invoice-number-cell',
      {type: Table.Cell.type.INPUT_FIELD, validator: validators.invoiceNumber},
    ),

    [AvailableInvoiceColumnType.type.AMOUNT]: createColumn(
      AvailableInvoiceColumnType.type.AMOUNT,
      i18next.t('Amount'),
      'connect-invoice-popup__amount-cell',
      {type: Table.Cell.type.INPUT_FIELD, validator: validators.amount},
    ),
  };
}

export default createInvoiceColumns;