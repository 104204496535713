import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

import {
  useCallback,
} from 'react';

import {
  ArrowButton,
} from '@components/index';

import Button from './Button';
import HiddenButtonsSign from './HiddenButtonsSign';

import './Pagination.scss';

Pagination.propTypes = {
  className: PropTypes.string,
  pageSize: PropTypes.number.isRequired,
  selectedPageIndex: PropTypes.number.isRequired,
  fullAmount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onPageNumberClick: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  pageSize: 0,
  selectedPageIndex: 0,
};

function Pagination({className, pageSize, selectedPageIndex, fullAmount, isLoading,
                      onPageNumberClick, ...props}) {

  const {t} = useTranslation();

  const visibleNumberButtonsRadius = 2;
  const selectedPageNumber = selectedPageIndex + 1;

  let pagesAmount = 0;

  if (fullAmount && pageSize) {
    pagesAmount = Math.ceil(fullAmount / pageSize);
  }

  let
    theoreticalFirstVisibleItemIndex = 0,
    theoreticalLastVisibleItemIndex = 0;

  if (pageSize && !isLoading) {
    theoreticalFirstVisibleItemIndex = selectedPageIndex * pageSize + 1;
    theoreticalLastVisibleItemIndex = (selectedPageIndex + 1) * pageSize;
  }

  const firstVisibleItemIndex = theoreticalFirstVisibleItemIndex > fullAmount
    ? fullAmount
    : theoreticalFirstVisibleItemIndex;

  const lastVisibleItemIndex = theoreticalLastVisibleItemIndex > fullAmount
    ? fullAmount
    : theoreticalLastVisibleItemIndex;

  const
    isPreviousPageButtonAvailable = firstVisibleItemIndex > 1,
    isNextPageButtonAvailable = lastVisibleItemIndex < fullAmount;

  const label = [
    t('Showing'),
    firstVisibleItemIndex + '-' + lastVisibleItemIndex,
    t('of'),
    fullAmount,
    t('Entries'),
  ].join(' ');

  function handleOnPageNumberClick(e, payload) {
    onPageNumberClick(payload);
  }

  const onPageNumberHandler = useCallback(handleOnPageNumberClick, [onNextPageClick]);

  function onNextPageClick() {
    onPageNumberClick(selectedPageNumber -1 + 1);
  }

  function onPreviousPageClick() {
    onPageNumberClick(selectedPageNumber -1 - 1);
  }

  function getNumberButton(number) {
    return (
      <Button
        isActive={selectedPageNumber === number}
        payload={number - 1}
        key={number}
        onClick={onPageNumberHandler}
      >
        {number}
      </Button>
    );
  }

  function getInnerNumberButtons() {
    const innerButtons = [];

    const theoreticalStartNumber = selectedPageNumber - visibleNumberButtonsRadius;
    const theoreticalEndNumber = selectedPageNumber + visibleNumberButtonsRadius;

    const firstPossibleInnerButtonNumber = 2;
    const lastPossibleInnerButtonNumber = pagesAmount - 1;

    let lostAtStartButtonsAmount = 0;
    let lostAtEndButtonsAmount = 0;

    const compensationButtonsAmount = 1;

    // calc lostAtStartButtonsAmount
    if (theoreticalStartNumber < firstPossibleInnerButtonNumber) {
      if (theoreticalStartNumber < 0) {
        lostAtStartButtonsAmount = Math.abs(theoreticalStartNumber) + 1;
      } else {
        lostAtStartButtonsAmount = 1 - theoreticalStartNumber;
      }

      lostAtStartButtonsAmount -= 2 - compensationButtonsAmount;

      if (lostAtStartButtonsAmount < 0){
        lostAtStartButtonsAmount = 0;
      }
    }

    // calc lostAtEndButtonsAmount
    if (theoreticalEndNumber > lastPossibleInnerButtonNumber) {
      lostAtEndButtonsAmount = theoreticalEndNumber - lastPossibleInnerButtonNumber - 1;

      lostAtEndButtonsAmount -= 2 - compensationButtonsAmount;

      if (lostAtEndButtonsAmount > compensationButtonsAmount){
        lostAtEndButtonsAmount = compensationButtonsAmount;
      }

      if (lostAtEndButtonsAmount < 0) {
        lostAtEndButtonsAmount = 0;
      }
    }

    const compensatedTheoreticalStartNumber = theoreticalStartNumber - lostAtEndButtonsAmount;
    const compensatedTheoreticalEndNumber = theoreticalEndNumber + lostAtStartButtonsAmount;

    const startNumber = compensatedTheoreticalStartNumber < firstPossibleInnerButtonNumber
      ? firstPossibleInnerButtonNumber
      : compensatedTheoreticalStartNumber;

    const endNumber = compensatedTheoreticalEndNumber > lastPossibleInnerButtonNumber
      ? lastPossibleInnerButtonNumber
      : compensatedTheoreticalEndNumber;

    for (let i = startNumber; i <= endNumber; i++) {
      innerButtons.push(getNumberButton(i));
    }

    return innerButtons;
  }

  function getSecondNumberButton() {
    if (selectedPageNumber - 1 - visibleNumberButtonsRadius <= 2) return;

    if (selectedPageNumber + 1 - visibleNumberButtonsRadius === 3) {
      return getNumberButton(2);
    }

    return <HiddenButtonsSign/>;
  }

  function getPrevLastNumberButton() {
    const lastRenderedPageNumber = selectedPageNumber + 2 + visibleNumberButtonsRadius;
    if (lastRenderedPageNumber >= pagesAmount) return;

    if (lastRenderedPageNumber >= pagesAmount) {
      return getNumberButton(pagesAmount);
    }

    return <HiddenButtonsSign/>;
  }

  return (
    <div
      {...props}
      className={[
        'pagination',
        className,
      ].join(' ')}
    >
      <p className={'pagination__label'}>{label}</p>
      {
        <div className={[
          'pagination__buttons-container',
          pagesAmount < 2 ? 'pagination__buttons-container_hidden' : '',
        ].join(' ')}>
          <ArrowButton
            className={'pagination__previous-button'}
            direction={ArrowButton.direction.LEFT}
            isDisabled={!isPreviousPageButtonAvailable}
            onClick={onPreviousPageClick}
          />
          {
            pagesAmount > 1
              ? getNumberButton(1)
              : null
          }
          {
            getSecondNumberButton()
          }
          {
            getInnerNumberButtons()
          }
          {
            getPrevLastNumberButton()
          }
          {
            getNumberButton(pagesAmount)
          }
          <ArrowButton
            className={'pagination__next-button'}
            direction={ArrowButton.direction.RIGHT}
            isDisabled={!isNextPageButtonAvailable}
            onClick={onNextPageClick}
          />
        </div>
      }
    </div>
  );
}

export default Pagination;