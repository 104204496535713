import * as Yup from 'yup';
import errors from './errors';

const regex = /^[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;

export default Yup
  .string()
  .test(
    'valid email',
    errors.notAValidEmailAsALogin,
    (value) => regex.test(value),
  );