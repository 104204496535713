import React from 'react';
import {startQueueWatcher} from '@slices/errors';
import {useWithDispatchCallback} from '@hooks/index';

import {
  hide,
  selectCurrentError,
  selectIsCurrentErrorVisible,
} from '@slices/errors';

import {
  useEffect,
} from 'react';

import {
  useSelector,
} from "react-redux";

import {
  ErrorPopup,
} from "components/index";

function ErrorsGate({children}) {

  const startQueueWatcherCallback = useWithDispatchCallback(startQueueWatcher);
  const hideCallback = useWithDispatchCallback(hide);

  const currentError = useSelector(selectCurrentError);
  const isCurrentErrorVisible = useSelector(selectIsCurrentErrorVisible);

  const {title, message} = currentError || {};

  useEffect(
    () => startQueueWatcherCallback(),
    [],
  );

  return (
    <>
      {children}
      <ErrorPopup
        isVisible={isCurrentErrorVisible}
        title={title || message || ''}
        content={title ? message || '' : ''}
        onCloseButtonClick={hideCallback}
      />
    </>
  );
}

export default ErrorsGate;
