import PropTypes from "prop-types";

const options = {
  INDEX: 'INDEX',
  STATUS: 'STATUS',
  RECEIVER: 'RECEIVER',
  AMOUNT: 'AMOUNT',
  DESCRIPTION: 'DESCRIPTION',
  USAGE: 'USAGE',
  INVOICE_PREVIEW: 'INVOICE_PREVIEW',
  CONFIRM_PAYMENTS: 'CONFIRM_PAYMENTS',
};

const PaymentColumnType = {
  propType: PropTypes.oneOf(Object.values(options)),
  type: options,
};

export default PaymentColumnType;