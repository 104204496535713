import './Popup.scss';

import React from 'react';
import PropTypes from 'prop-types';

Popup.propTypes = {
  className: PropTypes.string,
  children:PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  isActive: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  isSmall: PropTypes.bool,
  isError: PropTypes.bool,
  onContainerClick: PropTypes.func,
};

function Popup({className, children, isActive, isSmall, isVisible, isError, onContainerClick, ...props}) {
  return (
    <div
      {...props}
      className={[
        'popup',
        isSmall ? 'popup_small' : null,
        isActive ? 'popup_active' : null,
        isVisible ? 'popup_visible' : 'popup_hidden',
        isError ? 'popup_error' : null,
        className,
      ].join(' ')}
      onClick={onContainerClick}
    >
      {children}
    </div>
  );
}

export default Popup;
