import React from 'react';
import PropTypes from 'prop-types';

import './Table.scss';

Row.propTypes = {
  className: PropTypes.string,
};

function Row({className, children, ...props}) {
  return (
    <div
      {...props}
      className={[
        'table__row',
        className
      ].join(' ')}
    >
      {children}
    </div>
  );
}

export default Row;