import React from 'react';
import PropTypes from 'prop-types';

import types from '@types/index';

const propTypes = {
  className: PropTypes.string,
  id: types.Id.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

function Link({className, id, name, onClick, ...props}) {
  return (
    <a
      {...props}
      className={[
        'invoice-links__link',
        className,
      ].join(' ')}
      href="#"
      onClick={onClick}
    >
      {name}
    </a>
  );
}

Link.propTypes = propTypes;

export default Link;