import React from 'react';
import PropTypes from 'prop-types';

import WithDependenciesField from "./WithDependenciesField";

import {
  useRef,
} from 'react';

import {
  useField,
} from "formik";

const propTypes = {
  name: PropTypes.string.isRequired,
};

function WithFormikField(Component) {
  NewComponent.propTypes = propTypes;

  function NewComponent({name, ...props}) {
    const [field, meta] = useField(name);

    const nameRef = useRef(name);
    nameRef.current = name;

    return <Component
      name={name}
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      isTouched={meta.touched}
      error={meta.error}
      {...props}
    />;
  }

  return WithDependenciesField(NewComponent);
}

export default WithFormikField;