import React from 'react';
import PropTypes from 'prop-types';

import {
  ConfirmPayments,
} from '@components/index';

import '../Table.scss';

ConfirmPaymentsCell.propTypes = {
  className: PropTypes.string,
};

function ConfirmPaymentsCell({className, ...props}) {
  return (
    <ConfirmPayments
      {...props}
      className={[
        'table__confirm-payments-cell',
        className,
      ].join(' ')}
    />
  );
}

export default ConfirmPaymentsCell;