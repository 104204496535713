import {
  isAllOf,
  isRejected,
} from "@reduxjs/toolkit";

import Error from '@types/Error';
import ErrorType from '@types/ErrorType';

function matchStatus(action) {
  if (!action.payload) return false;

  const tooOldAccessTokenCode = Error.type[ErrorType.type.ACCESS_TOKEN_TOO_OLD].code;

  return action.payload.status === 401 && action.payload.code !== tooOldAccessTokenCode;
}

const matchUnauthorized = isAllOf(
  isRejected,
  matchStatus,
);

export default matchUnauthorized;