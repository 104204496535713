import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

import svgs from '@assets/svgs';

import {
  Popup,
} from '@components/index';

import './ErrorPopup.scss';

ErrorPopup.propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  title: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  onCloseButtonClick: PropTypes.func.isRequired,
};

function ErrorPopup ({className, isVisible, title, content,
                               onCloseButtonClick, ...props}) {

  const {t} = useTranslation();

  return (
    <Popup
      {...props}
      className={'error-popup'}
      isVisible={isVisible}
      isSmall={true}
      onBackgroundClick={onCloseButtonClick}
    >
      <Popup.Container className={'error-popup__container'}>
        <Popup.Header
          className={'error-popup__header'}
          title={title}
          Icon={DangerIcon}
          onCloseButtonClick={onCloseButtonClick}
        />
        {
          content
            ? (
              <Popup.Content className={'error-popup__content'}>
                <p className={'error-popup__question'}>{content}</p>
              </Popup.Content>
            )
            : null
        }
      </Popup.Container>
    </Popup>
  );
}

function DangerIcon({className, ...props}) {
  return (
    <svgs.Warning
      {...props}
      className={[
        'error-popup__icon',
        className
      ].join(' ')}
    />
  );
}

export default ErrorPopup;