import './PrimaryText.scss';

import React from 'react';
import PropTypes from 'prop-types';

PrimaryText.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

function PrimaryText({className, children, ...props}) {
  return (
    <div
      {...props}
      className={[
        'primary-text',
        className,
      ].join(' ')}
    >
      <p className={'primary-text__text'}>{children}</p>
    </div>
  );
}

export default PrimaryText;