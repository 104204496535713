import React from 'react';
import PropTypes from 'prop-types';

import './Pagination.scss';

HiddenButtonsSign.propTypes = {
  className: PropTypes.string,
};

function HiddenButtonsSign({className, ...props}) {
  return (
    <p
      {...props}
      className={[
        'pagination__hidden-buttons-sign',
        className,
      ].join(' ')}
    >
      <span className={'pagination__hidden-buttons-sign-text'}>...</span>
    </p>
  );
}

export default HiddenButtonsSign;