import PropTypes from 'prop-types'

const options = {
  EMPTY: 'EMPTY',
  TEXT: 'TEXT',
  HIGHLIGHTED_TEXT: 'HIGHLIGHTED_TEXT',
  TITLE: 'TITLE',
  PAYMENT_AMOUNT: 'PAYMENT_AMOUNT',
  BOOKING: 'BOOKING',
  INVOICE_LINKS: 'INVOICE_LINKS',
  INPUT_FIELD: 'INPUT_FIELD',
  SELECT_FIELD: 'SELECT_FIELD',
  DATEPICKER_FIELD: 'DATEPICKER_FIELD',
  DATE_RANGE_PICKER_FIELD: 'DATE_RANGE_PICKER_FIELD',
  BUTTON: 'BUTTON',
  CHECKBOX: 'CHECKBOX',
  RADIO_BUTTON: 'RADIO_BUTTON',
  CONFIRM_PAYMENTS: 'CONFIRM_PAYMENTS',
  NET_GROSS_AMOUNT: 'NET_GROSS_AMOUNT',
  LINK: 'LINK',
};

const CellType = {
  propType: PropTypes.oneOf(Object.values(options)),
  type: options,
};

export default CellType;