import PropTypes from 'prop-types';

export const options = {
  BOOKED: '2',
  HALF_BOOKED: '3',
  NOT_BOOKED: '1',
};

const BookingStatus = {
  propType: PropTypes.oneOf(Object.values(options)),
  status: options,
};

export default BookingStatus;