import React from 'react';
import PropTypes from 'prop-types';

import './ArrowButton.scss';
import svgs from '@assets/svgs';

ArrowButton.direction = {
  TOP: 'arrow-button_direction_top',
  LEFT: 'arrow-button_direction_left',
  BUTTON: 'arrow-button_direction_bottom',
  RIGHT: 'arrow-button_direction_right',

  getValues() {
    return Object
      .keys(this)
      .map(key => this[key]);
  }
};

ArrowButton.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(ArrowButton.direction.getValues()).isRequired,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

function ArrowButton({className, direction, isDisabled, onClick, ...props}) {

  function handleOnClick(...props) {
    if (isDisabled) return;

    onClick(...props);
  }

  return (
    <svgs.Chevron
      {...props}
      className={[
        'arrow-button',
        direction,
        isDisabled ? 'arrow-button_disabled' : null,
        className,
      ].join(' ')}
      onClick={handleOnClick}
    />
  );
}

export default ArrowButton;