import './PaginatedTable.scss';
import React from 'react';
import PropTypes from 'prop-types';

import {
  AutoGeneratedTable,
  Pagination,
} from '@components/index';

PaginatedTable.propTypes = {
  table: PropTypes.object.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  tableProps: PropTypes.object,
  componentsAdditionalProps: PropTypes.object,
  columnsAdditionalProps: PropTypes.object,
  onCurrentPageChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

PaginatedTable.defaultProps = {
  pageSize: 0,
  totalCount: 0,
};

function PaginatedTable({table, currentPage, pageSize, totalCount, isLoading,
                          tableProps, componentsAdditionalProps, columnsAdditionalProps,
                          onCurrentPageChange, onSubmit, ...props}) {

  return (
    <div {...props}>
      <AutoGeneratedTable
        {...tableProps}
        table={table}
        isLoading={isLoading}
        componentsAdditionalProps={componentsAdditionalProps}
        columnsAdditionalProps={columnsAdditionalProps}
        onSubmit={onSubmit}
      />
      <Pagination
        className={'paginated-table__pagination'}
        pageSize={pageSize}
        selectedPageIndex={currentPage}
        fullAmount={totalCount}
        isLoading={isLoading}
        onPageNumberClick={onCurrentPageChange}
      />
    </div>
  );
}

export default PaginatedTable;