import './AuthScreen.scss';

import React from 'react';
import svgs from '@assets/svgs';

import {
  useMemo,
} from 'react';

import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';

import {
  LoginFragment,
  ForgotPasswordFragment,
  ResetPasswordFragment,
} from '@fragments/index';

function AuthScreen() {

  let {path} = useRouteMatch();

  const pathes = useMemo(
    () => createPathes(path),
    [path],
  );

  return (
    <main className={'auth-screen'}>
      <Switch>
        <Route exact path={pathes.root}>
          <Redirect to={pathes.login}/>
        </Route>
        <Route path={pathes.login}>
          <LoginFragment
            className={'auth-screen__form'}
            pathToRegistration={pathes.registration}
            pathToForgotPassword={pathes.forgotPassword}
            pathToMainScreen={pathes.mainScreen}
          />
        </Route>
        <Route path={pathes.forgotPassword}>
          <ForgotPasswordFragment
            className={'auth-screen__form'}
            pathToLogin={pathes.login}
          />
        </Route>
        <Route path={pathes.resetPassword}>
          <ResetPasswordFragment
            className={'auth-screen__form'}
            pathToMainScreen={pathes.mainScreen}
          />
        </Route>
      </Switch>
      <svgs.Illustration className={'auth-screen__illustration'}/>
    </main>
  );
}

function createPathes(path) {
  return {
    root: path,
    login: `${path}/login`,
    registration: `${path}/registration`,
    forgotPassword: `${path}/forgot-password`,
    resetPassword: `${path}/reset-password`,
    mainScreen: '/',
  };
}

export default AuthScreen;