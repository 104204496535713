import './AuthForm.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {
  InputField as DefaultInputField,
} from '@components/index';

InputField.propTypes = {
  className: PropTypes.string,
};

function InputField({className, ...props}) {
  return <DefaultInputField
    {...props}
    className={[
      'auth-form__input-field',
      className,
    ].join(' ')}
  />;
}

export default InputField;