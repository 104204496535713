const production = {
  environment: {
    isProduction: true,
    isDevelopment: false,
  },
  backend: {
    url: 'https://fiyou.de/api/v2/',
  },
  websocket: {
    url: 'wss://fiyou.de:8887',
  },
};

export default production;
