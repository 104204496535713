import './CreatePaymentPopup.scss';

import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {useTranslation} from "react-i18next";

import types from '@types/index';
import errors from '@yup/errors';
import createCreatePaymentColumns from "./createCreatePaymentColumns";
import DefaultPaymentForm from "./DefaultPaymentForm";

import {
  useMemo,
  useCallback,
} from 'react';

import {
  useTableObject,
} from '@hooks/index';

import {
  Popup,
  Button,
} from '@components/index';

const validationSchema = Yup.object().shape({
  receiver: Yup.string().nullable().required(errors.required),
  name: Yup.string().nullable().required(errors.required),
  description: Yup.string().nullable().required(errors.required),
  usage: Yup.string().nullable().required(errors.required),
  typeTransfer: Yup.string().nullable().test('isRequiredIfEmployee', errors.required, (value, context) => {
    if (context.parent['receiver'] === '1' && !value) return;
    return true;
  }),
  checkedInvoiceId: Yup.string().nullable().test('isRequiredIfWithInvoice', errors.required, (value, context) => {
    if (context.parent['description'] === '3' && !value) return;
    return true;
  }),
  amount: Yup.number().typeError(errors.shouldBeANumber).required(errors.required),
});

DefaultPaymentPopup.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  receiverOptions: PropTypes.arrayOf(types.SelectOption),
  userOptions: PropTypes.arrayOf(types.SelectOption),
  descriptionOptions: PropTypes.arrayOf(types.SelectOption),
  typeTransferOptions: PropTypes.arrayOf(types.SelectOption),
  isReceiverOptionsLoading: PropTypes.bool,
  isUserOptionsLoading: PropTypes.bool,
  isDescriptionOptionsLoading: PropTypes.bool,
  isTypeTransferOptionsLoading: PropTypes.bool,
  isFormSubmitting: PropTypes.bool,
  invoices: PropTypes.array,
  pagination: PropTypes.object,
  areInvoicesLoading: PropTypes.bool,
  onFormSubmit: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onSearchInvoiceFieldsChange: PropTypes.func.isRequired,
  onCloseButtonClick: PropTypes.func.isRequired,
};

DefaultPaymentPopup.defaultProps = {
  onFormSubmit: new Function(),
  onPageChange: new Function(),
  onSearchInvoiceFieldsChange: new Function(),
  onCloseButtonClick: new Function(),
};

function DefaultPaymentPopup({
                               className,
                               title,
                               isVisible,
                              initialValues,
                              receiverOptions,
                              userOptions,
                              descriptionOptions,
                              typeTransferOptions,
                              isReceiverOptionsLoading,
                              isUserOptionsLoading,
                              isDescriptionOptionsLoading,
                              isTypeTransferOptionsLoading,
                              isFormSubmitting,
                              invoices,
                              pagination,
                              areInvoicesLoading,
                              onFormSubmit,
                              onPageChange,
                              onSearchInvoiceFieldsChange,
                              onGetReceiverOptions,
                              onGetTypeTransferOptions,
                              onGetNameOptions,
                              onGetDescriptionOptions,
                              onCloseButtonClick,
                              ...props}) {

  const {t} = useTranslation();

  return (
    <Popup
      {...props}
      className={[
        'create-payment-popup',
        className,
      ].join(' ')}
      isVisible={isVisible}
      onBackgroundClick={onCloseButtonClick}
    >
      <Popup.Container className={'create-payment-popup__container'}>
        <Formik
          initialValues={initialValues ? initialValues : {
            receiver: null,
            name: null,
            typeTransfer: null,
            amount: '',
            usage: '',
            description: '',
            checkedInvoiceId: null,
          }}
          validationSchema={validationSchema}
          onSubmit={onFormSubmit}
        >
          { function ({values, errors, touched, setFieldTouched, setFieldValue, submitForm}) {

            const onCheckInvoiceHandler = useCallback(
              ({checkedId}) => handleOnCheckInvoice(checkedId, invoices, setFieldValue, setFieldTouched),
              [invoices],
            );

            const onSearchInvoiceFieldsChangeHandler = useCallback(
              (fields) => handleOnSearchInvoiceFieldsChange(fields, values['receiver'],  values['description'], onSearchInvoiceFieldsChange, setFieldValue),
              [values['receiver'], values['description'], onSearchInvoiceFieldsChange],
            );

            const onPageChangeHandler = useCallback(
              (page) => handleOnPageChange(page, onPageChange, setFieldValue),
              [onPageChange],
            );

            const table = useTableObject(createCreatePaymentColumns, invoices);

            return (
              <>
                <Popup.Header
                  className={'create-payment-popup__header'}
                  title={title}
                  onCloseButtonClick={onCloseButtonClick}
                />
                <Popup.Content
                  className={'create-payment-popup__content'}
                  isLoading={isFormSubmitting}
                >
                  <DefaultPaymentForm
                    values={values}
                    errors={errors}
                    touched={touched}
                    table={table}
                    pagination={pagination}
                    receiverOptions={receiverOptions}
                    userOptions={userOptions}
                    descriptionOptions={descriptionOptions}
                    typeTransferOptions={typeTransferOptions}
                    isTypeTransferOptionsLoading={isTypeTransferOptionsLoading}
                    isReceiverOptionsLoading={isReceiverOptionsLoading}
                    isUserOptionsLoading={isUserOptionsLoading}
                    isDescriptionOptionsLoading={isDescriptionOptionsLoading}
                    setFieldValue={setFieldValue}
                    areInvoicesLoading={areInvoicesLoading}
                    onCheckInvoiceHandler={onCheckInvoiceHandler}
                    onSearchInvoiceFieldsChangeHandler={onSearchInvoiceFieldsChangeHandler}
                    onPageChangeHandler={onPageChangeHandler}
                    onGetReceiverOptions={onGetReceiverOptions}
                    onGetNameOptions={onGetNameOptions}
                    onGetDescriptionOptions={onGetDescriptionOptions}
                    onGetTypeTransferOptions={onGetTypeTransferOptions}
                  />
                </Popup.Content>
                <Popup.Footer>
                  <Button
                    onClick={submitForm}
                    isLoading={isFormSubmitting}
                  >
                    {t('Save')}
                  </Button>
                </Popup.Footer>
              </>
            );
          }}
        </Formik>
      </Popup.Container>
    </Popup>
  );
}

function handleOnCheckInvoice(checkedId, invoices, setFieldValue, setFieldTouched) {
  const invoice = invoices.find(invoice => invoice.id === checkedId);

  if (!invoice) return;

  const amountCellValue = invoice.cells[types.CreatePaymentColumnType.type.AMOUNT].value;

  const amountString = amountCellValue
    .replaceAll('€', '')
    .replaceAll('.', '')
    .replaceAll(',', '.');

  const amountNumber = Number(amountString);

  const amount = Number.isNaN(amountNumber) ? '' : amountNumber;

  setFieldTouched('checkedInvoiceId', true);

  setFieldValue('checkedInvoiceId', invoice.id);
  setFieldValue('amount', amount);
}

function handleOnSearchInvoiceFieldsChange(fields, receiver, description, onSearchInvoiceFieldsChange, setFieldValue) {
  if (!onSearchInvoiceFieldsChange) return;

  setFieldValue('checkedInvoiceId', null);
  onSearchInvoiceFieldsChange({
    ...fields,
    receiverName: receiver,
    description: description,
  });
}

function handleOnPageChange(page, onPageChange, setFieldValue) {
  if (!onPageChange) return;

  setFieldValue('checkedInvoiceId', null);
  onPageChange(page);
}

export default DefaultPaymentPopup;