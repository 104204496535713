import AvailableInvoiceColumnType from "@types/AvailableInvoiceColumnType";

import {
  createTextCell,
  createCheckboxCell,
} from 'utils/dataCreators';

export default function mapAvailableInvoices(invoices) {
  return invoices.map(invoice => ({
    id: invoice.id,
    cells: {
      [AvailableInvoiceColumnType.type.CHECKBOX]:
        createCheckboxCell(AvailableInvoiceColumnType.type.CHECKBOX),

      [AvailableInvoiceColumnType.type.RECEIVER]:
        createTextCell(AvailableInvoiceColumnType.type.RECEIVER, invoice.receiver),

      [AvailableInvoiceColumnType.type.INVOICE_NUMBER]:
        createTextCell(AvailableInvoiceColumnType.type.INVOICE_NUMBER, invoice.invoice_number),

      [AvailableInvoiceColumnType.type.AMOUNT]:
        createTextCell(AvailableInvoiceColumnType.type.AMOUNT, invoice.amount),
    },
  }));
}