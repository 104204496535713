import moment from "moment";

import HistoryColumn from "@types/HistoryColumn";

import {
  createLinkCell,
  createTextCell,
} from '@utils/dataCreators';

function mapHistory(response) {
  return response.map((action) => ({
    id: action.id,
    cells: {

      [HistoryColumn.type.NAME]:
        createTextCell(
          HistoryColumn.type.NAME,
          action.receiver.name,
        ),

      [HistoryColumn.type.ACTION]: action.bank.url
        ? (
          createLinkCell(
            HistoryColumn.type.ACTION,
            action.name,
            action.bank.name,
            action.bank.url,
          )
        )
        : (
          createTextCell(
            HistoryColumn.type.ACTION,
            (action.name + ' ' + action.bank.name),
          )
        ),

      [HistoryColumn.type.DATE]:
        createTextCell(
          HistoryColumn.type.DATE,
          moment(action.date).format('DD.MM.YYYY hh:mm'),
        ),

    }
  }));
}

export default mapHistory;