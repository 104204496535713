import React from 'react';
import {nanoid} from "nanoid";
import {useTranslation} from "react-i18next";

import {
  useState,
  useCallback,
  useEffect,
} from 'react';

import {
  useCreatePaymentMutation,
} from '@api/payments';

import {
  DefaultPaymentPopup,
} from "@components/index";

function WithCreatePaymentPopup(Component){
  function NewComponent(props) {

    const {t} = useTranslation();

    const [info, setInfo] = useState({});

    const [
      triggerCreatePayment,
      {
        isSuccess: isCreatePaymentSuccess,
        isLoading: isCreatePaymentLoading,
      },
    ] = useCreatePaymentMutation();

    const onShowHandler = useCallback(
      () => handleOnShowPopup(setInfo),
      [],
    );

    const onHideHandler = useCallback(
      () => handleOnHide(setInfo),
      [],
    );

    useEffect(
      () => hideOnCreationFinish(isCreatePaymentSuccess, isCreatePaymentLoading, onHideHandler),
      [isCreatePaymentSuccess, isCreatePaymentLoading]
    );

    return (
      <>
        <Component
          {...props}
          onShowCreatePaymentPopup={onShowHandler}
        />
        {
          info.key
            ? (
              <DefaultPaymentPopup
                key={info.key}
                title={t('Create Payment')}
                isVisible={!!info.isVisible}
                initialValues={null}
                onSubmit={triggerCreatePayment}
                onHide={onHideHandler}
                isFormSubmitting={isCreatePaymentLoading}
              />
            )
            : null
        }
      </>
    );
  }

  return NewComponent;
}

function handleOnShowPopup(setInfo) {
  setInfo({
    isVisible: true,
    key: nanoid(),
  });
}

function handleOnHide(setInfo) {
  setInfo(prevState => ({
    ...prevState,
    isVisible: false,
  }));
}

function hideOnCreationFinish(isCreatePaymentSuccess, isCreatePaymentLoading, onHideHandler) {
  if (!isCreatePaymentLoading && isCreatePaymentSuccess){
    onHideHandler();
  }
}

export default WithCreatePaymentPopup;