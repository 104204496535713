import BookingColumnType from "@types/BookingColumnType";
import mapInvoiceLinks from "@mappers/mapInvoiceLinks";

import {
  createTextCell,
  createInvoicesCell,
  createPaymentAmountCell,
} from "@utils/dataCreators";

export default function mapBookings(bookings, pagination) {
  return bookings.map((booking, key) => ({
    id: booking.id,
    cells: {
      [BookingColumnType.type.INDEX]: createTextCell(
        BookingColumnType.type.INDEX,
        String(pagination.currentPage * pagination.pageSize + key + 1),
      ),

      [BookingColumnType.type.STATUS]: createTextCell(
        BookingColumnType.type.STATUS,
        booking.status_name,
      ),

      [BookingColumnType.type.INVOICE]: createInvoicesCell(
        BookingColumnType.type.INVOICE,
        booking.invoice.id
          ? mapInvoiceLinks([mapBookingInvoice(booking.invoice)])
          : [],
        // todo: refactor payload creation
        {},
        false,
      ),

      [BookingColumnType.type.RECEIPT]: createTextCell(
        BookingColumnType.type.RECEIPT,
        booking.name,
      ),

      [BookingColumnType.type.BANK_NAME]: createTextCell(
        BookingColumnType.type.BANK_NAME,
        booking.bank,
      ),

      [BookingColumnType.type.USAGE]: createTextCell(
        BookingColumnType.type.USAGE,
        booking.description,
      ),

      [BookingColumnType.type.DATE]: createTextCell(
        BookingColumnType.type.DATE,
        booking.date,
      ),

      [BookingColumnType.type.NET_AMOUNT]: createPaymentAmountCell(
        BookingColumnType.type.NET_AMOUNT,
        String(booking.status_key),
        booking.net_amount,
      ),

      [BookingColumnType.type.GROSS_AMOUNT]: createPaymentAmountCell(
        BookingColumnType.type.GROSS_AMOUNT,
        String(booking.status_key),
        booking.cross_amount,
      ),

      [BookingColumnType.type.RECEIPT_TYPE]: createTextCell(
        BookingColumnType.type.RECEIPT_TYPE,
        booking.receipt_type,
      ),
    }
  }));
}

function mapBookingInvoice(invoice) {
  // (invoice_number !== name)
  return {
    id: invoice.id,
    name: invoice.invoice_number,
  };
}