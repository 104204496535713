import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

import {
  Link,
} from 'react-router-dom';

import './HorizontalSelect.scss';

HorizontalSelect.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function HorizontalSelect({className , localPath, options, value, onChange}) {

  const {t} = useTranslation();

  function handleOnSelect(e) {
    const {classList, dataset} = e.target;

    const index = classList.contains('horizontal-select');

    if (index < -1) return;

    const
      newValue = dataset.value,
      isAlreadySelected = newValue === value;

    if (isAlreadySelected) return;

    onChange(e, newValue);
  }

  return (
    <div
      className={[
          'horizontal-select',
          className,
      ].join(' ')}
      onClick={handleOnSelect}
    >
      {
        options.map(option => (
          <Link
            className={[
              'horizontal-select__option',
              value === option.value ? 'horizontal-select__option_selected' : null,
            ].join(' ')}
            to={localPath + '/' + option.value}
            key={option.value}
            data-value={option.value}
          >{option.label}</Link>
        ))
      }
    </div>
  );
}