import React from 'react';
import {nanoid} from "nanoid";
import {useTranslation} from "react-i18next";

import {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import {
  useCurrentDataSelector,
} from '@hooks/index';

import {
  useLazyGetUnfinishedInvoicePaymentQuery,
  useCreatePaymentMutation,
} from '@api/payments';

import {
  CreateInvoicePaymentPopup,
} from "@components/index";
import mappers from "@/mappers";

function WithCreateInvoicePaymentPopup(Component){
  function NewComponentWithCreateInvoicePaymentPopup(props) {

    const {t} = useTranslation();

    const [info, setInfo] = useState({});

    const [
      triggerGetUnfinishedInvoicePayment,
      getUnfinishedInvoicePaymentResult,
    ] = useLazyGetUnfinishedInvoicePaymentQuery();

    const {
      isFetching: isGetUnfinishedPaymentModelFetching,
    } = getUnfinishedInvoicePaymentResult;

    const [
      triggerCreatePayment,
      {
        isSuccess: isCreatePaymentSuccess,
        isLoading: isCreatePaymentLoading,
      },
    ] = useCreatePaymentMutation();

    const onShowHandler = useCallback(
      (invoiceId) => handleOnShowPopup(invoiceId, setInfo, triggerGetUnfinishedInvoicePayment),
      [],
    );

    const onHideHandler = useCallback(
      () => handleOnHide(setInfo),
      [],
    );

    useEffect(
      () => hideOnCreationFinish(isCreatePaymentSuccess, isCreatePaymentLoading, onHideHandler),
      [isCreatePaymentSuccess, isCreatePaymentLoading]
    );

    const currentUnfinishedInvoicePayment = useCurrentDataSelector(getUnfinishedInvoicePaymentResult);

    const initialData = useMemo(
      () => createInitialData(currentUnfinishedInvoicePayment),
      [currentUnfinishedInvoicePayment],
    );

    const {
      initialValues,
      initialReceiverOption,
      initialUserOption,
      initialDescriptionOption,
    } = initialData || {};

    return (
      <>
        <Component
          {...props}
          onShowCreateInvoicePaymentPopup={onShowHandler}
        />
        {
          info.key
            ? (
              <CreateInvoicePaymentPopup
                key={info.key}
                isVisible={!!info.isVisible}
                isLoading={isGetUnfinishedPaymentModelFetching}
                initialValues={initialValues}
                initialReceiverOption={initialReceiverOption}
                initialUserOption={initialUserOption}
                initialDescriptionOption={initialDescriptionOption}
                onFormSubmit={triggerCreatePayment}
                onCloseButtonClick={onHideHandler}
                isFormSubmitting={isCreatePaymentLoading}
              />
            )
            : null
        }
      </>
    );
  }

  return NewComponentWithCreateInvoicePaymentPopup;
}

function handleOnShowPopup(invoiceId, setInfo, triggerGetUnfinishedInvoicePayment) {
  setInfo({
    isVisible: true,
    key: nanoid(),
  });

  triggerGetUnfinishedInvoicePayment({
    invoiceId: invoiceId,
  });
}

function handleOnHide(setInfo) {
  setInfo(prevState => ({
    ...prevState,
    isVisible: false,
  }));
}

function hideOnCreationFinish(isCreatePaymentSuccess, isCreatePaymentLoading, onHideHandler) {
  if (!isCreatePaymentLoading && isCreatePaymentSuccess){
    onHideHandler();
  }
}

function createInitialData(currentUnfinishedInvoicePayment) {
  if (!currentUnfinishedInvoicePayment) return null;

  const payment = currentUnfinishedInvoicePayment;

  return {
    initialValues: {
      receiver: payment.receiver.type.id,
      name: payment.receiver.id,
      typeTransfer: payment.transfer.id,
      amount: payment.amount,
      usage: payment.usage,
      description: payment.creation_type.id,
      checkedInvoiceId: payment.invoice.id,
    },
    initialReceiverOption: mappers.SelectOptionView.parse(payment.receiver.type),
    initialUserOption: mappers.SelectOptionView.parse(payment.receiver),
    initialDescriptionOption: mappers.SelectOptionView.parse(payment.creation_type),
  }
}

export default WithCreateInvoicePaymentPopup;