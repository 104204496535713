import PropTypes from "prop-types";

const options = {
  INDEX: 'index',
  INVOICE: 'invoice',
  BOOKING: 'booking',
  NAME: 'name',
  USAGE: 'usage',
  DATE: 'date',
  PRICE: 'price',
  LEFT_PRICE: 'leftPrice',
};

const DefaultTransactionColumnType = {
  propType: PropTypes.oneOf(Object.values(options)),
  type: options,
};

export default DefaultTransactionColumnType;