import "./Main.scss";

import React from 'react';

import svgs from "@assets/svgs";
import utils from '@utils/index';
import Permission from "@types/Permission";

import {
  useMemo,
} from "react";

import {
  useSelector
} from "react-redux";

import {
  useTranslation,
} from "react-i18next";

import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import {
  useWithDispatchCallback,
} from "@hooks/index";

import {
  saveCurrentAuthData,
} from '@slices/auth';

import {
  selectSalaryCounter,
  selectPaymentCounter,
} from '@slices/socket';

import {
  NavBar,
  TopBar,
} from "@components/index";

import {
  Dashboard,
  Transactions,
  OpenPayment,
  Bookings,
} from "@fragments/index";

export default function Main({permissions}) {

  const {t} = useTranslation();

  const salaryCounter = useSelector(selectSalaryCounter);
  const paymentCounter = useSelector(selectPaymentCounter);

  const defaultLinks = useMemo(
    () => createDefaultLinks(t, salaryCounter, paymentCounter),
    [t, salaryCounter, paymentCounter],
  );

  const translationsLink = useMemo(
    () => createTranslationsLink(t, permissions),
    [t, permissions],
  );

  const settingsLink = useMemo(
    () => createSettingsLink(t, permissions),
    [t, permissions],
  );

  const links = useMemo(
    () => createNavbarLinks(defaultLinks, settingsLink, translationsLink),
    [defaultLinks, settingsLink, translationsLink],
  );

  const logoutLink = useMemo(
    () => createLogoutLink(t),
    [t],
  );

  return (
    <main className="main">
      <NavBar
        links={links}
        logoutLink={logoutLink}
      />
      <div className={'main__content'}>
        <TopBar/>
        <div className={'main__fragment-container'}>
          <Switch>
            <Route exact path={'/'}>
              <Redirect to={'/dashboard'}/>
            </Route>

            {/* todo: replaces redirects by fragments  */}
            <Route path={'/dashboard'} component={Dashboard}/>
            {/* Base data */}
            <Route path={'/user'}><Reload/></Route>
            <Route path={'/department'}><Reload/></Route>
            <Route path={'/banks'}><Reload/></Route>
            {/* Bank Transfers */}
            <Route path={'/payment-list'}><Reload/></Route>
            {/* Salary */}
            <Route path={'/import-payments'}><Reload/></Route>
            <Route path={'/employee'}><Reload/></Route>
            <Route path={'/tax-office'}><Reload/></Route>
            <Route path={'/health-insurance'}><Reload/></Route>
            <Route path={'/unknown'}><Reload/></Route>
            {/* Payment */}
            <Route path={'/transactions'} component={Transactions}/>
            <Route path={'/open-payment'} component={OpenPayment}/>
            <Route path={'/bookings'} component={Bookings}/>
            {/* Accounting */}
            <Route path={'/invoice'}><Reload/></Route>
            {/* Transaction */}
            <Route path={'/message'}><Reload/></Route>
            {/* Settings */}
            <Route path={'/categories'}><Reload/></Route>
            <Route path={'/invoice-accounts'}><Reload/></Route>
            <Route path={'/file-recipient-category'}><Reload/></Route>
            {/* Logout */}
            <Route path={'/site/logout'}><Reload/></Route>
          </Switch>
        </div>
      </div>
    </main>
  );
}

function createDefaultLinks(t, salaryCounter, paymentCounter) {
  return [
    {
      name: t('Dashboard'),
      to: "/dashboard",
      SvgIcon: svgs.Dashboard,
      additionalUrlsToMatch: [],
    },
    {
      name: t('Base Data'),
      SvgIcon: svgs.BaseData,
      links: [
        {name: t('Users'), to: "/user", SvgIcon: svgs.Users},
        {name: t('Organizations'), to: "/department", SvgIcon: svgs.Organization},
        {name: t('Banks'), to: "/banks", SvgIcon: svgs.Banks},
      ],
    },
    {
      name: t('Bank Transfers'),
      SvgIcon: svgs.Bank,
      links: [
        {name: t('Payment list'), to: "/payment-list", SvgIcon: svgs.PaymentList},
      ],
    },
    {
      name: t('Salary'),
      to: "/import-payments",
      counter: salaryCounter,
      SvgIcon: svgs.Salary,
      links: [
        {name: t('Employee'), to: "/employee", SvgIcon: svgs.Employee},
        {name: t('Tax Office'), to: "/tax-office", SvgIcon: svgs.Tax},
        {name: t('Health Insurance'), to: "/health-insurance", SvgIcon: svgs.Insurance},
        {name: t('Unknown'), to: "/unknown", SvgIcon: svgs.Unknown},
      ],
    },
    {
      name: t('Payment'),
      counter: paymentCounter,
      SvgIcon: svgs.Payment,
      links: [
        {name: t('Transactions'), to: "/transactions", SvgIcon: svgs.Transactions},
        {name: t('Open Payment'), to: "/open-payment", SvgIcon: svgs.OpenPayment},
        {name: t('Bookings'), to: "/bookings", SvgIcon: svgs.Booking},
      ],
    },
    {
      name: t('Accounting'),
      SvgIcon: svgs.Document,
      links: [
        {name: t('All Invoices'), to: "/invoice", SvgIcon: svgs.Invoice},
      ],
    },
  ];
}

function createSettingsLink(t, permissions) {
  const link = {
    name: t('Settings'),
    SvgIcon: svgs.Settings,
    links: [
      {name: t('Accounting'), to: "/categories", SvgIcon: svgs.Document},
      {name: t('Invoice Import'), to: "/invoice-accounts", SvgIcon: svgs.Download},
      {name: t('Employee'), to: "/file-recipient-category", SvgIcon: svgs.Users},
    ],
  };

  return utils.renderByPermission(permissions, Permission.type.SETTINGS_NAV_ITEMS, link)
}

function createTranslationsLink(t, permissions) {
  const link = {
    name: t('Translation'),
    to: "/message",
    SvgIcon: svgs.Translation,
    additionalUrlsToMatch: [],
  };

  return utils.renderByPermission(permissions, Permission.type.TRANSACTION_NAV_ITEM, link);
}

function createNavbarLinks(defaultLinks, settingsLink, translationsLink) {
  const links = [...defaultLinks];

  if (translationsLink) {
    links.push(translationsLink);
  }

  if (settingsLink) {
    links.push(settingsLink);
  }

  return links;
}

function createLogoutLink(t) {
  return {
    name: t('Log Out'),
    to: "/auth",
    SvgIcon: svgs.Logout,
  };
}

function Reload() {
  const saveAuthData = useWithDispatchCallback(saveCurrentAuthData);
  saveAuthData();
  if (process.env.NODE_ENV === 'production') {

    window.location.reload();
  }

  return null;
}