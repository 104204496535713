import React from 'react';
import {useTranslation} from "react-i18next";

import {
  InputField,
  OutputFormikSelectField,
  OutputFormikInputField,
} from "@components/index";

function UpdateInvoicePaymentForm({userOptions, descriptionOptions}) {

  const {t} = useTranslation();

  return (
    <>
      <OutputFormikSelectField
        className={'create-payment-popup__field'}
        name={'name'}
        label={t('Name')}
        options={userOptions}
      />
      <OutputFormikSelectField
        className={'create-payment-popup__field'}
        name={'description'}
        label={t('Description')}
        options={descriptionOptions}
      />
      <OutputFormikInputField
        className={'create-payment-popup__field'}
        name={'amount'}
        label={t('Amount')}
      />
      <InputField
        className={'create-payment-popup__field'}
        name={'usage'}
        label={t('Usage')}
      />
    </>
  );
}

export default UpdateInvoicePaymentForm;