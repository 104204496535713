import {
  useRef,
  useEffect,
} from 'react';

function useFormError(error, setFieldError) {

  const setFieldErrorRef = useRef(setFieldError);

  useEffect(
    () => showErrors(error, setFieldErrorRef),
    [error],
  );
}

function showErrors(error, setFieldErrorRef) {
  const setFieldError = setFieldErrorRef.current;

  if (!setFieldError) return;
  if (!error || !error.fields) return;

  for (const field of error.fields) {
    if (!field.error) continue;
    setFieldError(field.name, field.error);
  }
}

export default useFormError;