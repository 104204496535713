import {
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";

function useEffectOnDepsMutation(effect, deps) {
  // const [callback] = useState(() => effect);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const callback = useCallback(effect, deps);

  const isInitialRenderRef = useRef(isInitialRender);
  isInitialRenderRef.current = isInitialRender;

  useEffect(
    () => handleDepsMutation(isInitialRenderRef, setIsInitialRender, callback),
    [callback],
  );
}

function handleDepsMutation(isInitialRenderRef, setIsInitialRender, callback) {
  if (isInitialRenderRef.current) {
    setIsInitialRender(false);
    return;
  }

  return callback();
}

export default useEffectOnDepsMutation;