import React from 'react';
import PropTypes from 'prop-types';

import {
  useCallback,
} from 'react';

import {
  useWithDispatchCallback,
} from '@hooks/index';

import {
  saveCurrentAuthData,
} from '@slices/auth';

import {
  TextLink,
} from '@components/index';

FibuLink.propTypes = {
  onClick: PropTypes.func,
};

FibuLink.defaultProps = {
  onClick: new Function(),
};

function FibuLink({onClick, ...props}) {

  const saveCurrentAuthDataCallback = useWithDispatchCallback(saveCurrentAuthData);

  const onClickHandler = useCallback(
    (event) => handleOnClick(event, saveCurrentAuthDataCallback, onClick),
    [saveCurrentAuthDataCallback, onClick],
  );

  return (
    <TextLink
      {...props}
      onClick={onClickHandler}
    />
  );
}

function handleOnClick(event, saveCurrentAuthDataCallback, onClick) {
  saveCurrentAuthDataCallback();
  onClick(event);
}

export default FibuLink;