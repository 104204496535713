import React from 'react';
import PropTypes from 'prop-types';

import {
  Language,
} from '@classes/index';

import ReactSelect from "react-select";

import './LanguageSelect.scss';

LanguageSelect.propTypes = {
  className: PropTypes.string,
  activeLanguage: PropTypes.instanceOf(Language).isRequired,
  languages: PropTypes.arrayOf(PropTypes.instanceOf(Language)).isRequired,
};

function LanguageSelect({className, activeLanguage, languages, ...props}) {
  return (
    <ReactSelect
      {...props}
      value={activeLanguage}
      className={'language-select'}
      classNamePrefix={'language-select'}
      isClearable={false}
      isSearchable={false}
      options={languages}
      components={{
        ValueContainer: CustomControl,
        SingleValue: CustomValue,
        Menu: CustomMenu,
        Option: CustomOption,
        IndicatorsContainer: EmptyComponent,
        Placeholder: EmptyComponent,
      }}
      // defaultValue={languages[0]}
    />
  );
}


function LanguageComponent({className, title, Icon, ...props}) {
  return (
    <div
      {...props}
      className={[
        'language-select__custom-option',
        className,
      ].join(' ')}
    >
      <Icon className={'language-select__custom-option-icon'}/>
      <p className={'language-select__custom-option-short-title'}>{title}</p>
    </div>
  );
}

function CustomValue({innerRef, innerProps, data}) {
  return <LanguageComponent
    {...innerProps}
    className={'language-select__custom-option_role_header'}
    Icon={data.Icon}
    title={data.shortTitle}
    ref={innerRef}
    />;
}

function CustomOption({innerRef, innerProps, data}) {
  return <LanguageComponent
    {...innerProps}
    className={'language-select__custom-option_role_option'}
    Icon={data.Icon}
    title={data.title}
    ref={innerRef}
  />;
}

function CustomMenu({innerRef, innerProps, children}) {
  return (
    <div
      {...innerProps}
      className={'language-select__custom-menu'}
      ref={innerRef}
    >
      {children}
    </div>
  );
}

function CustomControl({children}) {
  return children;
}

function EmptyComponent() {
  return null;
}

export default LanguageSelect;