import utils from '@utils/index';
import {authApi} from "@api/./authApi";

const resource = 'translation';

export const localesAPI = authApi.injectEndpoints({
  endpoints: (builder) => ({

    /**
     * will return transactions for chosen language based on an appropriate header
     */
    getLocal: builder.query({
      queryFn: async (arg, api1, extraOptions, baseQuery) => {

        const response = await baseQuery({
          url: resource + '/index',
        });

        return utils.handleQueryFnResponse(response);
      }
    }),

  }),
});

export const {
  useLazyGetLocalQuery,
} = localesAPI;