import './CreatePaymentPopup.scss';

import React from 'react';
import PropTypes from 'prop-types';

import types from '@types/index';

import CreateInvoicePaymentPopup from "./CreateInvoicePaymentPopup";

import {
  useCallback,
} from 'react';

import {
  useCurrentDataSelector,
} from '@hooks/index';

import {
  useLazyGetTypeTransferOptionsQuery,
} from "@api/payments";

CreateInvoicePaymentPopupContainer.propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  initialReceiverOption: types.SelectOption,
  initialUserOption: types.SelectOption,
  initialDescriptionOption: types.SelectOption,
  initialTypeTransferOption: types.SelectOption,
  onFormSubmit: PropTypes.func.isRequired,
};

CreateInvoicePaymentPopupContainer.defaultProps = {
  onFormSubmit: new Function(),
};

function CreateInvoicePaymentPopupContainer({
                                              initialReceiverOption,
                                              initialUserOption,
                                              initialDescriptionOption,
                                              initialTypeTransferOption,
                                              onFormSubmit,
                                              ...props
                                            }) {

  const [
    triggerGetTypeTransferOptions,
    getTypeTransferOptionsResult,
  ] = useLazyGetTypeTransferOptionsQuery();

  const {
    data: typeTransferOptions,
    isFetching: isTypeTransferOptionsFetching,
  } = getTypeTransferOptionsResult;

  const onFormSubmitHandler = useCallback(
    (fields) => handleOnFormSubmit(fields, onFormSubmit),
    [onFormSubmit],
  );

  const currentTypeTransferOptions = useCurrentDataSelector(getTypeTransferOptionsResult);

  const receiverOptions = initialReceiverOption
    ? [initialReceiverOption]
    : [];

  const userOptions = initialUserOption
    ? [initialUserOption]
    : [];

  const descriptionOptions = initialDescriptionOption
    ? [initialDescriptionOption]
    : [];

  const initialTypeTransferOptions = initialTypeTransferOption
    ? [initialTypeTransferOption]
    : [];

  const finalTypeTransferOptions = typeTransferOptions
    ? currentTypeTransferOptions || []
    : initialTypeTransferOptions;

  return (
    <CreateInvoicePaymentPopup
      {...props}
      receiverOptions={receiverOptions}
      userOptions={userOptions}
      descriptionOptions={descriptionOptions}
      typeTransferOptions={finalTypeTransferOptions}
      isTypeTransferOptionsLoading={isTypeTransferOptionsFetching}
      onGetTypeTransferOptions={triggerGetTypeTransferOptions}
      onFormSubmit={onFormSubmitHandler}
    />
  );
}

function handleOnFormSubmit(fields, onFormSubmit) {
  onFormSubmit({
    checkedInvoiceId: fields.checkedInvoiceId,
    typeTransfer: fields.typeTransfer,
    amount: fields.amount,
    usage: fields.usage,
  });
}

export default CreateInvoicePaymentPopupContainer;