import PropTypes from 'prop-types';

const options = {
  INVOICES_AMOUNTS_SUM_BIGGER_THEN_TRANSACTION_AMOUNT: 'INVOICES_AMOUNTS_SUM_BIGGER_THEN_TRANSACTION_AMOUNT',
  SERVER_SIDE_FORM_VALIDATION_ERRORS: 'SERVER_SIDE_FORM_VALIDATION_ERRORS',
  NO_ACCESS_TOKEN: 'NO_ACCESS_TOKEN',
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  ACCESS_TOKEN_TOO_OLD: 'ACCESS_TOKEN_TOO_OLD',
  REFRESH_TOKEN_FAILED: 'REFRESH_TOKEN_FAILED',
  ERROR_INSTANCE_CREATION_FAILED: 'ERROR_INSTANCE_CREATION_FAILED',
  NETWORK_CONNECTION_FAIL: 'NETWORK_CONNECTION_FAIL',
  IMPOSSIBLE_ADVANCES_PAYMENT: 'IMPOSSIBLE_ADVANCES_PAYMENT',
};

const ErrorType = {
  propType: PropTypes.oneOf(Object.values(options)),
  type: options,
};

export default ErrorType;