import i18next from "i18next";

import validators from '@yup/index';
import CreatePaymentColumnType from '@types/CreatePaymentColumnType';
import {Table} from '@components/index';
import {createColumn} from "@utils/dataCreators";

function createCreatePaymentColumns() {
  return {

    [CreatePaymentColumnType.type.INVOICE]: createColumn(
      CreatePaymentColumnType.type.INVOICE,
      i18next.t('Invoice'),
      'create-payment-popup__invoice-cell',
      {type: Table.Cell.type.INPUT_FIELD, validator: validators.invoiceNumber},
    ),

    [CreatePaymentColumnType.type.AMOUNT]: createColumn(
      CreatePaymentColumnType.type.AMOUNT,
      i18next.t('Amount'),
      'create-payment-popup__amount-cell',
      {type: Table.Cell.type.INPUT_FIELD, validator: validators.amount},
    ),

    [CreatePaymentColumnType.type.COMPLETE_NAME]: createColumn(
      CreatePaymentColumnType.type.COMPLETE_NAME,
      i18next.t('Complete Name'),
      'create-payment-popup__complete-name-cell',
      {type: Table.Cell.type.INPUT_FIELD, validator: validators.text},
    ),

    [CreatePaymentColumnType.type.RADIO_BUTTON]: createColumn(
      CreatePaymentColumnType.type.RADIO_BUTTON,
      '',
      'create-payment-popup__radio-button-cell',
    ),

  };
}

export default createCreatePaymentColumns;