import './BlockProperty.scss';

import React from 'react';
import PropTypes from "prop-types";

BlockProperty.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
  comment: PropTypes.node,
  iconClassName: PropTypes.string,
  Icon: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.element,
  ]).isRequired,
};

function BlockProperty({
                         className,
                         label,
                         value,
                         comment,
                         iconClassName,
                         Icon,
                         ...props}) {

  return (
    <div
      {...props}
      className={[
        'block-property',
        className
      ].join(' ')}
    >
      <div className={'block-property__content'}>
        <Icon
          className={[
            'block-property__icon',
            iconClassName
          ].join(' ')}
        />
        <div>
          <p className={'block-property__label'}>{label}</p>
          <p className={'block-property__value'}>{value}</p>
        </div>
      </div>
      {
        comment
          ? (
            <div className={'block-property__footer'}>
              <p className={'block-property__comment'}>
                {comment}
              </p>
            </div>
          )
          : null
      }
    </div>
  );
}

export default BlockProperty;