function createIsSliceMatcher(slice) {
  return function isSlice(action) {
    const slashIndex = action.type.indexOf('/');

    const typeEndIndex = slashIndex > -1 ? slashIndex : action.type.length

    const type = action.type.slice(0, typeEndIndex);

    return type === slice.reducerPath;
  }
}

export default createIsSliceMatcher;