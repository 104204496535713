import i18next from "i18next";

import LogColumn from '@types/LogColumn';
import {createColumn} from "@utils/dataCreators";

function createLogColumns() {
  return {

    [LogColumn.type.QUICK_DESCRIPTION]: createColumn(
      LogColumn.type.QUICK_DESCRIPTION,
      i18next.t(''),
    ),

  };
}

export default createLogColumns;