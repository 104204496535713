import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

import {
  Checkbox,
} from '@components/index';

import './TextCheckbox.scss';

TextCheckbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  checkboxProps: PropTypes.instanceOf(Object).isRequired,
};

TextCheckbox.defalutProps = {
  checkboxProps: {},
};

function TextCheckbox({className, label, checkboxProps, ...props}) {

  const {t} = useTranslation();

  return (
    <label
      {...props}
      className={[
        'text-checkbox',
        className,
      ].join(' ')}>
      <Checkbox
        checkboxProps={checkboxProps}
        className={'text-checkbox__checkbox'}
      />
      <span className={'text-checkbox__label'}>{t(label)}</span>
    </label>
  );
}

export default TextCheckbox;

