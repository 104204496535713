import React from 'react';
import PropTypes from 'prop-types';

import {
  useEffect,
  useRef,
} from 'react';

import {
  useField,
  useFormikContext,
} from "formik";

const propTypes = {
  name: PropTypes.string.isRequired,
  dependencies: PropTypes.arrayOf(PropTypes.any),
  fallback: PropTypes.any,
  onFallback: PropTypes.func,
};

const defaultProps = {
  dependencies: [],
  fallback: null,
  onFallback: new Function(),
};

function WithDependenciesField(Component) {
  NewComponent.propTypes = propTypes;
  NewComponent.defaultProps = defaultProps;

  function NewComponent({name, dependencies, fallback, forceFallbackIfHasDefault, onFallback, ...props}) {
    const [field, meta, formik] = useField(name);
    const {values} = useFormikContext();

    const isUninitializedRef = useRef(true);

    const fallbackRef = useRef(fallback);
    fallbackRef.current = fallback;

    const onFallbackRef = useRef(onFallback);
    onFallbackRef.current = onFallback;

    const dependenciesValues = dependencies.map((dep) => values[dep]);

    useEffect(
      () => {
        if (isUninitializedRef.current) {
          isUninitializedRef.current = false;

          if (forceFallbackIfHasDefault && field.value) {
            callFallback(dependencies, values, onFallbackRef);
          }

          return;
        }

        if (dependencies.length < 1) return;

        formik.setValue(fallbackRef.current);

        callFallback(dependencies, values, onFallbackRef);
      },
      [...dependenciesValues],
    )

    return <Component
      name={name}
      {...props}
    />;
  }

  return NewComponent;
}

function callFallback(dependencies, values, onFallbackRef) {
  const deps = dependencies.reduce((deps, depName) => {
    deps[depName] = values[depName];
    return deps;
  }, {});

  onFallbackRef.current(deps);
}

export default WithDependenciesField;