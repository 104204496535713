import i18next from "i18next";

import MonitoringColumn from '@types/MonitoringColumn';
import {createColumn} from "@utils/dataCreators";

function createMonitoringColumns() {
  return {
    [MonitoringColumn.type.SCAN]: createColumn(
      MonitoringColumn.type.SCAN,
      i18next.t('Scan'),
      'dashboard__monitoring-scan-cell',
    ),

    [MonitoringColumn.type.INCOME]: createColumn(
      MonitoringColumn.type.INCOME,
      i18next.t('Income'),
      'dashboard__monitoring-income-cell',
    ),

    [MonitoringColumn.type.ERROR]: createColumn(
      MonitoringColumn.type.ERROR,
      i18next.t('Error'),
      'dashboard__monitoring-error-cell',
    ),

  };
}

export default createMonitoringColumns;