// todo: refactor hardcoded transaction categories
export const rawCashRegisterCategory = {
  id: 'cash_register',
  bank_id: 'cash_register',
  name: 'Cash Register',
  isCashRegister: true,
};

const rawAllCategory = {
  id: 'all',
  bank_id: 'all',
  name: 'All',
};

export default function mapTransactionCategories(categories) {

  if (!categories) return [];

  const copy = categories.slice();

  copy.push(rawCashRegisterCategory, rawAllCategory);

  return copy.map(category => ({
    name: String(category.id),
    label: category.name,
    importBankId: String(category.id), // id for import
    bankId: category.bank_id, // id for searching
    status: category.status,
    isImportAvailable: category.status === 1,
    isDepositAvailable: !!category.isCashRegister,
    isCashRegister: !!category.isCashRegister,
    routeName: category.name
      .trim()
      .toLowerCase()
      .replaceAll(' ', '-')
      .replaceAll('(', '')
      .replaceAll(')', ''),
  }));
}