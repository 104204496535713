import "react-datepicker/dist/react-datepicker.min.css";
import './Datepicker.scss';

import React from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';

import utils from '@utils/index';

import CustomInput from './CustomInput';

import {
  useCallback,
  useState,
} from 'react';

import {
  useSelector,
} from "react-redux";

import {
  selectLanguage
} from "@slices/locales";

Datepicker.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Date).isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default function Datepicker({className, name, value, placeholder, onChange, ...props}) {

  const [isOpen, setIsOpen] = useState(false);

  const language = useSelector(selectLanguage);

  const onChangeHandler = useCallback (
    (value, event) => handleOnChange(value, event, name, onChange, setIsOpen),
    [name, onChange],
  );

  const onClearHandler = useCallback(
    (event) => handleOnClear(event, onChangeHandler, setIsOpen),
    [onChangeHandler],
  );

  const onInputClickHandler = useCallback(
    () => setIsOpen(true),
    [],
  );

  const onClickOutsideHandler = useCallback(
    () => setIsOpen(false),
    [],
  );

  return <ReactDatePicker
    {...props}
    className={className}
    open={isOpen}
    locale={language}
    selected={value}
    placeholderText={placeholder || 'dd.MM.yyyy'}
    dateFormat={"dd.MM.yyyy"}
    customInput={<CustomInput isFocused={isOpen} onClear={onClearHandler}/>}
    onInputClick={onInputClickHandler}
    onClickOutside={onClickOutsideHandler}
    onChange={onChangeHandler}
  />;
}

function handleOnChange(value, event, name, onChange, setIsOpen) {
  setIsOpen(false);
  onChange(utils.createEvent(value, name));
}

function handleOnClear(event, onChangeHandler, setIsOpen) {
  event.stopPropagation()
  setIsOpen(false);
  onChangeHandler(null, event)
}
