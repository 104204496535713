import i18next from "i18next";
import types from "@types/index";
import validators from '@yup/index';
import {createColumn} from "@utils/dataCreators";
import {Table} from "@components/index";

function createCashRegisterColumns() {
  return {
    [types.CashRegisterTransactionColumnType.type.INDEX]: createColumn(
      types.CashRegisterTransactionColumnType.type.INDEX,
      '№',
      'transactions__index-cell'
    ),

    [types.CashRegisterTransactionColumnType.type.INVOICE_LINKS]: createColumn(
      types.CashRegisterTransactionColumnType.type.INVOICE_LINKS,
      i18next.t('Invoice'),
      'transactions__invoice-cell'
    ),

    [types.CashRegisterTransactionColumnType.type.CREATED_AT]: createColumn(
      types.CashRegisterTransactionColumnType.type.CREATED_AT,
      i18next.t('Created At'),
      'transactions__created-at',
      {type: Table.Cell.type.DATE_RANGE_PICKER_FIELD},
    ),

    [types.CashRegisterTransactionColumnType.type.PAYMENT_DATE]: createColumn(
        types.CashRegisterTransactionColumnType.type.PAYMENT_DATE,
        i18next.t('Date'),
        'transactions__date-cell',
        {type: Table.Cell.type.DATE_RANGE_PICKER_FIELD},
    ),

    [types.CashRegisterTransactionColumnType.type.NAME]: createColumn(
      types.CashRegisterTransactionColumnType.type.NAME,
      i18next.t('Name'),
      'transactions__name-cell',
      {type: Table.Cell.type.INPUT_FIELD, validator: validators.text},
    ),

    [types.CashRegisterTransactionColumnType.type.USAGE]: createColumn(
      types.CashRegisterTransactionColumnType.type.USAGE,
      i18next.t('Usage'),
      'transactions__usage-cell',
      {type: Table.Cell.type.INPUT_FIELD, validator: validators.text},
    ),

    [types.CashRegisterTransactionColumnType.type.PRICE]: createColumn(
      types.CashRegisterTransactionColumnType.type.PRICE,
      i18next.t('Amount'),
      'transactions__price-cell',
      {type: Table.Cell.type.INPUT_FIELD, validator: validators.amount},
    ),
  };
}

export default createCashRegisterColumns;