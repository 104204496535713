import './OutputTable.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

OutputTable.propTypes = {
  className: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
};

function OutputTable({className, rows, ...props}) {

  const {t} = useTranslation();

  return (
    <ul
      {...props}
      className={[
          'output-table',
          className,
      ].join(' ')}
    >
      {
        rows.map(row => (
          <li className={'output-table__row'} key={row.label}>
            <div className={'output-table__field-name'}>{t(row.label)}</div>
            <div className={'output-table__field-value'}>{row.value}</div>
          </li>
        ))
      }
    </ul>
  );
}

export default OutputTable;