import React from 'react';
import PropTypes from 'prop-types';

import '../Table.scss';

TextCell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
};

function TextCell({className, children, ...props}) {
  return (
    <div
      {...props}
      className={[
        'table__text-cell',
        className,
      ].join(' ')}
    >
      <p className={'table__text'}>{children}</p>
    </div>
  );
}

export default TextCell;