import {ReactComponent as Dashboard} from "@assets/svg/dashboard.svg";
import {ReactComponent as BaseData} from "@assets/svg/data.svg";
import {ReactComponent as Bank} from "@assets/svg/bank.svg";
import {ReactComponent as Salary} from "@assets/svg/salary.svg";
import {ReactComponent as Payment} from "@assets/svg/payment.svg";
import {ReactComponent as Transactions} from "@assets/svg/transactions.svg";
import {ReactComponent as OpenPayment} from "@assets/svg/open-payment.svg";
import {ReactComponent as Booking} from "@assets/svg/bookings.svg";
import {ReactComponent as Document} from "@assets/svg/document.svg";
import {ReactComponent as Settings} from "@assets/svg/settings.svg";
import {ReactComponent as Logout} from "@assets/svg/logout.svg";
import {ReactComponent as ArrowDown} from "@assets/svg/chevron-down.svg";
import {ReactComponent as Logo} from "@assets/svg/logo.svg";
import {ReactComponent as Balance} from "@assets/svg/balance.svg";
import {ReactComponent as Close} from "@assets/svg/close.svg";
import {ReactComponent as Chevron} from "@assets/svg/chevron-down.svg";
import {ReactComponent as Calendar} from "@assets/svg/calendar.svg";
import {ReactComponent as Booked} from "@assets/svg/status/booked.svg";
import {ReactComponent as HalfBooked} from "@assets/svg/status/half-booked.svg";
import {ReactComponent as NotBooked} from "@assets/svg/status/not-booked.svg";
import {ReactComponent as AddButton} from "@assets/svg/add-button.svg";
import {ReactComponent as English} from "@assets/svg/lang/en.svg";
import {ReactComponent as German} from "@assets/svg/lang/de.svg";
import {ReactComponent as Profile} from "@assets/svg/profile.svg";
import {ReactComponent as Edit} from "@assets/svg/edit.svg";
import {ReactComponent as Delete} from "@assets/svg/delete.svg";
import {ReactComponent as Plus} from "@assets/svg/plus.svg";
import {ReactComponent as Error} from "@assets/svg/error.svg";
import {ReactComponent as Warning} from "@assets/svg/warning.svg";
import {ReactComponent as Car} from "@assets/svg/car.svg";
import {ReactComponent as Poco} from "@assets/svg/poco.svg";
import {ReactComponent as Garage} from "@assets/svg/garage.svg";
import {ReactComponent as Illustration} from "@assets/svg/illustration.svg";
import {ReactComponent as Loading} from "@assets/svg/loading.svg";
import {ReactComponent as Translation} from "@assets/svg/translation.svg";
import {ReactComponent as Users} from "@assets/svg/users.svg";
import {ReactComponent as Banks} from "@assets/svg/banks.svg";
import {ReactComponent as Organization} from "@assets/svg/organization.svg";
import {ReactComponent as PaymentList} from "@assets/svg/payment-list.svg";
import {ReactComponent as Unknown} from "@assets/svg/unknown.svg";
import {ReactComponent as Insurance} from "@assets/svg/insurance.svg";
import {ReactComponent as Tax} from "@assets/svg/tax.svg";
import {ReactComponent as Employee} from "@assets/svg/employee.svg";
import {ReactComponent as Invoice} from "@assets/svg/invoice.svg";
import {ReactComponent as Download} from "@assets/svg/download.svg";
import {ReactComponent as CashRegister} from "@assets/svg/hand-holding-usd-solid.svg";

export default {
  Dashboard,
  BaseData,
  Bank,
  Salary,
  Payment,
  Transactions,
  OpenPayment,
  Booking,
  Document,
  Settings,
  Logout,
  Logo,
  ArrowDown,
  Balance,
  Close,
  Chevron,
  Calendar,
  Booked,
  HalfBooked,
  NotBooked,
  AddButton,
  English,
  German,
  Profile,
  Edit,
  Delete,
  Plus,
  Error,
  Warning,
  Car,
  Poco,
  Garage,
  Illustration,
  Loading,
  Translation,
  Users,
  Banks,
  Organization,
  PaymentList,
  Unknown,
  Insurance,
  Tax,
  Employee,
  Invoice,
  Download,
  CashRegister
};