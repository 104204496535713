import MissingDocumentColumn from "@types/MissingDocumentColumn";

import {
  createLinkCell,
  createTextCell,
} from '@utils/dataCreators';

function mapMissingDocuments(response) {
  return response.map((document) => ({
    id: document.id,
    cells: {

      [MissingDocumentColumn.type.EMPLOYEE]:
        createLinkCell(
          MissingDocumentColumn.type.EMPLOYEE,
          '',
          document.name,
          document.url,
        ),

      [MissingDocumentColumn.type.MISSING_FILES]:
        createTextCell(
          MissingDocumentColumn.type.MISSING_FILES,
          String(document.counter),
        ),

    }
  }));
}

export default mapMissingDocuments;