import React from "react";
import PropTypes from 'prop-types';

import {
  Table,
} from "@components/index";

Row.propTypes = {
  cells: PropTypes.object.isRequired,
  columns: PropTypes.object.isRequired,
  componentsAdditionalProps: PropTypes.object,
};

Row.defaultProps = {
  componentsAdditionalProps: {},
  columnsAdditionalProps: {},
};

function Row({className, cells, columns, componentsAdditionalProps}) {
  return (
    <Table.Row>
      {
        Object.values(columns).map(column => {

          const cell = cells[column.name] || {};

          return (
            <Table.Cell
              {...cell.props}
              {...componentsAdditionalProps[cell.type]}
              className={[
                className,
                column.className,
              ].join(' ')}
              type={cell.type}
              value={cell.value}
              key={column.name}
            />
          );
        })
      }
    </Table.Row>
  );
}

export default Row;