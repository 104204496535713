import './Table.scss';

import React from 'react';
import PropTypes from 'prop-types';

import types from '@types/index';

import {
  Datepicker,
  DateRangePicker,
  Input,
  Select,
} from "@components/index";

import EmptyCell from './cells/EmptyCell';
import TextCell from './cells/TextCell';
import HighlightedTextCell from './cells/HighlightedTextCell';
import TitleCell from './cells/TitleCell';
import PaymentAmount from './cells/PaymentAmount';
import BookingCell from './cells/BookingCell';
import InvoiceLinksCell from './cells/InvoiceLinksCell';
import FieldCell from './cells/FieldCell';
import ButtonCell from './cells/ButtonCell';
import ConfirmPaymentsCell from './cells/ConfirmPaymentsCell';
import NetGrossAmountCell from './cells/NetGrossAmountCell';
import CheckboxCell from './cells/CheckboxCell';
import RadioButtonCell from './cells/RadioButtonCell';
import LinkCell from './cells/LinkCell';

Cell.type = types.CellType.type;

const components = {
  [Cell.type.EMPTY]:
    ({value, fieldProps, ...props}) => <EmptyCell {...props}/>,

  [Cell.type.TEXT]:
    ({value, ...props}) => <TextCell {...props}>{value}</TextCell>,

  [Cell.type.HIGHLIGHTED_TEXT]:
    ({value, ...props}) => <HighlightedTextCell {...props}>{value}</HighlightedTextCell>,

  [Cell.type.TITLE]:
    ({value, ...props}) => <TitleCell {...props}>{value}</TitleCell>,

  [Cell.type.PAYMENT_AMOUNT]:
    ({value, ...props}) => <PaymentAmount {...props}>{value}</PaymentAmount>,

  [Cell.type.BOOKING]:
    ({value, ...props}) => <BookingCell {...props}/>,

  [Cell.type.INVOICE_LINKS]:
    ({value, ...props}) => <InvoiceLinksCell {...props}/>,

  [Cell.type.INPUT_FIELD]:
    ({...props}) => <FieldCell Component={Input} {...props}/>,

  [Cell.type.SELECT_FIELD]:
    ({...props}) => <FieldCell Component={Select} {...props}/>,

  [Cell.type.DATEPICKER_FIELD]:
    ({...props}) => <FieldCell Component={Datepicker} {...props}/>,

  [Cell.type.DATE_RANGE_PICKER_FIELD]:
    ({...props}) => <FieldCell Component={DateRangePicker} {...props}/>,

  [Cell.type.BUTTON]:
      ({...props}) => <ButtonCell {...props}/>,

  [Cell.type.CHECKBOX]:
    ({...props}) => <CheckboxCell {...props}/>,

  [Cell.type.RADIO_BUTTON]:
    ({...props}) => <RadioButtonCell {...props}/>,

  [Cell.type.CONFIRM_PAYMENTS]:
    ({...props}) => <ConfirmPaymentsCell {...props}/>,

  [Cell.type.NET_GROSS_AMOUNT]:
    ({value, isPositive, ...props}) => <NetGrossAmountCell isPositive={isPositive} {...props}>{value}</NetGrossAmountCell>,

  [Cell.type.LINK]: LinkCell,
};

Cell.propTypes = {
  className: PropTypes.string,
  type: types.CellType.propType.isRequired,
};

Cell.defaultProps = {
  type: Cell.type.EMPTY,
};

function Cell({className, type, value, ...props}) {

  const Component = components[type];

  return (
    <Component
      {...props}
      className={[
        'table__cell-wrapper',
        className,
      ].join(' ')}
      value={value}
    />
  );
}

export default Cell;