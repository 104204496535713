import PropTypes from 'prop-types';

const options = {
  INVOICE: "INVOICE",
  AMOUNT: "AMOUNT",
  COMPLETE_NAME: "COMPLETE_NAME",
  RADIO_BUTTON: "RADIO_BUTTON",
};

const CreatePaymentColumnType = {
  propType: PropTypes.oneOf(Object.values(options)),
  type: options,
};

export default CreatePaymentColumnType;