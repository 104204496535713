import mappers from '@mappers/index';
import types from '@types/index';
import tags from './tags';
import {api} from './index';
import utils from "@/utils";
import i18next from "i18next";

const resource = 'payments';

export const paymentsApi = api.injectEndpoints({

  endpoints: (builder) => ({

    getPayments: builder.query({
      queryFn: async (args, api1, extraOptions, baseQuery) => {
        const params = utils.createQueryParams()
          .add('page', args.page)
          .add('amount', args[types.PaymentColumnType.type.AMOUNT])
          .add('usage', args[types.PaymentColumnType.type.USAGE])
          .add('test_data', Number(process.env.NODE_ENV === 'development'));

        if (args[types.PaymentColumnType.type.STATUS]) {
          params.add('status', {
            id: args[types.PaymentColumnType.type.STATUS],
          });
        }

        if (args[types.PaymentColumnType.type.RECEIVER]) {
          params.add('receiver', {
            name: args[types.PaymentColumnType.type.RECEIVER],
          });
        }

        if (args[types.PaymentColumnType.type.DESCRIPTION]) {
          params.add('output_type', {
            id: args[types.PaymentColumnType.type.DESCRIPTION],
          });
        }

        const response = await baseQuery({
          url: resource + '/index',
          params: params.finish(),
        });

        const mapPayments = (payments) => {
          const models = payments.map(mappers.PaymentModel.parse);
          const views = models.map(mappers.PaymentView.parse);
          return { models, views };
        };

        const mapper = utils.createPaginatedMapper(mapPayments, 'payments');

        return utils.handleQueryFnResponse(response, mapper);
      },
      providesTags: [tags.PAYMENTS],
    }),

    getStatusOptions: builder.query({
      queryFn: async (arg, api1, extraOptions, baseQuery) => {
        const response = await baseQuery(resource + '/get-status');
        return utils.handleQueryFnResponse(response, mappers.mapSelectOptions);
      },
    }),

    getDescriptionOptions: builder.query({
      queryFn: async (arg, api1, extraOptions, baseQuery) => {
        const response = await baseQuery(resource + '/get-description');
        return utils.handleQueryFnResponse(response, mappers.mapSelectOptions);
      },
    }),

    getReceiverOptions: builder.query({
      queryFn: async (arg, api1, extraOptions, baseQuery) => {
        const response = await baseQuery(resource + '/get-receiver-type');
        return utils.handleQueryFnResponse(response, mappers.mapSelectOptions);
      },
    }),

    getNameOptions: builder.query({
      queryFn: async (arg, api1, extraOptions, baseQuery) => {
        const response = await baseQuery(resource + `/get-receiver-name?receiver_type=${arg.receiverType}`);
        return utils.handleQueryFnResponse(response, mappers.mapSelectOptions);
      },
    }),

    getCreationDescriptionOptions: builder.query({
      queryFn: async (arg, api1, extraOptions, baseQuery) => {
        const response = await baseQuery(resource + `/get-type-description?receiver_name=${arg.receiverName}`);
        return utils.handleQueryFnResponse(response, mappers.mapSelectOptions);
      },
    }),

    getTypeTransferOptions: builder.query({
      queryFn: async (arg, api1, extraOptions, baseQuery) => {
        const response = await baseQuery(resource + `/get-type-transfer`);
        return utils.handleQueryFnResponse(response, mappers.mapSelectOptions);
      },
    }),

    getPaymentCreationInvoices: builder.query({
      queryFn: async (arg, api1, extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: resource + '/get-invoice-list',
          params: {
            page: arg.page,
            receiver_name: arg.receiverName,
            ['InvoicesSearch[amount]']: arg[types.CreatePaymentColumnType.type.AMOUNT],
            ['InvoicesSearch[receiver]']: arg[types.CreatePaymentColumnType.type.COMPLETE_NAME],
            ['InvoicesSearch[invoice_number]']: arg[types.CreatePaymentColumnType.type.INVOICE],
          }
        });

        const mapper = utils.createPaginatedMapper(mappers.mapPaymentCreationInvoices, 'invoices');

        return utils.handleQueryFnResponse(response, mapper);
      },
    }),

    getUnfinishedInvoicePayment: builder.query({
      queryFn: async (arg, api1, extraOptions, baseQuery) => {
        const invoiceResponse = await baseQuery(`invoice/get-invoice?invoice_id=${arg.invoiceId}`,);

        const descriptionOptionResponse = await baseQuery(resource + '/get-type-description-by-invoice');

        const response = {
          data: {
            invoice: invoiceResponse.data.invoice,
            description: descriptionOptionResponse.data,
          },
        };

        const mapper = function (response) {
          return mappers.PaymentModel.parseInvoicePayment(response.invoice, response.description);
        }

        return utils.handleQueryFnResponse(response, mapper);
      },
    }),

    confirmPayment: builder.mutation({
      queryFn: async (args, api1, extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: resource + '/confirm-payments',
          method: 'POST',
          params: {
            user_id: args.userId,
            payment_id: args.paymentId,
          },
        });

        return utils.handleQueryFnResponse(response);
      },
      // todo: rewrite on optimistic update
      invalidatesTags: [tags.PAYMENTS],
    }),

    createPayment: builder.mutation({
      queryFn: async (arg, api1, extraOptions, baseQuery) => {
        const params = utils.createQueryParams();

        if ('usage' in arg) {
          params.add('usage', arg.usage);
        }

        if ('amount' in arg) {
          params.add('amount', arg.amount);
        }

        if ('description' in arg) {
          params.add('creation_type', {
            id: arg['description'],
          });
        }

        if ('name' in arg) {
          params.add('receiver', {
            id: arg['name'],
          });
        }

        if ('checkedInvoiceId' in arg) {
          params.add('invoice', {
            id: arg['checkedInvoiceId'],
          });
        }

        if ('typeTransfer' in arg) {
          params.add('type_transfer', {
            id: arg['typeTransfer'],
          });
        }

        const response = await baseQuery({
          url: resource + '/create',
          method: 'POST',
          params: params.finish(),
        });

        return utils.handleQueryFnResponse(
          response,
          (response) => response,
          i18next.t('Creation failed'),
        );
      },
      invalidatesTags: (result, error) => {
        return error ? [] : [tags.PAYMENTS];
      },
    }),

    updatePayment: builder.mutation({
      queryFn: async (arg, api1, extraOptions, baseQuery) => {
        const params = utils.createQueryParams();

        params.add('id', arg.id);

        if ('usage' in arg) {
          params.add('usage', arg.usage);
        }

        if ('amount' in arg) {
          params.add('amount', arg.amount);
        }

        if ('description' in arg) {
          params.add('creation_type', {
            id: arg['description'],
          });
        }

        if ('name' in arg) {
          params.add('receiver', {
            id: arg['name'],
          });
        }

        if ('checkedInvoiceId' in arg) {
          params.add('invoice', {
            id: arg['checkedInvoiceId'],
          });
        }

        if ('typeTransfer' in arg) {
          params.add('type_transfer', {
            id: arg['typeTransfer'],
          });
        }

        const response = await baseQuery({
          url: resource + '/update',
          method: 'POST',
          params: params.finish(),
        });

        return utils.handleQueryFnResponse(response);
      },
      invalidatesTags: (result, error) => {
        return error ? [] : [tags.PAYMENTS];
      },
    }),

    deletePayment: builder.mutation({
      queryFn: async (id, api1, extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: resource + '/delete',
          params: utils.createQueryParams()
            .add('id', id)
            .finish(),
        });

        return utils.handleQueryFnResponse(response);
      },
      invalidatesTags: (result, error) => {
        return error ? [] : [tags.PAYMENTS];
      },
    }),

  }),
});

export const {
  useGetPaymentsQuery,
  useLazyGetPaymentsQuery,
  useGetStatusOptionsQuery,
  useGetDescriptionOptionsQuery,
  useGetReceiverOptionsQuery,
  useLazyGetReceiverOptionsQuery,
  useGetNameOptionsQuery,
  useLazyGetNameOptionsQuery,
  useGetCreationDescriptionOptionsQuery,
  useLazyGetCreationDescriptionOptionsQuery,
  useGetTypeTransferOptionsQuery,
  useLazyGetTypeTransferOptionsQuery,
  useGetPaymentCreationInvoicesQuery,
  useLazyGetUnfinishedInvoicePaymentQuery,
  useConfirmPaymentMutation,
  useCreatePaymentMutation,
  useUpdatePaymentMutation,
  useDeletePaymentMutation,

} = paymentsApi;