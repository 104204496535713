import PropTypes from 'prop-types';

const options = {
  DESCRIPTION: 'DESCRIPTION',
  RADIO_BUTTON: 'RADIO_BUTTON',
};

const AddNewCashRegisterColumnType = {
  propType: PropTypes.oneOf(Object.values(options)),
  type: options,
};

export default AddNewCashRegisterColumnType;