import CashRegisterTransactionColumnType from "@types/CashRegisterTransactionColumnType";

import mapInvoiceLinks from './mapInvoiceLinks';
import mapPaginationItemIndex from "./mapPaginationItemIndex";

import {
  createTextCell,
  createInvoicesCell,
} from '@utils/dataCreators';

export default function mapCashRegisterTransactions(transactions, pagination) {
  return transactions.map((transaction, key) => {

    const index = mapPaginationItemIndex(pagination, key);

    return {
      id: transaction.id,
      cells: {
        [CashRegisterTransactionColumnType.type.INDEX]:
          createTextCell(CashRegisterTransactionColumnType.type.INDEX, String(index)),

        [CashRegisterTransactionColumnType.type.INVOICE_LINKS]:
          createInvoicesCell(
            CashRegisterTransactionColumnType.type.INVOICE_LINKS,
            mapInvoiceLinks(transaction.invoice),
            {},
            false,
          ),

        [CashRegisterTransactionColumnType.type.CREATED_AT]:
          createTextCell(CashRegisterTransactionColumnType.type.CREATED_AT, transaction.created_at),

        [CashRegisterTransactionColumnType.type.PAYMENT_DATE]:
          createTextCell(CashRegisterTransactionColumnType.type.CREATED_AT, transaction.payment_date),

        [CashRegisterTransactionColumnType.type.NAME]:
          createTextCell(CashRegisterTransactionColumnType.type.NAME, transaction.name),

        [CashRegisterTransactionColumnType.type.USAGE]:
          createTextCell(CashRegisterTransactionColumnType.type.USAGE, transaction.description),

        [CashRegisterTransactionColumnType.type.PRICE]:
          createTextCell(CashRegisterTransactionColumnType.type.PRICE, transaction.amount),

      }
    };
  });
}