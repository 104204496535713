import PropTypes from 'prop-types';

const options = {
  ATTENTION: 'ATTENTION',
  ERROR: 'ERROR',
};

const BlockMessageType = {
  propType: PropTypes.oneOf(Object.values(options)),
  type: options,
};

export default BlockMessageType;