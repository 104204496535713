import BookingStatus from './BookingStatus';
import CellType from './CellType';
import ButtonType from './ButtonType';
import DefaultTransactionColumnType from './DefaultTransactionColumnType';
import CashRegisterTransactionColumnType from './CashRegisterTransactionColumnType';
import PaymentColumnType from './PaymentColumnType';
import BookingColumnType from './BookingColumnType';
import AvailableInvoiceColumnType from './AvailableInvoiceColumnType';
import BlockMessageType from './BlockMessageType';
import Availability from './Availability';
import SelectOption from './SelectOption';
import ElementRef from './ElementRef';
import Id from './Id';
import PaymentStatus from './PaymentStatus';
import CreatePaymentColumnType from './CreatePaymentColumnType';
import LoaderSizeType from './LoaderSizeType';
import ErrorType from './ErrorType';
import Error from "@types/Error";
import AddNewCashRegisterColumnType from "@types/AddNewCashRegisterColumnType";
import Permission from "@types/Permission";
import MissingDocumentColumn from "./MissingDocumentColumn";
import HistoryColumn from "./HistoryColumn";
import Row from "./Row";
import TabledObject from "./TabledObject";
import MonitoringColumn from "./MonitoringColumn";
import LogColumn from "./LogColumn";

export const types = {
  BookingStatus,
  CellType,
  ButtonType,
  DefaultTransactionColumnType,
  CashRegisterTransactionColumnType,
  PaymentColumnType,
  BookingColumnType,
  AvailableInvoiceColumnType,
  BlockMessageType,
  Availability,
  SelectOption,
  ElementRef,
  Id,
  PaymentStatus,
  CreatePaymentColumnType,
  LoaderSizeType,
  ErrorType,
  Error,
  AddNewCashRegisterColumnType,
  Permission,
  MissingDocumentColumn,
  HistoryColumn,
  Row,
  TabledObject,
  MonitoringColumn,
  LogColumn,
};

export default types;