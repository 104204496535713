import store from '@redux/store';

import {
  setIsConnected,
} from '@slices/socket';

import {
  auth,
} from './actions';

function createOnConnectHandler(socket, userId) {
  function handleOnConnect() {
    store.dispatch(setIsConnected(true));
    dispatch(auth(userId));
  }

  function dispatch(action) {
    return socket.send(JSON.stringify(action));
  }

  return handleOnConnect;
}

export default createOnConnectHandler;