import CreatePaymentColumnType from "@types/CreatePaymentColumnType";

import {
  createTextCell,
  createRadioButtonCell,
} from '@utils/dataCreators';

export default function mapPaymentCreationInvoices(invoices) {
  return invoices.map(invoice => ({
    id: String(invoice.id),
    cells: {

      [CreatePaymentColumnType.type.INVOICE]: createTextCell(
        CreatePaymentColumnType.type.INVOICE,
        invoice.invoice_number,
      ),

      [CreatePaymentColumnType.type.AMOUNT]: createTextCell(
        CreatePaymentColumnType.type.AMOUNT,
        invoice.amount,
      ),

      [CreatePaymentColumnType.type.COMPLETE_NAME]: createTextCell(
        CreatePaymentColumnType.type.COMPLETE_NAME,
        invoice.receiver,
      ),

      [CreatePaymentColumnType.type.RADIO_BUTTON]: createRadioButtonCell(
        CreatePaymentColumnType.type.RADIO_BUTTON,
        false,
      ),

    },
  }));
}