import SelectOptionModel from "@mappers/SelectOptionModel";

class UserModel {
  static default = {
    id: null,
    name: '',
    type: SelectOptionModel.default,
  }

  static parse(response) {
    if (!response) return SelectOptionModel.default;
    return {
      id: String(response.id),
      name: response.name,
      type: SelectOptionModel.parse(response.type)
    };
  }
}

export default UserModel;