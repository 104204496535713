import './OpenPayments.scss';

import React  from 'react';
import {useTranslation} from "react-i18next";
import {compose} from '@reduxjs/toolkit';

import svgs from "@assets/svgs";
import types from "@types/index";
import utils from '@utils/index';
import queryParams from "@assets/queryParams";

import createPaymentColumns from "./createPaymentColumns";

import {
  useCallback,
  useEffect,
} from 'react';

import {
  useSelector,
} from "react-redux";

import {
  useLazyGetPaymentsQuery,
  useGetStatusOptionsQuery,
  useGetDescriptionOptionsQuery,
  useConfirmPaymentMutation,
} from '@api/payments';

import {
  useQueryParams,
  useTableObject,
} from '@hooks/index';

import {
  selectPermissions,
} from "@slices/user";

import {
  Title,
  PaginatedTable,
  Button,
  Table,
} from '@components/index';

import {
  WithShortViewInvoicePopup,
  WithCreatePaymentPopup,
  WithCreateInvoicePaymentPopup,
  WithUpdatePaymentPopup,
  WithDeletePaymentPopup,
} from '@hocs/index';

import {
  useGetUserQuery,
} from '@api/user';

function OpenPayment ({
                        className,
                        staticContext,
                        onShowDeletePaymentPopup,
                        onShowConnectInvoicePopup,
                        onShowShortViewInvoicePopup,
                        onShowCreatePaymentPopup,
                        onShowCreateInvoicePaymentPopup,
                        onShowUpdatePaymentPopup,
                        ...props}) {

  const {t} = useTranslation();

  const query = useQueryParams();

  const permissions = useSelector(selectPermissions);

  const [
    triggerGetPayments,
    paymentsResult,
    paymentsLastPromiseInfo,
  ] = useLazyGetPaymentsQuery()

  const {
    data: paymentsResponse,
    isFetching: arePaymentsFetching,
  } = paymentsResult || {};

  const
    {payments, pagination} = paymentsResponse || {},
    {currentPage, pageSize, totalCount} = pagination || {},
    {views: paymentViews, models: paymentModels} = payments || {};

  const {
    data: statusOptions,
    isLoading: areStatusOptionsLoading,
  } = useGetStatusOptionsQuery();

  const {
    data: descriptionOptions,
    isLoading: areDescriptionOptionsLoading,
  } = useGetDescriptionOptionsQuery();

  const [
    triggerConfirmPayment,
  ] = useConfirmPaymentMutation();

  const {
    data: user,
  } = useGetUserQuery();

  const table = useTableObject(createPaymentColumns, paymentViews);

  const formSubmitHandler = useCallback(
    (fields) => handleFormSubmit(fields, paymentsLastPromiseInfo, triggerGetPayments),
    [paymentsLastPromiseInfo, triggerGetPayments],
  );

  const setCurrentPageHandler = useCallback(
    (page) => handleSetCurrentPage(page, paymentsLastPromiseInfo, triggerGetPayments),
    [paymentsLastPromiseInfo, triggerGetPayments],
  );

  const onUserIconClickHandler = useCallback(
    (event, payload) => handleOnUserIconClick(payload, user, triggerConfirmPayment),
    [user],
  );

  const onShowEditPaymentPopupHandler = useCallback(
    (paymentId) => handleOnShowEditPaymentPopup(paymentId, paymentModels, onShowUpdatePaymentPopup),
    [paymentModels],
  );

  useEffect(
    () => triggerGetPayments({}),
    [],
  );

  useEffect(
    () => handleCreationAsInvoice(query, onShowCreateInvoicePaymentPopup),
    [query],
  );

  return (
    <div
      {...props}
      className={[
        'open-payments',
        className,
      ].join(' ')}
    >
      <div className={'open-payments__top-row'}>
        <Title>{t('Open Payment')}</Title>
        {
          utils.renderByPermission(permissions, types.Permission.type.CREATE_PAYMENT, (
            <Button
              Icon={svgs.Plus}
              onClick={onShowCreatePaymentPopup}
            >
              {t('Add recipient')}
            </Button>
          ))
        }
      </div>
      <PaginatedTable
        className={'open-payments__table-wrapper'}
        table={table}
        currentPage={currentPage || 0}
        totalCount={totalCount || 0}
        pageSize={pageSize}
        isLoading={arePaymentsFetching}
        tableProps={{
          className: 'open-payments__table',
        }}
        componentsAdditionalProps={{
          [Table.Cell.type.CONFIRM_PAYMENTS]: {
            currentUserId: user && user.id,
            onUserIconClick: onUserIconClickHandler,
            onDeleteButtonClick: onShowDeletePaymentPopup,
            onEditButtonClick: onShowEditPaymentPopupHandler,
          },
          [Table.Cell.type.BUTTON]: {
            label: t('Preview'), // todo: make column specific
            onClick: onShowShortViewInvoicePopup,
          }
        }}
        columnsAdditionalProps={{
          [types.PaymentColumnType.type.STATUS]: {
            options: statusOptions,
            isLoading: areStatusOptionsLoading,
          },
          [types.PaymentColumnType.type.DESCRIPTION]: {
            options: descriptionOptions,
            isLoading: areDescriptionOptionsLoading,
          },
        }}
        onSubmit={formSubmitHandler}
        onCurrentPageChange={setCurrentPageHandler}
      />
    </div>
  );
}

function handleSetCurrentPage(page, paymentsLastPromiseInfo, triggerGetPayments) {
  triggerGetPayments({
    ...paymentsLastPromiseInfo,
    page: page,
  });
}

function handleFormSubmit(fields, paymentsLastPromiseInfo, triggerGetPayments) {
  triggerGetPayments({
    ...paymentsLastPromiseInfo,
    ...fields,
  });
}

function handleOnUserIconClick(payload, user, triggerConfirmPayment) {
  if (!user || user.id < -1)
  if (payload.user.hasConfirmed) return;
  if (payload.user.id !== user.id) return;

  triggerConfirmPayment({
    paymentId: payload.paymentId,
    userId: payload.user.id,
  });
}

function handleOnShowEditPaymentPopup(paymentId, paymentModels, onShowUpdatePaymentPopup) {
  if (!paymentModels) return;

  const model = paymentModels.find((model) => {
    return model.id === paymentId
  });

  if (!model) return;

  onShowUpdatePaymentPopup(paymentId, model);
}

function handleCreationAsInvoice(query, onShowCreateInvoicePaymentPopup) {
  const invoiceId = query.get(queryParams.INVOICE_ID);

  if (!invoiceId) return;

  onShowCreateInvoicePaymentPopup(invoiceId);
}

export default compose(
  WithCreatePaymentPopup,
  WithCreateInvoicePaymentPopup,
  WithUpdatePaymentPopup,
  WithDeletePaymentPopup,
  WithShortViewInvoicePopup,
)(OpenPayment);