import i18n from 'i18n';

import {
  useRef,
  useEffect,
} from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  useLazyGetLocalQuery,
} from '@api/locales';

import {
  selectLanguage,
} from '@slices/locales';

import {
  useCurrentDataSelector,
  useEffectOnDepsMutation,
} from '@hooks/index';

function LocalGate({children}) {

  const language = useSelector(selectLanguage);

  const [
    trigger,
    result,
  ] = useLazyGetLocalQuery();

  const local = useCurrentDataSelector(result);

  useEffect(
    () => trigger(),
    [],
  );

  const languageRef = useRef(language);
  languageRef.current = language;

  useEffectOnDepsMutation(
    () => applyLocal(local, languageRef),
    [local],
  );

  return children;
}

function applyLocal(local, languageRef) {
  i18n.addResourceBundle(languageRef.current, 'translation', local);
  i18n.changeLanguage(languageRef.current);
}

export default LocalGate;