import React from 'react';
import PropTypes from 'prop-types';

import {
  useState,
  useRef,
  useEffect,
  useMemo,
} from 'react';

import {
  useLocation,
} from "react-router-dom";

import {
  useTranslation,
} from "react-i18next";

import Link from "./Link/Link";
import DropDown from "./DropDown/DropDownContainer";

import "./NavBar.scss";
import svgs from "@assets/svgs";

NavBar.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        ...Link.propTypes,
        additionalUrlsToMatch: PropTypes.arrayOf(PropTypes.string),
      }),
      PropTypes.shape(DropDown.propTypes),
  ])),
  logoutLink: PropTypes.shape({
    name: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    SvgIcon: PropTypes.object.isRequired,
  }).isRequired,
};

export default function NavBar({links, logoutLink}) {
  const {t} = useTranslation();
  const {pathname} = useLocation();
  const [backDropStyle, setBackDropStyle] = useState();
  const {activeLinkIndex, nestedIndex} = useMemo(calcActiveLinkIndexAndNestedIndex, [links, pathname]);
  const [lastOpenedDropDownIndex, setLastOpenedDropDownIndex] = useState(-1);
  const backDropRef = useRef();
  const navigationMenuRef = useRef();
  const linksRefs = useRef([]);

  function calcActiveLinkIndexAndNestedIndex() {
    let nestedIndex = -1;

    const activeLinkIndex = links.findIndex(l => {
      if (l.to) {
        const isMatch = getDoesMatchCurrentPathname(l);
        if (isMatch) return true;
      }
      if (l.links) {
        for (let i = 0; i < l.links.length; i++){
          if (getDoesMatchCurrentPathname(l.links[i])) {
            nestedIndex = i;
            return true;
          }
        }

        return false;
      }

      return false;
    });

    return {
      activeLinkIndex,
      nestedIndex,
    };
  }

  function getDoesMatchCurrentPathname(link) {
    if (compareLinkWithPath(link.to, pathname)) return true;

    if (!link.additionalUrlsToMatch) return false;

    return link.additionalUrlsToMatch.find(url => compareLinkWithPath(url, pathname));
  }

  function compareLinkWithPath(link, path) {
    const pathStart = path.split('/').find((part) => !!part);
    const linkStart = link.split('/').find((part) => !!part);
    return pathStart === linkStart;
  }

  function handleDropDownResize(event) {
    if (activeLinkIndex < 0) return;

      const eventDropDown = event.ref.current;

      const eventDropDownIndex = linksRefs.current.findIndex((ref) => ref.current === eventDropDown);

      if (activeLinkIndex === eventDropDownIndex){
        setBackDropStyle((prevState) => ({
          ...prevState,
          height: event.newHeight,
        }));

        return;
      }

      if (eventDropDownIndex < activeLinkIndex) {
        const delta = event.newHeight - eventDropDown.offsetHeight;

        setBackDropStyle((prevState) => ({
          ...prevState,
          top: prevState.top + delta,
        }));
      }
  }

  function updateBackdrop() {
    if (activeLinkIndex > -1) {
      const
        activeLink = linksRefs.current[activeLinkIndex].current,
        linksContainer = navigationMenuRef.current;

      setBackDropStyle({
        height: activeLink.offsetHeight,
        top: activeLink.getBoundingClientRect().top - linksContainer.getBoundingClientRect().top,
      });
    }
  }

  useEffect(updateBackdrop, [activeLinkIndex]);

  return (
    <aside className={"navigation__sidebar"}>
      <header className={"navigation__header"}>
        <svgs.Logo className={"navigation__logo"}/>
      </header>
      <nav className={"navigation__nav"}>
        <div className="navigation__menu" ref={navigationMenuRef}>
          {
            links.map((l, key) => {
              linksRefs.current[key] = React.createRef();

              if (!l.links) {
                return <Link
                  className={"navigation__link"}
                  name={l.name}
                  to={l.to}
                  isDark={activeLinkIndex === key}
                  isActive={activeLinkIndex === key}
                  SvgIcon={l.SvgIcon}
                  key={key}
                  ref={linksRefs.current[key]}
                />
              }

              return <DropDown
                className={"navigation__link"}
                name={l.name}
                to={l.to}
                counter={l.counter}
                links={l.links}
                isDark={activeLinkIndex === key}
                isActive={activeLinkIndex === key}
                nestedIndex={nestedIndex}
                shouldImmediatelyCloseOnce={lastOpenedDropDownIndex === -1 ? false : lastOpenedDropDownIndex !== key}
                SvgIcon={l.SvgIcon}
                key={key}
                onResize={handleDropDownResize}
                onOpen={() => setLastOpenedDropDownIndex(key)}
                ref={linksRefs.current[key]}
              />
            })
          }
          <div className="navigation__backdrop backdrop" style={backDropStyle} ref={backDropRef}>
            <div className="backdrop__body"></div>
            <div className="backdrop__outer-border"></div>
          </div>
        </div>
        <div className="navigation__footer">
          <Link
            className={"navigation__link"}
            name={logoutLink.name}
            to={logoutLink.to}
            SvgIcon={logoutLink.SvgIcon}
          />
        </div>
      </nav>
    </aside>
  );
}






