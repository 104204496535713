import mapPagination from "@mappers/mapPagination";

function createPaginatedMapper(mapper, resourceName, entriesName) {
  return function paginatedMapper(response) {
    const outputEntriesName = entriesName || resourceName;

    const pagination = mapPagination(response._meta);

    return {
      [outputEntriesName]: mapper(response[resourceName], pagination),
      pagination: pagination,
    };
  }
}

export default createPaginatedMapper;