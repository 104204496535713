import './ViewInvoicePopup.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {
  Popup,
  PDFViewerPDFJSContainer,
} from '@components/index';

ShortViewInvoicePopup.propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  pdfPath: PropTypes.string.isRequired,
  onBackgroundClick: PropTypes.func.isRequired,
  onCloseButtonClick: PropTypes.func.isRequired,
}

function ShortViewInvoicePopup({className, isVisible, pdfPath,
                            onBackgroundClick, onCloseButtonClick, ...props}) {

  return (
    <Popup {...props} isVisible={isVisible} onBackgroundClick={onBackgroundClick}>
      <Popup.Container className={'view-invoice-popup-container_maximized'}>
        <Popup.Header title={'Preview Invoice'} onCloseButtonClick={onCloseButtonClick}/>
        <Popup.Content className={'view-invoice-popup'}>
          <div className={'view-invoice-popup__layout'}>
            <PDFViewerPDFJSContainer
              className={'view-invoice-popup__pdf-viewer'}
              pdfPath={pdfPath}
            />
          </div>
        </Popup.Content>
      </Popup.Container>
    </Popup>
  );
}

export default ShortViewInvoicePopup;