import MonitoringColumn from "@types/MonitoringColumn";

import {
  createTextCell,
} from '@utils/dataCreators';

function mapInvoiceSource(response) {
  return response.map((source) => ({
    title: source.label,
    row: {
      id: source.id,
      cells: {

        [MonitoringColumn.type.SCAN]: createTextCell(
          MonitoringColumn.type.SCAN,
          source.counter_scan,
        ),

        [MonitoringColumn.type.INCOME]: createTextCell(
          MonitoringColumn.type.INCOME,
          source.counter_income,
        ),

        [MonitoringColumn.type.ERROR]: createTextCell(
          MonitoringColumn.type.ERROR,
          source.counter_error,
        ),

      },
    },
  }));
}

export default mapInvoiceSource;