import './BlockMessage.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

import svgs from '@assets/svgs';
import BlockMessageType from '@types/BlockMessageType';
import Paragraph from "./Paragraph";

BlockMessage.type = BlockMessageType.type;

const typeProps = {
  [BlockMessage.type.ATTENTION]: {
    rootClassName: 'block-message_type_attention',
    title: 'Attention',
    Icon: svgs.Error,
  },
  [BlockMessage.type.ERROR]: {
    rootClassName: 'block-message_type_error',
    title: 'Error',
    Icon: svgs.Warning,
  },
};

BlockMessage.propTypes = {
  className: PropTypes.string,
  type: BlockMessageType.propType,
  children: PropTypes.node.isRequired,
  onCloseButtonClick: PropTypes.func.isRequired,
};

BlockMessage.defaultTypes = {
  type: BlockMessage.type.ATTENTION,
};

function BlockMessage({className, type, children, onCloseButtonClick, ...props}) {

  const {t} = useTranslation();

  const currentTypeProps = typeProps[type];

  return (
    <div
      {...props}
      className={[
        'block-message',
        currentTypeProps.rootClassName,
        className,
      ].join(' ')}
    >
      <currentTypeProps.Icon className={'block-message__icon'}/>
      <div className={'block-message__header'}>
        <p className={'block-message__title'}>{t(currentTypeProps.title)}!</p>
        <svgs.Close className={'block-message__close-button'} onClick={onCloseButtonClick}/>
      </div>
      <div className={'block-message__content'}>
        <p className={'block-message__text'}>{children}</p>
      </div>
    </div>
  );
}

BlockMessage.Paragraph = Paragraph;

export default BlockMessage;