import './Dashboard.scss';

import React from 'react';
import PropTypes from 'prop-types';

Section.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  ActionButton: PropTypes.func,
  onActionButtonClick: PropTypes.func,
};

function Section({className, title, children, ActionButton, onActionButtonClick, ...props}) {
  return (
    <article
      {...props}
      className={[
        'dashboard__section',
        className,
      ].join(' ')}
    >
      <header className={'dashboard__section-header'}>
        <h3 className={'dashboard__section-title'}>{title}</h3>
        {
          ActionButton
            ? (
              <ActionButton
                className={'dashboard__section-action-button'}
                onClick={onActionButtonClick}
              />
            )
            : null
        }
      </header>
      {children}
    </article>
  );
}

export default Section;