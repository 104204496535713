import React from 'react';
import PropTypes from 'prop-types';

import './Booking.scss';
import svgs from '@assets/svgs';
import types from '@types/index';

Booking.status = types.BookingStatus.status;

const components = {
  [Booking.status.BOOKED]: svgs.Booked,
  [Booking.status.HALF_BOOKED]: svgs.HalfBooked,
  [Booking.status.NOT_BOOKED]: svgs.NotBooked,
  ['none']: () => null,
};

Booking.propTypes = {
  className: PropTypes.string,
  status: types.BookingStatus.propType.isRequired,
};

function Booking({className, status, ...props}) {

  const Component = components[status] || components['none'];

  return (
    <div
      {...props}
      className={[
        'booking-container',
        className,
      ].join(' ')}>
      <Component/>
    </div>
  );
}

export default Booking;