import './PDFViewer.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {
  Loader,
} from '@components/index';

PDFViewer.propTypes = {
  className: PropTypes.string,
  canvases: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  onPdfMouseLeave: PropTypes.func.isRequired,
  onMagnifierMouseMove: PropTypes.func.isRequired,
  onMagnifierContainerClick: PropTypes.func.isRequired,
  onScrollContainerScroll: PropTypes.func.isRequired,
  scrollContainerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  magnifierContainerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  magnifierCanvasRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  pfdRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

PDFViewer.defaultProps = {
  canvases: [],
};

function PDFViewer({className, canvases, isLoading,
                     onPdfMouseLeave, onMagnifierMouseMove, onMagnifierContainerClick, onScrollContainerScroll,
                     scrollContainerRef, magnifierContainerRef, magnifierCanvasRef, pfdRef, ...props}) {

  return (
    <div
      {...props}
      className={[
        'pdf-viewer',
        className,
      ].join(' ')}
    >
      <div className={'pdf-viewer__scroll-container'} onScroll={onScrollContainerScroll} ref={scrollContainerRef}>
        {
          isLoading
            ? <Loader className={'pdf-viewer__loader'}/>
            : null
        }
        <div
          className={[
            'pdf-viewer__pdf',
            isLoading ? 'pdf-viewer__pdf_hidden' : null,
          ].join(' ')}
          onClick={onMagnifierContainerClick}
          onMouseMove={onMagnifierMouseMove}
          onMouseLeave={onPdfMouseLeave}
          ref={pfdRef}
        >
          <div className={'pdf-viewer__magnifier-container'} ref={magnifierContainerRef}>
            <canvas className={'pdf-viewer__magnifier'} ref={magnifierCanvasRef}/>
          </div>
          {
            canvases.map((ref, key) => <canvas className={'pdf-viewer__canvas'} key={key} ref={ref}/>)
          }
        </div>
      </div>
    </div>
  );
}

export default PDFViewer;
