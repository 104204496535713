import {
  useState,
  useEffect,
} from 'react';

function useFiredOnceEffect(callback, deps) {
  const [hasFired, setHasFired] = useState(false);

  useEffect(
    () => handleEffect(callback, deps, hasFired, setHasFired),
    [hasFired, ...deps],
  );
}

function handleEffect(callback, deps, hasFired, setHasFired) {
  if (hasFired) return;

  const result = !!callback(...deps);
  setHasFired(result);
}

export default useFiredOnceEffect;