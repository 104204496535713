import React from 'react';
import PropTypes from 'prop-types';

import {
  Checkbox,
} from '@components/index';

import '../Table.scss';

CheckboxCell.propTypes = {
  className: PropTypes.string,
  value: PropTypes.bool.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

function CheckboxCell({className, value, name, onChange, ...props}) {
  return (
    <div
      {...props}
      className={[
        'table__checkbox-container-cell',
        className,
      ].join(' ')}
    >
      <Checkbox checkboxProps={{value, onChange, name}}/>
    </div>
  );
}

export default CheckboxCell;