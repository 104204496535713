import {
  useCallback,
} from 'react';

function useHandlerWithPayload(handler, payload = {}) {

  const deps = typeof payload === 'object'
    ? Object.values(payload)
    : [payload];

  return useCallback(
    (event) => callHandler(event, payload, handler),
    [handler, ...deps],
  );
}

function callHandler(event, payload, handler) {
  if (!handler) return;
  handler(event, payload);
}

export default useHandlerWithPayload;