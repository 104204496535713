import mapSelectOptions from './mapSelectOptions';
import mapTransactionCategories from './mapTransactionCategories';
import mapDefaultTransactions from './mapDefaultTransactions';
import mapInvoiceLinks from './mapInvoiceLinks';
import mapBalance from './mapBalance';
import mapPagination from './mapPagination';
import mapInvoice from './mapInvoice';
import mapInvoiceFilePath from './mapInvoiceFilePath';
import mapPayments from './mapPayments';
import mapAvailableInvoices from './mapAvailableInvoices';
import mapInvoicesConnectionAvailability from './mapInvoicesConnectionAvailability';
import mapAmount from './mapAmount';
import mapBookings from './mapBookings';
import mapPaymentCreationInvoices from './mapPaymentCreationInvoices';
import mapErrorDescription from './mapErrorDescription';
import mapErrorType from './mapErrorType';
import mapError from './mapError';
import mapFieldsErrors from './mapFieldsErrors';
import mapUser from './mapUser';
import mapImportTransactionResponse from './mapImportTransactionResponse';
import mapTransactionCategoriesIntoSelectOptions from './mapTransactionCategoriesIntoSelectOptions';
import mapBeingAddedToCashRegisterTransaction from './mapBeingAddedToCashRegisterTransaction';
import mapAuthToken from './mapAuthToken';
import mapPaginationItemIndex from './mapPaginationItemIndex';
import mapCashRegisterTransactions from './mapCashRegisterTransactions';
import mapRedirection from "./mapRedirection";
import mapMissingDocuments from "./mapMissingDocuments";
import mapHistory from "./mapHistory";
import mapInvoiceSource from "./mapInvoiceSource";
import mapLogs from "./mapLogs";
import PaymentModel from "./PaymentModel";
import SelectOptionModel from "./SelectOptionModel";
import InvoiceFilePathModel from "./InvoiceFilePathModel";
import PaymentView from "./PaymentView";
import SelectOptionView from "./SelectOptionView";

export const mappers = {
  mapSelectOptions,
  mapTransactionCategories,
  mapDefaultTransactions,
  mapInvoiceLinks,
  mapBalance,
  mapPagination,
  mapInvoice,
  mapInvoiceFilePath,
  mapPayments,
  mapAvailableInvoices,
  mapInvoicesConnectionAvailability,
  mapAmount,
  mapBookings,
  mapPaymentCreationInvoices,
  mapErrorDescription,
  mapErrorType,
  mapError,
  mapFieldsErrors,
  mapUser,
  mapImportTransactionResponse,
  mapTransactionCategoriesIntoSelectOptions,
  mapBeingAddedToCashRegisterTransaction,
  mapAuthToken,
  mapPaginationItemIndex,
  mapCashRegisterTransactions,
  mapRedirection,
  mapMissingDocuments,
  mapHistory,
  mapInvoiceSource,
  mapLogs,
  PaymentModel,
  SelectOptionModel,
  InvoiceFilePathModel,
  PaymentView,
  SelectOptionView,
};

export default mappers;