import React from 'react';

import Link from './Link';

import {
  useHandlerWithPayload,
} from '@hooks/index';

LinkContainer.propTypes = Link.propTypes;

function LinkContainer({id, onClick, ...props}) {

  const onClickHandler = useHandlerWithPayload(onClick, id);

  return (
    <Link
      {...props}
      id={id}
      onClick={onClickHandler}
    />
  );
}

export default LinkContainer;