import PropTypes from "prop-types";

const options = {
  NAME: 'NAME',
  ACTION: 'ACTION',
  DATE: 'DATE',
};

const HistoryColumn = {
  propType: PropTypes.oneOf(Object.values(options)),
  type: options,
};

export default HistoryColumn;

