import "react-datepicker/dist/react-datepicker.min.css";
import './Datepicker.scss';

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';

import utils from '@utils/index';

import CustomInput from './CustomInput';

import {
  useState,
  useCallback,
} from 'react';

import {
  useSelector,
} from "react-redux";

import {
  selectLanguage
} from "@slices/locales";

DateRangePicker.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  error: PropTypes.node,
  isTouched: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};

function DateRangePicker({className, name, value, label, placeholder, error, isTouched, onChange, onBlur, ...props}) {
  const [newStartDate, setNewStartDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [gotStartDate, endDate] = value;
  const startDate = newStartDate || gotStartDate;

  const language = useSelector(selectLanguage);

  const onChangeHandler = useCallback (
    (value, event) => handleOnChange(value, event, name, newStartDate, onChange),
    [name, newStartDate, onChange],
  );

  const onClickOutsideHandler = useCallback(
    () => handleOnClickOutside(newStartDate, gotStartDate, name, onChange),
    [newStartDate, gotStartDate, name, onChange],
  );

  const onInputClickHandler = useCallback(
    () => handleOnInputClick(),
    [],
  );

  const onBlurHandler = useCallback(
    () => handleOnBlur(name, onBlur),
    [name, onBlur],
  );

  const onClearHandler = useCallback(
    (event) => handleOnClear(event, name, onChange, onBlurHandler),
    [name, onChange, onBlurHandler],
  );

  function handleOnChange(value, event, name, newStartDate, onChange) {
    if (newStartDate) {
      setNewStartDate(null);
      setIsOpen(false)

      onChange(utils.createEvent(value, name));
    } else {
      const [startDate] = value;
      setNewStartDate(startDate);
    }
  }

  function handleOnClickOutside(newStartDate, gotStartDate, name, onChange) {
    setIsOpen(false)

    if (!newStartDate) return;

    if (newStartDate !== gotStartDate) {
      setNewStartDate(null)
      onChange(utils.createEvent([newStartDate, newStartDate], name));
    }
  }

  function handleOnInputClick() {
    setIsOpen(true)
  }

  function handleOnClear(event, name, onChange, onBlurHandler) {
    setIsOpen(false);
    setNewStartDate(null);
    onBlurHandler(utils.createEvent([], name))
    onChange(utils.createEvent([], name));

    event.stopPropagation();
  }

  function format(date) {
    if (!date) return '';
    return moment(date).format('DD.MM.YYYY');
  }

  let visibleValue = null;

  if (startDate) {
    visibleValue = [
      format(startDate),
      '-',
      format(endDate),
    ].join(' ');
  }

  const isErrorVisible = error && isTouched;

  return (
    <div {...props} className={className}>
      {
        label
          ? <p className={'datepicker__label'}>{label}</p>
          : null
      }
      <ReactDatePicker
        className={[
          'datepicker__picker',
          isErrorVisible ? 'datepicker__picker_with_error' : null,
        ].join(' ')}
        value={visibleValue}
        open={isOpen}
        locale={language}
        placeholderText={placeholder || 'dd/mm/yyyy'}
        startDate={newStartDate || startDate}
        endDate={newStartDate ? null : endDate}
        selectsRange={true}
        customInput={<CustomInput isFocused={isOpen} onClear={onClearHandler} onBlur={onBlurHandler}/>}
        onInputClick={onInputClickHandler}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        onClickOutside={onClickOutsideHandler}
      />
      {
        isErrorVisible
          ? (
            <div className={'datepicker__error-container'}>
              <p className={'datepicker__error'}>{t(error)}</p>
            </div>
          )
          : null
      }
    </div>
  );
}

function handleOnBlur(name, onBlur) {
  if (!onBlur) return;
  onBlur(utils.createEvent(null, name));
}


export default DateRangePicker;