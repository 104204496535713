import '../Table.scss';

import React from 'react';
import PropTypes from 'prop-types';

FieldCell.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  error: PropTypes.string,
  Component: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  fieldProps: PropTypes.object,
};

function FieldCell({className, name, value, error, Component, onChange, fieldProps, ...props}) {
  return (
    <div
      {...props}
      className={[
        'table__field-cell',
        className,
      ].join(' ')}
    >
      <Component
        {...fieldProps}
        name={name}
        value={value}
        error={error}
        onChange={onChange}
      />
    </div>
  );
}

export default FieldCell;