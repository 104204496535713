import './Dashboard.scss';

import React from 'react';
import {useTranslation} from "react-i18next";

import createMissingDocumentsColumns from "./createMissingDocumentsColumns";
import createHistoryColumns from "./createHistoryColumns";
import createMonitoringColumns from "./createMonitoringColumns";
import Section from "./Section";

import {
  useMemo,
} from 'react';

import {
  useLazyGetMissingDocumentsQuery
} from "@api/documents";

import {
  useLazyGetActionsQuery,
} from '@api/history';

import {
  Title,
  PrimaryText,
  InvoiceCard,
  PaginatedTable,
  AutoGeneratedTable,
  MonitoringTabledList,
  Button,
} from '@components/index';

import {
  WithContainerTable,
  WithLogPopup,
} from '@hocs/index'

const MissingDocumentsPaginatedTable = WithContainerTable(
  PaginatedTable,
  useLazyGetMissingDocumentsQuery,
  createMissingDocumentsColumns,
  getMissingDocumentsData,
);

const HistoryTable = WithContainerTable(
  AutoGeneratedTable,
  useLazyGetActionsQuery,
  createHistoryColumns,
  getHistoryData,
);

function Dashboard({onShowLogPopup}) {

  const {t} = useTranslation();

  const tableProps = useMemo(
    () => ({ className: 'dashboard__missing-documents-table' }),
    [],
  );

  return (
    <div className={'dashboard'}>
      <Title>Dashboard</Title>
      <div className={'dashboard__content'}>
        <div className={'dashboard__left-block'}>
          <Section
            title={t('Open claims')}
          >
            <footer className={'dashboard__section-footer'}>
              <PrimaryText>1.500,64 €</PrimaryText>
              <div className={'dashboard__claims'}>
                <InvoiceCard
                  className={'dashboard__claim-item'}
                  title={t('Due invoice')}
                  price={'1.300.64 €'}
                />
                <InvoiceCard
                  className={'dashboard__claim-item'}
                  title={t('Open invoice')}
                  price={'200.00 €'}
                />
              </div>
            </footer>
          </Section>
          <Section
            className={'dashboard__missing-documents-section'}
            title={t('Missing documents employee')}
          >
            <MissingDocumentsPaginatedTable
              className={'dashboard__missing-documents'}
              tableProps={tableProps}
            />
          </Section>
        </div>
        <div className={'dashboard__right-block'}>
          <Section
            className={'dashboard__monitoring-section'}
            title={t('Monitoring')}
            ActionButton={ShowLogButton}
            onActionButtonClick={onShowLogPopup}
          >
            <MonitoringTabledList
              className={'dashboard__monitoring-list'}
              columnsFactory={createMonitoringColumns}
            />
          </Section>
          <Section
            className={'dashboard__history-section'}
            title={t('History')}
          >
            <HistoryTable
              className={'dashboard__history'}
            />
          </Section>
        </div>
      </div>
    </div>
  );
}

function ShowLogButton(props) {

  const {t} = useTranslation();

  return (
    <Button
      {...props}
      type={Button.type.PRIMARY}
    >
      {t('Show log')}
    </Button>
  );
}

function getMissingDocumentsData(data) {
  return {
    entries: data ? data.documents : null,
    pagination: data ? data.pagination : null ,
  };
}

function getHistoryData(data) {
  return {
    entries: data,
    pagination: null,
  };
}

export default WithLogPopup(Dashboard);