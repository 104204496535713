import './i18n';
import './App.scss';

import React from 'react';
import store from '@redux/store';

import {
  Provider,
} from 'react-redux';

import {
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";

import {
  AuthGate,
  ErrorsGate,
  RedirectionGate,
  LocalGate,
  GlobalLoaderGate,
} from '@components/index';

import {
  MainScreen,
  AuthScreen,
} from "@screens/index";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <RedirectionGate>
          <LocalGate>
            <GlobalLoaderGate>
              <Switch>
                <Route path="/auth">
                  <AuthScreen/>
                </Route>
                <Route path="/">
                  <ErrorsGate>
                    <AuthGate pathToAuth={'/auth'}>
                      <MainScreen/>
                    </AuthGate>
                  </ErrorsGate>
                </Route>
              </Switch>
            </GlobalLoaderGate>
          </LocalGate>
        </RedirectionGate>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
