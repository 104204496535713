import React from 'react';
import PropTypes from 'prop-types';

import '../Table.scss';

NetGrossAmountCell.propTypes = {
  className: PropTypes.string,
  isPositive: PropTypes.bool.isRequired,
};

function NetGrossAmountCell({className, children, isPositive, ...props}) {
  return (
    <div
      {...props}
      className={[
        'table__net-gross-amount-cell',
        className,
      ].join(' ')}
    >
      <p
        className={[
          'table__net-gross-amount-text',
          isPositive ? 'table__net-gross-amount-text_status_positive' : 'table__net-gross-amount-text_status_negative',
          className,
        ].join(' ')}
      >
        {children}
      </p>
    </div>
  );
}

export default NetGrossAmountCell;