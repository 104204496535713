import mappers from '@mappers/index';
import utils from '@utils/index';
import {api} from "@api/index";

const resource = 'dashboard';

export const historyAPI = api.injectEndpoints({
  endpoints: (builder) => ({

    getLogs: builder.query({
      queryFn: async (arg, api1, extraOptions, baseQuery) => {

        const response = await baseQuery({
          url: resource + '/log',
        });

        return utils.handleQueryFnResponse(response, mappers.mapLogs);
      }
    }),

  }),
});

export const {
  useLazyGetLogsQuery,
} = historyAPI;