import '../Table.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {
  FibuLink,
} from '@components/index';

LinkCell.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node.isRequired,
  link: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
  children: PropTypes.string,
};

function LinkCell({className, label, link, url, ...props}) {
  return (
    <div
      {...props}
      className={[
        'table__text-cell',
        className,
      ].join(' ')}
    >
      <p className={'table__text'}>
        {label}
        {' '}
        <FibuLink url={url}>{link}</FibuLink>
      </p>
    </div>
  );
}

export default LinkCell;