import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

import {
  Popup,
  Button,
} from '@components/index';

import './ConfirmPopup.scss';

ConfirmPopup.propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  title: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onCloseButtonClick: PropTypes.func.isRequired,
};

function ConfirmPopup ({className, isVisible, title, content,
                               onButtonClick, onCloseButtonClick, ...props}) {

  const {t} = useTranslation();

  return (
    <Popup
      {...props}
      isVisible={isVisible}
      isSmall={true}
      onBackgroundClick={onCloseButtonClick}
    >
      <Popup.Container className={'confirm-popup__container'}>
        <Popup.Header
          title={title}
          onCloseButtonClick={onCloseButtonClick}
        />
        <Popup.Content className={'confirm-popup__content'}>
          {
            content
              ? <p className={'confirm-popup__question'}>{content}</p>
              : null
          }
        </Popup.Content>
        <Popup.Footer className={'confirm-popup__footer'}>
          <Button
            type={Button.type.OUTLINE}
            onClick={onButtonClick}
          >
            {t('Ok')}
          </Button>
        </Popup.Footer>
      </Popup.Container>
    </Popup>
  );
}

export default ConfirmPopup;