import React from 'react';
import PropTypes from "prop-types";

import '../Table.scss';

TitleCell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string.isRequired,
};

function TitleCell({className, children, ...props}) {
  return (
    <div
      {...props}
      className={[
        'table__title-cell',
        className,
      ].join(' ')}
    >
      <p className={'table__title'}>{children}</p>
    </div>
  );
}

export default TitleCell;