import './FieldError.scss';

import React from 'react';
import PropTypes from 'prop-types';

FieldError.propTypes = {
  className: PropTypes.string,
  error: PropTypes.node,
  isTouched: PropTypes.bool,
};

function FieldError({className, error, isTouched, ...props}) {

  if (!error || !isTouched) return null;

  return (
    <div
      {...props}
      className={[
        'field-error',
        className,
      ].join(' ')}
    >
      <p className={'field-error__message'}>{error}</p>
    </div>
  );
}

export default FieldError;