import PropTypes from "prop-types";

const options = {
  SCAN: 'SCAN',
  INCOME: 'INCOME',
  ERROR: 'ERROR',
};

const MonitoringColumn = {
  propType: PropTypes.oneOf(Object.values(options)),
  type: options,
};

export default MonitoringColumn;

