import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

import initWebsocket from '@websocket/init';

import {
  useEffect,
} from 'react';

import {
  Redirect,
} from 'react-router-dom';

import {
  selectIsAccessTokenExists,
} from '@slices/auth';

import {
  useGetUserQuery,
} from '@api/user';

AuthGate.propTypes = {
  pathToAuth: PropTypes.string.isRequired,
  children: PropTypes.node,
};

function AuthGate({pathToAuth, children}) {
  const isAccessTokenExists = useSelector(selectIsAccessTokenExists);

  const getUserOptions = {
    skip: !isAccessTokenExists,
  };

  const {
    data: user,
  } = useGetUserQuery(undefined, getUserOptions);

  const { id } = user || {};

  useEffect(
    () => handleNewId(id),
    [id],
  );

  if (!isAccessTokenExists) {
    return <Redirect to={pathToAuth}/>;
  }

  return children;
}

function handleNewId(id) {
  if (!id) return;
  initWebsocket(id)
}

export default AuthGate;