import mappers from '@mappers/index';
import utils from '@utils/index';
import BookingColumnType from '@types/BookingColumnType';
import {api} from "@api/index";
import tags from "@api/tags";

const resource = 'bookings';

export const bookingsAPI = api.injectEndpoints({
  endpoints: (builder) => ({

    getBookings: builder.query({
      queryFn: async (arg, api1, extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: resource + '/index',
          params: {
            page: arg.page || 0,
            ['BookingsSearch[name]']: arg[BookingColumnType.type.RECEIPT],
            ['BookingsSearch[status]']: arg[BookingColumnType.type.STATUS],
            ['BookingsSearch[bank_name]']: arg[BookingColumnType.type.BANK_NAME],
            ['BookingsSearch[net_amount]']: arg[BookingColumnType.type.NET_AMOUNT],
            ['BookingsSearch[cross_amount]']: arg[BookingColumnType.type.GROSS_AMOUNT],
            ['BookingsSearch[description]']: arg[BookingColumnType.type.USAGE],
            ['BookingsSearch[date]']: utils.createISODate(arg[BookingColumnType.type.DATE]),
            ['BookingsSearch[invoice_number]']: arg[BookingColumnType.type.INVOICE],
            ['BookingsSearch[receipt_type]']: arg[BookingColumnType.type.RECEIPT_TYPE],
          },
        });

        const paginatedMapper = utils.createPaginatedMapper(mappers.mapBookings, 'bookings');

        return utils.handleQueryFnResponse(response, paginatedMapper);
      },
      providesTags: [tags.TRANSACTIONS],
    }),

    getBankOptions: builder.query({
      queryFn: async (arg, api1, extraOptions, baseQuery) => {
        const response = await baseQuery(resource + '/get-banks-name');
        return utils.handleQueryFnResponse(response, mappers.mapSelectOptions);
      }
    }),

    getBookingStatusOptions: builder.query({
      queryFn: async (arg, api1, extraOptions, baseQuery) => {
        const response = await baseQuery(resource + '/get-status');
        return utils.handleQueryFnResponse(response, mappers.mapSelectOptions);
      }
    }),

    getReceiptTypeOptions: builder.query({
      queryFn: async (arg, api1, extraOptions, baseQuery) => {
        const response = await baseQuery(resource + '/get-receipt-type');
        return utils.handleQueryFnResponse(response, mappers.mapSelectOptions);
      }
    }),

  }),
});

export const {
  useLazyGetBookingsQuery,
  useGetBankOptionsQuery,
  useGetBookingStatusOptionsQuery: useGetStatusOptionsQuery,
  useGetReceiptTypeOptionsQuery,
} = bookingsAPI;