import {
  useRef,
  useCallback,
} from 'react';

export default function useSubmitWithFrequencyLimit(submit, milliseconds) {
  const mutableStateRef = useRef({
    args: {},
    timeoutId: null,
    lastSubmitTimestamp: 0,
  });

  const submitWithArgsCallback = useCallback(
    () => submitWithArgs(submit, mutableStateRef),
    [submit],
  );

  const submitWithFrequencyLimitCallback = useCallback(
    (...args) =>
      submitWithFrequencyLimit(submitWithArgsCallback, milliseconds, mutableStateRef, ...args),
    [submitWithArgsCallback, milliseconds],
  );

  const isSubmitPlanned = !!mutableStateRef.current.timeoutId;

  return [
    submitWithFrequencyLimitCallback,
    isSubmitPlanned,
  ];
}

function submitWithFrequencyLimit(submitWithArgs, milliseconds, mutableStateRef, ...args) {
  mutableStateRef.current.args = args;

  const lastSubmitTimestamp = mutableStateRef.current.lastSubmitTimestamp;

  const isTimeoutSet = mutableStateRef.current.timeoutId;
  const isFirstSubmitDone = !!lastSubmitTimestamp;

  const isTimeLimitPassed =
    isFirstSubmitDone &&
    !isTimeoutSet &&
    Date.now() - lastSubmitTimestamp > milliseconds;

  if (!isFirstSubmitDone || isTimeLimitPassed) {
    submitWithArgs();
    return;
  }

  if (!isTimeoutSet) {
    mutableStateRef.current.timeoutId = setTimeout(submitWithArgs, milliseconds);
  }
}

function submitWithArgs(submit, mutableStateRef) {
  submit(...mutableStateRef.current.args);
  mutableStateRef.current.lastSubmitTimestamp = Date.now();
  mutableStateRef.current.timeoutId = null;
}