import './AddNewCashRegisterPopup.scss';

import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {useTranslation} from "react-i18next";

import types from '@types/index';
import errors from "@yup/errors";

import {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';

import {
  Formik,
  Form,
} from 'formik';

import {
  useSubmitWithFrequencyLimit,
  useFormError,
} from '@hooks/index';

import {
  Popup,
  SelectField,
  Button,
  DateRangePickerField,
  AutoGeneratedRadioButtonTable,
  Pagination,
  FieldError, InputField,
} from '@components/index';

import FormFields from './FormFields';
import {Datepicker} from "@/components";

const addCashRegisterSchema = Yup.object().shape({
  [FormFields.CR_TYPE]: Yup
    .number()
    .nullable()
    .required(errors.required),
  [FormFields.CR_DATE]: Yup
    .date()
    .required(errors.required),
  [FormFields.CR_AMOUNT]: Yup
    .number()
    .nullable()
    .positive()
    .required(errors.required),
  [FormFields.CR_SUBJECT]: Yup
      .string()
      .nullable(),
  [FormFields.CR_NUMBER]: Yup
      .string()
      .nullable()
})

const initialValues = {
  [FormFields.CR_TYPE]: 1,
  [FormFields.CR_DATE]: getTodayDate(),
  [FormFields.CR_AMOUNT]: null,
  [FormFields.CR_SUBJECT]: '',
  [FormFields.CR_NUMBER]: ''
};

AddNewCashRegisterPopup.propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  bankOptions: PropTypes.arrayOf(types.SelectOption).isRequired,
  areBankOptionsLoading: PropTypes.bool,
  transactions: PropTypes.arrayOf(PropTypes.object),
  areTransactionsLoading: PropTypes.bool,
  pagination: PropTypes.object,
  isConnectButtonLoading: PropTypes.bool,
  submitError: types.Error.propType,
  onConnectButtonClick: PropTypes.func.isRequired,
  onCloseButtonClick: PropTypes.func.isRequired,
  onGetTransactions: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

function AddNewCashRegisterPopup({
                                   className,
                                   isVisible,
                                   bankOptions,
                                   areBankOptionsLoading,
                                   transactions,
                                   areTransactionsLoading,
                                   pagination,
                                   isConnectButtonLoading,
                                   submitError,
                                   onConnectButtonClick,
                                   onCloseButtonClick,
                                   onGetTransactions,
                                   onPageChange,
                                   ...props}) {

  const {t} = useTranslation();

  const [isTableShown, setIsTableShown] = useState(false);

  const [onGetTransactionsHandler] = useSubmitWithFrequencyLimit(onGetTransactions, 1000);

  const onGetTransactionsRef = useRef(onGetTransactionsHandler);

  return (
    <Popup
      {...props}
      className={[
        'add-new-cash-register-popup',
        className,
      ].join(' ')}
      isVisible={isVisible}
      onBackgroundClick={onCloseButtonClick}
    >
      <Popup.Container
        className={[
          'add-new-cash-register-popup__container',
          isTableShown ? 'add-new-cash-register-popup__container_with_max-height' : null,
        ].join(' ')}>
        <Popup.Header
          title={t('Add new cash register')}
          onCloseButtonClick={onCloseButtonClick}
        />
        <Formik
          initialValues={initialValues}
          validationSchema={addCashRegisterSchema}
          onSubmit={onConnectButtonClick}
        >
          {({handleSubmit, values, setFieldValue, setFieldTouched, setFieldError}) => {
            const [date, setDate] = useState(new Date())
            useFormError(submitError, setFieldError);

            return (
              <Form className={'add-new-cash-register-popup__form'}>
                <Popup.Content
                  className={'add-new-cash-register-popup__content'}
                  isLoading={isConnectButtonLoading}
                >
                  <SelectField
                    className={'add-new-cash-register-popup__field'}
                    name={FormFields.CR_TYPE}
                    label={t('Receipt Type')}
                    options={[
                      { value: 1, label: t('Income', 'booking') },
                      { value: 2, label: t('Outgoing', 'booking') },
                    ]}
                  />
                    <p className={'select__label'}>{t('Date')}</p>
                  <Datepicker
                    className={'add-new-cash-register-popup__field'}
                    name={FormFields.CR_DATE}
                    onChange={(e) => setFieldValue(FormFields.CR_DATE, e.target.value)}
                    value={values[FormFields.CR_DATE]}
                  />
                  <InputField
                      className={'add-new-cash-register-popup__field'}
                      name={FormFields.CR_AMOUNT}
                      label={t('Amount')}
                    />
                    <InputField
                        className={'add-new-cash-register-popup__field'}
                        name={FormFields.CR_SUBJECT}
                        label={t('Subject')}
                    />
                    <InputField
                        className={'add-new-cash-register-popup__field'}
                        name={FormFields.CR_NUMBER}
                        label={t('Booking Number')}
                    />
                </Popup.Content>
                <Popup.Footer className={'add-new-cash-register-popup__footer'}>
                  <Button
                    className={'add-new-cash-register-popup__footer-button'}
                    inputType={'submit'}
                    onClick={handleSubmit}
                  >
                    {t('Connect')}
                  </Button>
                </Popup.Footer>
              </Form>
            );
          }}
        </Formik>
      </Popup.Container>
    </Popup>
  );
}

function getTodayDate() {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
}

function onConnectButtonClick() {
    console.log(arguments);
}
export default AddNewCashRegisterPopup;