import './ConnectInvoicePopup.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

import {
  Button,
  Popup,
} from "@components/index";

SmallConnectInvoicePopup.propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  openAmount: PropTypes.string.isRequired,
  isPrivateButtonLoading: PropTypes.bool,
  isCashRegisterButtonLoading: PropTypes.bool,
  onShowAllButtonClick: PropTypes.func.isRequired,
  onCancelButtonClick: PropTypes.func.isRequired,
  onCloseButtonClick: PropTypes.func.isRequired,
  onCashRegisterButtonClick: PropTypes.func.isRequired,
  onPrivateButtonClick: PropTypes.func.isRequired,
};

SmallConnectInvoicePopup.defaultProps = {
  openAmount: '',
};

function SmallConnectInvoicePopup({
                                    className,
                                    isVisible,
                                    openAmount,
                                    isPrivateButtonLoading,
                                    isCashRegisterButtonLoading,
                                    onShowAllButtonClick,
                                    onCancelButtonClick,
                                    onCloseButtonClick,
                                    onCashRegisterButtonClick,
                                    onPrivateButtonClick,
                                    ...props}) {

  const {t} = useTranslation();

  const isMajorActionLoading = isPrivateButtonLoading || isCashRegisterButtonLoading;

  return (
    <Popup
      {...props}
      className={className}
      isVisible={isVisible}
      onBackgroundClick={onCloseButtonClick}
    >
      <Popup.Container className={'small-connect-invoice-popup__container'}>
        <Popup.Header title={t('Connect Invoice')} onCloseButtonClick={onCloseButtonClick}/>
        <Popup.Content
          isLoading={isPrivateButtonLoading || isCashRegisterButtonLoading}
        >
          <p className={'small-connect-invoice-popup__message'}>
            <span>{t('New open Amount') + ':' + ' '}</span>
            <span className={'small-connect-invoice-popup__open-amount'}>{openAmount + '.'}</span>
            <span>{' ' + t('Would you like to link further invoices?')}</span>
          </p>
        </Popup.Content>
        <Popup.Footer className={'small-connect-invoice-popup__footer'}>
          <Button
            className={'small-connect-invoice-popup__footer-button'}
            disabled={isMajorActionLoading}
            isLoading={isCashRegisterButtonLoading}
            onClick={onCashRegisterButtonClick}
          >
            {t('Cash Register')}
          </Button>
          <Button
            className={'small-connect-invoice-popup__footer-button'}
            disabled={isMajorActionLoading}
            isLoading={isPrivateButtonLoading}
            onClick={onPrivateButtonClick}
          >
            {t('Private')}
          </Button>
          <Button
            className={'small-connect-invoice-popup__footer-button'}
            disabled={isMajorActionLoading}
            onClick={onShowAllButtonClick}
          >
            {t('Show All')}
          </Button>
          <Button
            className={'small-connect-invoice-popup__footer-button'}
            type={Button.type.PRIMARY_OUTLINE}
            onClick={onCancelButtonClick}
          >
            {t('Cancel')}
          </Button>
        </Popup.Footer>
      </Popup.Container>
    </Popup>
  );
}

export default SmallConnectInvoicePopup;