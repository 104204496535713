import React from 'react';
import {nanoid} from "nanoid";
import {useTranslation} from "react-i18next";

import mappers from '@mappers/index';

import {
  useState,
  useEffect,
} from 'react';

import {
  UpdateInvoicePaymentPopup,
  DefaultPaymentPopup,
} from "@components/index";

function UpdatePaymentPopup({initialPaymentModel, isSubmitting, isVisible, onSubmit, onHide}) {

  const {t} = useTranslation();

  const [info, setInfo] = useState({});

  useEffect(
    () => handleOnShowPopup(initialPaymentModel, setInfo),
    [],
  );

  if (!info.key) return null;

  return (
    info.isOuterInvoice
      ? (
        <UpdateInvoicePaymentPopup
          key={info.key}
          isVisible={isVisible}
          initialValues={info.initialValues}
          isFormSubmitting={isSubmitting}
          userOptions={[info.initialUserOption]}
          descriptionOptions={[info.initialDescriptionOption]}
          onCloseButtonClick={onHide}
          onFormSubmit={onSubmit}
        />
      )
      : (
        <DefaultPaymentPopup
          key={info.key}
          title={t('Update Payment')}
          isVisible={isVisible}
          initialValues={info.initialValues}
          initialReceiverOption={info.initialReceiverOption}
          initialUserOption={info.initialUserOption}
          initialDescriptionOption={info.initialDescriptionOption}
          initialTypeTransferOption={info.initialTypeTransferOption}
          onSubmit={onSubmit}
          onHide={onHide}
          isFormSubmitting={isSubmitting}
        />
      )
  );
}

function handleOnShowPopup(payment, setInfo) {
  const info = {
    key: nanoid(),
    initialValues: null,
    initialUserOption: null,
    initialDescriptionOption: null,
  };

  if (payment) {
    info.isOuterInvoice = payment.isOuterInvoice,
    info.initialValues = {
      receiver: payment.receiver.type.id,
      name: payment.receiver.id,
      typeTransfer: payment.transfer.id,
      amount: payment.amount,
      usage: payment.usage,
      description: payment.creation_type.id,
      checkedInvoiceId: payment.invoice.id,
    }
    info.initialReceiverOption = mappers.SelectOptionView.parse(payment.receiver.type);
    info.initialUserOption = mappers.SelectOptionView.parse(payment.receiver);
    info.initialDescriptionOption = mappers.SelectOptionView.parse(payment.creation_type);
    info.initialTypeTransferOption = mappers.SelectOptionView.parse(payment.transfer);
  }

  setInfo(info);
}

export default UpdatePaymentPopup;