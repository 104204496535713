import types from '@types/index';
import utils from '@utils/index';
import {authApi} from '@api/authApi';
import {createIsSliceMatcher} from '@slices/matchers/index';

import tags from './tags';
import config from './config';
import addLanguageHeader from "./addLanguageHeader";

import {
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/dist/query/react";

import {
  selectAccessToken,
  selectRefreshToken,
  selectIsAccessTokenExists,
  selectIsAccessTokenActive,
  selectIsAccessTokenRefreshing,
} from '@redux/selectors';

const baseFetch = fetchBaseQuery({
  baseUrl: config.baseUrl,
  prepareHeaders: (headers, api) => {

    const
      state = api.getState(),
      accessToken = selectAccessToken(state);

    headers.set('Authorization', accessToken);
    addLanguageHeader(headers, api);

    return  headers;
  },
});

const rootApi = createApi({

  baseQuery: async (query, api, extra) => {
    const
      state = api.getState(),
      isAccessTokenExists = selectIsAccessTokenExists(state),
      isAccessTokenActive = selectIsAccessTokenActive(state),
      isAccessTokenRefreshing = selectIsAccessTokenRefreshing(state),
      refreshToken = selectRefreshToken(state);

    if (!isAccessTokenExists) {
      return {
        error: types.Error.create(types.Error.type[types.ErrorType.type.NO_ACCESS_TOKEN]),
      };
    }

    if (!isAccessTokenActive) {
      if (isAccessTokenRefreshing) {
        await utils.waitTill(100, () => {
          const newState = api.getState();
          const isRefreshing = selectIsAccessTokenRefreshing(newState);
          return !isRefreshing;
        });
      } else {
        await api.dispatch(authApi.endpoints.refreshToken.initiate(refreshToken));
      }

      const
        newState = api.getState(),
        isActive = selectIsAccessTokenActive(newState);

      if (!isActive) {
        return {
          error: types.Error.create(types.Error.type[types.ErrorType.type.REFRESH_TOKEN_FAILED]),
        };
      }
    }

    let result;

    try {
      result = await baseFetch(query, api, extra);
    } catch (error) {
      result = {
        error: error,
      };
    }

    return result;
  },

  keepUnusedDataFor: 0,

  refetchOnReconnect: true,

  tagTypes: Object.values(tags),

  endpoints: () => ({}),
});

export const api = rootApi;

export const matchIsApiAction = createIsSliceMatcher(api);