import './CreatePaymentPopup.scss';

import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {useTranslation} from "react-i18next";

import types from '@types/index';
import errors from '@yup/errors';
import UpdateInvoicePaymentForm from "./UpdateInvoicePaymentForm";

import {
  useCallback,
} from 'react';

import {
  Popup,
  Button,
} from '@components/index';

const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().required(errors.required),
  description: Yup.string().nullable().required(errors.required),
  usage: Yup.string().nullable().required(errors.required),
  amount: Yup.number().typeError(errors.shouldBeANumber).required(errors.required),
});

UpdateInvoicePaymentPopup.propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  userOptions: PropTypes.arrayOf(types.SelectOption),
  descriptionOptions: PropTypes.arrayOf(types.SelectOption),
  onFormSubmit: PropTypes.func.isRequired,
  onCloseButtonClick: PropTypes.func.isRequired,
};

UpdateInvoicePaymentPopup.defaultProps = {
  onFormSubmit: new Function(),
  onCloseButtonClick: new Function(),
};

function UpdateInvoicePaymentPopup({
                                     className,
                                     isVisible,
                                     initialValues,
                                     userOptions,
                                     descriptionOptions,
                                     isFormSubmitting,
                                     onFormSubmit,
                                     onCloseButtonClick,
                                     ...props
                                   }) {

  const {t} = useTranslation();

  const onFormSubmitHandler = useCallback(
    (fields) => handleOnFormSubmit(fields, onFormSubmit),
    [onFormSubmit],
  );

  return (
    <Popup
      {...props}
      className={[
        'create-payment-popup',
        className,
      ].join(' ')}
      isVisible={isVisible}
      onBackgroundClick={onCloseButtonClick}
    >
      <Popup.Container className={'create-payment-popup__container'}>
        <Formik
          initialValues={initialValues ? initialValues : {
            name: '',
            amount: '',
            usage: '',
            description: '',
          }}
          validationSchema={validationSchema}
          onSubmit={onFormSubmitHandler}
        >
          { function ({submitForm}) {

            return (
              <>
                <Popup.Header
                  className={'create-payment-popup__header'}
                  title={t('Update Payment')}
                  onCloseButtonClick={onCloseButtonClick}
                />
                <Popup.Content
                  className={'create-payment-popup__content'}
                  isLoading={isFormSubmitting}
                >
                  <UpdateInvoicePaymentForm
                    userOptions={userOptions}
                    descriptionOptions={descriptionOptions}
                  />
                </Popup.Content>
                <Popup.Footer>
                  <Button
                    onClick={submitForm}
                    isLoading={isFormSubmitting}
                  >
                    {t('Save')}
                  </Button>
                </Popup.Footer>
              </>
            );
          }}
        </Formik>
      </Popup.Container>
    </Popup>
  );
}

function handleOnFormSubmit(fields, onFormSubmit) {
  onFormSubmit({
    usage: fields.usage,
  });
}

export default UpdateInvoicePaymentPopup;