import './Loader.scss';

import React from 'react';
import PropTypes from 'prop-types';

import types from '@types/index';

import {
  Ring,
} from 'react-css-spinners';

Loader.size = types.LoaderSizeType.size;

const sizeClassNames = {
  [Loader.size.LARGE]: 'loader_size_large',
  [Loader.size.MEDIUM]: 'loader_size_medium',
  [Loader.size.SMALL]: 'loader_size_small',
};

Loader.propTypes = {
  className: PropTypes.string,
  size: types.LoaderSizeType.propType,
};

Loader.defaultProps = {
  size: Loader.size.MEDIUM,
};

function Loader({className, size, ...props}) {

  const sizeClassName = sizeClassNames[size];

  return (
    <div
      {...props}
      className={[
        'loader',
        sizeClassName,
        className,
      ].join(' ')}
    >
      <div className={'loader__content'}>
        <div className={'loader__outer-spinner-container'}>
          <Ring className={'loader__outer-spinner'}  color={'#C0B3FF'} />
        </div>
        <div className={'loader__inner-spinner-container'}>
          <Ring className={'loader__inner-spinner'} color={'#8266FF'}/>
        </div>
      </div>
    </div>
  );
}

export default Loader;