import React from "react";
import PropTypes from "prop-types";

import Link from "@components/NavBar/Link/Link";

import "@components/NavBar/Link/Link.scss"
import "./DropDown.scss";
import svgs from "@assets/svgs";

const propTypes = {
  className: PropTypes.string,
  closedItemsContainerStyle: PropTypes.object,
  name: PropTypes.string.isRequired,
  counter: PropTypes.number,
  links: PropTypes.arrayOf(PropTypes.shape(Link.propTypes)).isRequired,
  isOpen: PropTypes.bool,
  isDark: PropTypes.bool,
  activeLinkIndex: PropTypes.number,
  SvgIcon: PropTypes.object.isRequired,
  onHeaderClick: PropTypes.func,
  headerRef: PropTypes.object,
  itemsRef: PropTypes.object,
};

function DropDown({className, closedItemsContainerStyle, name, counter, links, isOpen, isDark, nestedIndex, SvgIcon,
                    onHeaderClick, headerRef, itemsContainerRef}, ref) {

  return (
    <div
      className={[
        'drop-down',
        isOpen ? 'drop-down_open' : '',
        isDark ? 'drop-down_dark' : '',
        className,
      ].join(' ')}
      ref={ref}
    >
      <div
        className={[
          'drop-down__header',
          'link',
          isDark ? 'link_dark' : '',
          'link_active',
        ].join(' ')}
        onClick={onHeaderClick}
        ref={headerRef}
      >
        <div className={'drop-down__name'}>
          <SvgIcon className={"link__icon"}/>
          <p className={"link__text"}>{name}</p>
        </div>
        <div className={'drop-down__indicators'}>
          {
            counter
              ? (
                <div className={'link__counter'}>
                  <p className={'link__counter-number'}>{counter}</p>
                </div>
              )
              : null
          }
          <svgs.ArrowDown className={"drop-down__arrow"}/>
        </div>
      </div>
      <div className="drop-down__items-container">
        <div
          className="drop-down__items"
          style={isOpen ? null : closedItemsContainerStyle}
          ref={itemsContainerRef}
        >
          {
            links.map((r, key) => (
              <Link
                className={"drop-down__nested-link"}
                name={r.name}
                to={r.to}
                isDark={isDark}
                isActive={nestedIndex === key}
                key={key}
                SvgIcon={r.SvgIcon}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
}

const DropDownWithRef = React.forwardRef(DropDown);

DropDownWithRef.propTypes = propTypes;

export default DropDownWithRef;