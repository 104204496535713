import amount from './amount';
import invoiceNumber from './invoiceNumber';
import receiver from './receiver';
import text from './text';
import email from './email';
import password from './password';

export default {
  amount,
  invoiceNumber,
  receiver,
  text,
  email,
  password,
};