import {createSlice} from "@reduxjs/toolkit";
import {userApi} from '@api/user';

const initialState = {
  id: -1,
  name: '',
  permissions: [],
};

const user = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addMatcher(
      userApi.endpoints.getUser.matchFulfilled,
      function (state, action) {
        state.id = action.payload.id;
        state.name = action.payload.name,
        state.permissions = action.payload.permissions;
      },
    );

  },
});

export default user.reducer;

export const selectId = (state) => state.user.id;

export const selectPermissions = (state) => state.user.permissions;

export const selectName = (state) => state.user.name;