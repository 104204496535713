import './TabledList.scss';

import React from 'react';
import PropTypes from 'prop-types';

import types from '@types/index';
import TabledObject from "./TabledObject";

import {
  Loader,
} from '@components/index';

TabledList.propTypes = {
  className: PropTypes.string,
  tabledObjects: PropTypes.arrayOf(types.TabledObject.propType),
  columnsFactory: PropTypes.func.isRequired,
};

TabledList.defaultProps = {
  tabledObjects: [],
};

function TabledList({className, tabledObjects, isLoading, columnsFactory, ...props}) {
  return (
    <ul
      {...props}
      className={[
        'tabled-list',
        className,
      ].join(' ')}
    >
      {
        isLoading
          ? (
            <div className={'tabled-list__loader'}>
              <Loader/>
            </div>
          )
          : (
            tabledObjects.map((object) => (
              <TabledObject
                className={'tabled-list__object'}
                key={object.row.id}
                tabledObject={object}
                columnsFactory={columnsFactory}
              />
          )
        ))
      }
    </ul>
  );
}

export default TabledList;
