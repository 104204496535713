import mappers from '@mappers/index';
import store from '@redux/store';
import fulfillQueryFn from "@utils/fulfillQueryFn";
import rejectQueryFn from "@utils/rejectQueryFn";
import {schedule} from "@slices/redirection";

const fakeMapper = (data) => data;

export default function handleQueryFnResponse(response, mapper = fakeMapper, errorTitle) {
  if (response.error) {
    return rejectQueryFn(mappers.mapError(response.error), errorTitle);
  } else {
    const redirection = mappers.mapRedirection(response.data);

    if (redirection) {
      store.dispatch(schedule(redirection));
    }

    return fulfillQueryFn(mapper(response.data));
  }
}