import './OutputField.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

OutputField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  Icon: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
  ]),
};

function OutputField({className, label, value, Icon, ...props}) {
  const {t} = useTranslation();

  return (
    <div
      {...props}
      className={[
        'output-field',
        className,
      ].join(' ')}
    >
      {
        label
          ? <p className={'output-field__label'}>{t(label)}</p>
          : null
      }
      <div className={'output-field__value-container'}>
        <p className={'output-field__value'}>{value}</p>
        {
          Icon
            ? (
              <div className={'output-field__icon-container'}>
                <Icon className={'output-field__icon'}/>
              </div>
            )
            : null
        }
      </div>
    </div>
  );
}

export default OutputField;