import React from 'react';

import TabledList from "./TabledList";

import {
  useEffect,
} from 'react';

import {
  useLazyGetInvoiceSourcesQuery
} from '@api/invoice';

function MonitoringTabledListContainer(props) {
  const [
    trigger,
    result,
  ] = useLazyGetInvoiceSourcesQuery();

  const {
    data: objects,
    isFetching: isFetching,
  } = result || {};

  useEffect(
    () => trigger(),
    [],
  );

  return (
    <TabledList
      {...props}
      tabledObjects={objects}
      isLoading={isFetching}
    />
  );
}

export default MonitoringTabledListContainer;