import './TabledObject.scss';

import React from "react";
import PropTypes from 'prop-types';

import utils from '@utils/index';
import types from '@types/index';

import {
  useMemo,
} from 'react';

import {
  useTableObject,
} from '@hooks/index';

import {
  AutoGeneratedTable,
} from '@components/index';

TabledObject.propTypes = {
  className: PropTypes.string,
  tabledObject: types.TabledObject.propType.isRequired,
  columnsFactory: PropTypes.func.isRequired,
};

function TabledObject({className, tabledObject, columnsFactory, ...props}) {

  const {
    title,
    row
  } = tabledObject;

  const rows = useMemo(
    () => row ? [row] : [],
    [row],
  );

  const table = useTableObject(columnsFactory, rows);

  return (
    <li
      {...props}
      className={[
        'tabled-object',
        className,
      ].join(' ')}
    >
      <div className={'tabled-object__title-container'}>
        <p className={'tabled-object__title'}>{title}</p>
      </div>
      <AutoGeneratedTable
        className={'tabled-object__table'}
        table={table}
        isLoading={false}
        onSubmit={utils.doNothingHandler}
      />
    </li>
  );
}

export default TabledObject;