import i18next from "i18next";

import types from "@types/index";
import validators from '@yup/index'

import {
  createColumn,
} from "@utils/dataCreators";

import {
  Table,
} from "@components/index";

function createDefaultColumns() {
  return {
    [types.DefaultTransactionColumnType.type.INDEX]: createColumn(
      types.DefaultTransactionColumnType.type.INDEX,
      '№',
      'transactions__index-cell'
    ),

    [types.DefaultTransactionColumnType.type.INVOICE]: createColumn(
      types.DefaultTransactionColumnType.type.INVOICE,
      i18next.t('Invoice'),
      'transactions__invoice-cell'
    ),

    [types.DefaultTransactionColumnType.type.BOOKING]: createColumn(
      types.DefaultTransactionColumnType.type.BOOKING,
      i18next.t('Booking status'),
      'transactions__booking-cell',
      {type: Table.Cell.type.SELECT_FIELD},
    ),

    [types.DefaultTransactionColumnType.type.NAME]: createColumn(
      types.DefaultTransactionColumnType.type.NAME,
      i18next.t('Name'),
      'transactions__name-cell',
      {type: Table.Cell.type.INPUT_FIELD, validator: validators.text},
    ),

    [types.DefaultTransactionColumnType.type.USAGE]: createColumn(
      types.DefaultTransactionColumnType.type.USAGE,
      i18next.t('Usage'),
      'transactions__usage-cell',
      {type: Table.Cell.type.INPUT_FIELD, validator: validators.text},
    ),

    [types.DefaultTransactionColumnType.type.DATE]: createColumn(
      types.DefaultTransactionColumnType.type.DATE,
      i18next.t('Date'),
      'transactions__date-cell',
      {type: Table.Cell.type.DATE_RANGE_PICKER_FIELD},
    ),

    [types.DefaultTransactionColumnType.type.PRICE]: createColumn(
      types.DefaultTransactionColumnType.type.PRICE,
      i18next.t('Amount'),
      'transactions__price-cell',
      {type: Table.Cell.type.INPUT_FIELD, validator: validators.amount},
    ),

    [types.DefaultTransactionColumnType.type.LEFT_PRICE]: createColumn(
      types.DefaultTransactionColumnType.type.LEFT_PRICE,
      i18next.t('Left amount'),
      'transactions__left-price-cell',
    ),
  };
}

export default createDefaultColumns;