import './Checkbox.scss';

import React from 'react';
import PropTypes from 'prop-types';

Checkbox.propTypes = {
  className: PropTypes.string,
  checkboxProps: PropTypes.instanceOf(Object).isRequired,
};

function Checkbox({className, checkboxProps, ...props}) {

  const {
    className: checkboxClassName,
    value,
  } = checkboxProps;

  return (
    <span
      {...props}
      className={[
        'checkbox-container',
        className,
      ].join(' ')}
    >
       <input
         {...checkboxProps}
         className={[
           'checkbox-container__checkbox',
           checkboxClassName,
         ].join(' ')}
         type="checkbox"
         checked={value}
       />
      <span className={'checkbox-container__checkmark'}/>
    </span>
  );
}

export default Checkbox;