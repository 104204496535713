class SelectOptionModel {
  static default = {
    id: null,
    name: '',
  }

  static parse(response) {
    if (!response) return SelectOptionModel.default;
    return {
      id: response.id ? String(response.id) : response.id,
      name: response.name,
    };
  }
}

export default SelectOptionModel;