import './Button.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

import svgs from "@assets/svgs";
import types from '@types/index';

Button.type = types.ButtonType.type;

const classes = {
  [Button.type.PRIMARY]: 'button_type_primary',
  [Button.type.DANGEROUS]: 'button_type_dangerous',
  [Button.type.OUTLINE]: 'button_type_outline',
  [Button.type.PRIMARY_OUTLINE]: 'button_type_primary-outline',
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string.isRequired,
  type: types.ButtonType.propType,
  disabled: PropTypes.bool,
  inputType: PropTypes.string,
  isLoading: PropTypes.bool,
  Icon: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  onClick: PropTypes.func.isRequired,
};

Button.defaultProps = {
  Icon: () => null,
  type: Button.type.PRIMARY,
};

function Button({className, children, type, disabled, inputType, isLoading, Icon, onClick, ...props}) {

  const {t} = useTranslation();

  const isLoadingIconAvailable = type === Button.type.PRIMARY || type === Button.type.DANGEROUS;

  const isLoaderVisible = isLoadingIconAvailable && isLoading;

  const isDisabled = disabled || isLoading;

  const Loader = svgs.Loading;

  return (
    <button
      {...props}
      className={[
        'button',
        isLoaderVisible ? 'button_loading' : null,
        classes[type],
        className
      ].join(' ')}
      type={inputType}
      disabled={isDisabled}
      onClick={onClick}
    >
      {t(children)}
      {
        isLoaderVisible
          ? <Loader className={'button__loading-icon'}/>
          : <Icon className={'button__icon'}/>
      }
    </button>
  );
}

export default Button;