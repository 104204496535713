import {useTranslation} from "react-i18next";

import {
  useMemo,
} from 'react';

function useTableObject(columnsFactory, rows) {
  if (typeof columnsFactory !== 'function') {
    throw new Error('columnsFactory should be function');
  }

  const {t} = useTranslation();

  const columns = useMemo(
    () => columnsFactory(),
    [t, columnsFactory]
  );

  const table = useMemo(
    () => createTable(columns, rows),
    [columns, rows],
  );

  return table;
}

function createTable(columns, rows) {
  if (!columns) {
    throw new Error('Columns object is required')
  }

  return {
    columns: columns,
    rows: rows || [],
  };
}

export default useTableObject;