import PropTypes from 'prop-types';

const options = {
  CHECKBOX: 'CHECKBOX',
  RECEIVER: 'RECEIVER',
  INVOICE_NUMBER: 'INVOICE_NUMBER',
  AMOUNT: 'AMOUNT',
};

const AvailableInvoiceColumnType = {
  propType: PropTypes.oneOf(Object.values(options)),
  type: options,
};

export default AvailableInvoiceColumnType;

