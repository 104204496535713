import './AuthForm.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {
  useHistory,
} from "react-router-dom";
import {
  useCallback,
} from 'react';

Redirect.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
};

function Redirect({className, to, ...props}) {

  const history = useHistory();

  const onButtonClick = useCallback(
    () => history.push(to),
    [history, to],
  );

  return (
    <a
      {...props}
      className={[
        'auth-form__redirect',
        className,
      ].join(' ')}
      onClick={onButtonClick}
    />
  );
}

export default Redirect;