import React from 'react';
import PropTypes from 'prop-types';

import * as pdfJs from 'pdfjs-dist/build/pdf.min';
import * as pdfJsWorkder from 'pdfjs-dist/build/pdf.worker.entry';
pdfJs.GlobalWorkerOptions.workerSrc = pdfJsWorkder;

import {
  useGetPdfQuery,
} from '@api/transactions';

import {
  useEffect,
  useState,
} from 'react';

import {
  PDFViewer,
} from '@components/index';

PDFViewerPDFJSContainer.propTypes = {
  pdfPath: PropTypes.string.isRequired,
}

function PDFViewerPDFJSContainer({pdfPath, ...props}) {

  const [pages, setPages] = useState();

  const getPdfParams = {
    skip: !pdfPath,
  };

  console.log('PDFViewerPDFJSContainer', pdfPath)


  const {
    data: pdf,
    isFetching: isPdfFetching,
  } = useGetPdfQuery(pdfPath, getPdfParams);

  useEffect(
    () => getPages(pdf, setPages),
    [pdf],
  );

  return (
      <PDFViewer
        {...props}
        pages={pages}
        isLoading={isPdfFetching}
        key={pages}
      />
  );
}

async function getPages(arrayBuffer, setPages) {
  if (!arrayBuffer) return;

  try {
    const pdf = await pdfJs.getDocument(arrayBuffer).promise;

    const pagesPromises = [];

    for (let i = 1; i <= pdf.numPages; i++) {
      const currentPagePromise = pdf.getPage(i);
      pagesPromises.push(currentPagePromise);
    }

    const pages = await Promise.all(pagesPromises)

    setPages(pages);
  } catch (error) {
    console.log('Parse array buffer into pdf failed', error);
  }
}

export default PDFViewerPDFJSContainer;