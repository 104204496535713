import types from '@types/index';

export default function mapErrorType(serverError) {
  if (!serverError.data || !serverError.data.status || serverError.data.code < 0) return 'not specified';

  const status = serverError.data.status;
  const code = serverError.data.code;

  const error = types.Error.values.find(error => error.status === status && error.code === code)

  return error ? error.type : 'unknown';
}