import {
  isAllOf,
  isRejected,
} from "@reduxjs/toolkit";

function isServerUserFriendlyErrorMatcher(action) {
  if (!action || !action.payload) return;

  const error = action.payload;
  return !!(error.title || error.message);
}

const isRejectedWithUserFriendlyError = isAllOf(
  isRejected,
  isServerUserFriendlyErrorMatcher,
);

export default isRejectedWithUserFriendlyError;