import './InvoiceCard.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

import {
  Button,
} from '@components/index';

InvoiceCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  onOpenButtonClick: PropTypes.func,
};

InvoiceCard.defaultProps = {
  onOpenButtonClick: new Function(),
};

function InvoiceCard({className, title, price, onOpenButtonClick, ...props}) {

  const {t} = useTranslation();

  return (
    <article
      {...props}
      className={[
        'invoice-card',
        className,
      ].join(' ')}
    >
      <h3 className={'invoice-card__title'}>{title}</h3>
      <p className={'invoice-card__price'}>{price}</p>
      <div className={'invoice-card__button-container'}>
        <Button
          type={Button.type.PRIMARY}
          onClick={onOpenButtonClick}
        >
          {t('Open')}
        </Button>
      </div>
    </article>
  );
}

export default InvoiceCard;