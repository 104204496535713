import './BlockMessage.scss';

import React from 'react';
import PropTypes from 'prop-types';

Paragraph.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};

function Paragraph({className, children, ...props}) {
  return (
    <span
      {...props}
      className={className}
    >
      {children}
    </span>
  );
}

export default Paragraph;