import React from 'react';
import {nanoid} from "nanoid";

import UpdatePaymentPopup from './UpdatePaymentPopup';

import {
  useState,
  useCallback,
  useEffect,
} from 'react';

import {
  useUpdatePaymentMutation,
} from '@api/payments';

function WithUpdatePaymentPopup(Component){
  function NewComponent(props) {

    const [info, setInfo] = useState({});

    const [
      triggerUpdatePayment,
      {
        isSuccess: isUpdatePaymentSuccess,
        isLoading: isUpdatePaymentLoading,
      },
    ] = useUpdatePaymentMutation();

    const onShowHandler = useCallback(
      (id, model) => handleOnShowPopup(model, setInfo),
      [],
    );

    const onHideHandler = useCallback(
      () => handleOnHide(setInfo),
      [],
    );

    const onSaveButtonClickHandler = useCallback(
      (fields) => handleOnSaveButtonClick(fields, info, triggerUpdatePayment),
      [info],
    );

    useEffect(
      () => hideOnCreationFinish(isUpdatePaymentSuccess, isUpdatePaymentLoading, onHideHandler),
      [isUpdatePaymentSuccess, isUpdatePaymentLoading]
    );

    return (
      <>
        <Component
          {...props}
          onShowUpdatePaymentPopup={onShowHandler}
        />
        {
          info.key
            ? (
              <UpdatePaymentPopup
                key={info.key}
                isVisible={!!info.isVisible}
                initialPaymentModel={info.initialPaymentModel}
                isSubmitting={isUpdatePaymentLoading}
                onHide={onHideHandler}
                onSubmit={onSaveButtonClickHandler}
              />
            )
            : null
        }
      </>
    );
  }

  return NewComponent;
}

function handleOnSaveButtonClick(fields, info, triggerUpdatePayment) {
  triggerUpdatePayment({
    id: info.id,
    ...fields,
  });
}

function handleOnShowPopup(paymentModel, setInfo) {
  setInfo({
    isVisible: true,
    key: nanoid(),
    id: paymentModel.id,
    initialPaymentModel: paymentModel,
  });
}

function handleOnHide(setInfo) {
  setInfo(prevState => ({
    ...prevState,
    isVisible: false,
  }));
}

function hideOnCreationFinish(isUpdatePaymentSuccess, isUpdatePaymentLoading, onHideHandler) {
  if (!isUpdatePaymentLoading && isUpdatePaymentSuccess){
    onHideHandler();
  }
}

export default WithUpdatePaymentPopup;