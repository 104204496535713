import i18next from "i18next";

import mappers from '@mappers/index';
import types from '@types/index';
import utils from '@utils/index';
import tags from './tags';
import {api} from './index';
import {rawCashRegisterCategory} from "@mappers/mapTransactionCategories";

import AddNewCashRegisterFormFields from '@components/AddNewCashRegisterPopup/FormFields';

const resource = 'transaction';

export const transactionsApi = api.injectEndpoints({

  endpoints: (builder) => ({

    getBookingsStatuses: builder.query({
      queryFn: async (transactionId, api1, extraOptions, baseQuery) => {
        const response = await baseQuery(resource + '/get-status');
        return utils.handleQueryFnResponse(response, mappers.mapSelectOptions);
      },
    }),

    getTransactionsCategories: builder.query({
      queryFn: async (transactionId, api1, extraOptions, baseQuery) => {
        const response = await baseQuery(resource + `/get-banks`);
        return utils.handleQueryFnResponse(response, mappers.mapTransactionCategories);
      },
    }),

    getTransactionsBankOptions: builder.query({
      queryFn: async (arg, api1, extraOptions, baseQuery) => {
        const response = await baseQuery(resource + '/get-banks');
        return utils.handleQueryFnResponse(response, mappers.mapTransactionCategoriesIntoSelectOptions);
      }
    }),

    getTransactions: builder.query({
      queryFn: async (args, api1, extraOptions, baseQuery) => {
        const dateRange =
          args[types.DefaultTransactionColumnType.type.DATE] || args[types.CashRegisterTransactionColumnType.type.CREATED_AT] ;

        const dateRangePayment = args[types.CashRegisterTransactionColumnType.type.PAYMENT_DATE];
        const [startDatePayment, endDatePayment] = dateRangePayment || [];

        const [startDate, endDate] = dateRange || [];

        const response = await baseQuery({
          url: resource + `/index`,
          params: {
            'TransactionSearch[bank_id]': args.bankId,
            'TransactionSearch[name]': args[types.DefaultTransactionColumnType.type.NAME],
            'TransactionSearch[description]': args[types.DefaultTransactionColumnType.type.USAGE],
            'TransactionSearch[date_start]': utils.createISODate(startDate),
            'TransactionSearch[date_end]':  utils.createISODate(endDate),
            'TransactionSearch[amount]': args[types.DefaultTransactionColumnType.type.PRICE],
            'TransactionSearch[status]': args[types.DefaultTransactionColumnType.type.BOOKING],

            'TransactionSearch[date_start_payment]': utils.createISODate(startDatePayment),
            'TransactionSearch[date_end_payment]':  utils.createISODate(endDatePayment),
            page: args.page,
          },
        });

        const mapper = rawCashRegisterCategory.bank_id === args.bankId
          ? mappers.mapCashRegisterTransactions
          : mappers.mapDefaultTransactions;

        const paginatedMapper = utils.createPaginatedMapper(mapper, 'transactions');

        const mapperWithBalance = function mapperWithBalance(response) {
          const result = paginatedMapper(response);

          if (response.bank) {
            result.balance = mappers.mapBalance(response.bank);
          }

          return result;
        }

        return utils.handleQueryFnResponse(response, mapperWithBalance);
      },
      providesTags: [tags.TRANSACTIONS],
    }),

    getBeingAddedToCashRegisterTransactions: builder.query({
      queryFn: async (arg, api1, extraOptions, baseQuery) => {
        const dateRange = arg[AddNewCashRegisterFormFields.DATA_RANGE] ;

        const [startDate, endDate] = dateRange || [];

        const response = await baseQuery({
          url: resource + `/index`,
          params: {
            'TransactionSearch[bank_id]': arg[AddNewCashRegisterFormFields.BANK_NAME],
            'TransactionSearch[date_start]': utils.createISODate(startDate),
            'TransactionSearch[date_end]':  utils.createISODate(endDate),
            page: arg.page,
          },
        });

        const paginatedMapper = utils.createPaginatedMapper(
          mappers.mapBeingAddedToCashRegisterTransaction,
          'transactions',
        );

        return utils.handleQueryFnResponse(response, paginatedMapper);
      },
      providesTags: [tags.TRANSACTIONS],
    }),

    getInvoicesConnectionAvailability: builder.query({
      queryFn: async (args, api1, extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: resource + '/check-amount-transaction',
          params: utils.createQueryParams()
            .add('transaction_id', args.transactionId)
            .add('invoice_ids', args.invoiceIds)
            .finish(),
        });

        return utils.handleQueryFnResponse(response, mappers.mapInvoicesConnectionAvailability);
      },
      providesTags: [tags.AVAILABILITY],
    }),

    connectInvoices: builder.mutation({
      queryFn: async (args, api1, extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: resource + '/connect-invoice',
          params: utils.createQueryParams()
            .add('transaction_id', args.transactionId)
            .add('invoice_ids', args.invoiceIds)
            .finish(),
        });

        return utils.handleQueryFnResponse(response);
      },
      // todo: handle code 14
      // status 400, code 14 = invoices amount sum > transactions amount
      invalidatesTags: [tags.TRANSACTIONS, tags.AVAILABILITY, tags.AVAILABLE_INVOICES],
    }),

    importTransactions: builder.mutation({
      queryFn: async (arg, api1, extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: resource + '/import-transactions',
          params: utils.createQueryParams()
            .add('bank_id', arg.bankId)
            .add('url', arg.href)
            .finish(),
        });

        return utils.handleQueryFnResponse(
          response,
          mappers.mapImportTransactionResponse,
          i18next.t('Import failed'),
        );
      },
      invalidatesTags: (result, error) => {
        if (error) return [];
        if (result && result.code === 20) return [];
        return [tags.TRANSACTIONS];
      },
    }),

    // todo: ask about returning status codes
    moveTransactionIntoCashRegister: builder.mutation({
      queryFn: async (transactionId, api1, extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: resource + '/cash-or-private',
          params: utils.createQueryParams()
            .add('transaction_id', transactionId)
            .add('status', 5) // Cash - 5, Private - 6
            .finish(),
        });

        return utils.handleQueryFnResponse(response);
      },
      invalidatesTags: (result, error) => {
        return error ? [] : [tags.TRANSACTIONS];
      },
    }),

    // todo: ask about returning status codes
    makeTransactionPrivate: builder.mutation({
      queryFn: async (transactionId, api1, extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: resource + '/cash-or-private',
          params: utils.createQueryParams()
            .add('transaction_id', transactionId)
            .add('status', 6) // Cash - 5, Private - 6
            .finish(),
        });

        return utils.handleQueryFnResponse(response);
      },
      invalidatesTags: (result, error) => {
        return error ? [] : [tags.TRANSACTIONS];
      },
    }),

    getPdf: builder.query({
      queryFn: async (filePath, api1, extraOptions, baseQuery) => {
        try {
          const response = await baseQuery({
            url: filePath,
            responseHandler: (response) => response.arrayBuffer(),
          });

          return utils.handleQueryFnResponse(response);
        } catch (error) {
          return utils.rejectQueryFn({
            error: error,
          });
        }
      },
    }),

    addNewCashRegister: builder.mutation({
      queryFn: async (arg, api1, extraOptions, baseQuery) => {

        const response = await baseQuery({
          url: resource + '/cash-register',
          method: 'POST',
          body: JSON.stringify(arg),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });

        return utils.handleQueryFnResponse(response);
      },
      invalidatesTags: (result, error) => {
        return error ? [] : [tags.TRANSACTIONS];
      },
    }),

  }),
});

export const {
  useGetBookingsStatusesQuery,
  useGetTransactionsCategoriesQuery,
  useGetTransactionsQuery,
  useLazyGetTransactionsQuery,
  useLazyGetInvoicesConnectionAvailabilityQuery,
  useLazyGetBeingAddedToCashRegisterTransactionsQuery,
  useGetTransactionsBankOptionsQuery,
  useConnectInvoicesMutation,
  useImportTransactionsMutation,
  useMoveTransactionIntoCashRegisterMutation,
  useAddNewCashRegisterMutation,
  useMakeTransactionPrivateMutation,
  useGetPdfQuery,
} = transactionsApi;