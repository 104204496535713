import './ViewInvoicePopup.scss';

import React from 'react';
import PropTypes from 'prop-types';

import svgs from "@assets/svgs";

import {
  Popup,
  OutputField,
  OutputTable,
  TextCheckbox,
  PDFViewerPDFJSContainer,
} from '@components/index';

ViewInvoicePopup.propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  info: PropTypes.instanceOf(Object).isRequired,
  pdfPath: PropTypes.string.isRequired,
  amount: PropTypes.instanceOf(Object).isRequired,
  onBackgroundClick: PropTypes.func.isRequired,
  onCloseButtonClick: PropTypes.func.isRequired,
}

function ViewInvoicePopup({className, isVisible, info, pdfPath, amount,
                            onBackgroundClick, onCloseButtonClick, ...props}) {

  return (
    <Popup {...props} isVisible={isVisible} onBackgroundClick={onBackgroundClick}>
      <Popup.Container>
        <Popup.Header title={'Invoice View'} onCloseButtonClick={onCloseButtonClick}/>
        <Popup.Content className={'view-invoice-popup'}>
          <div className={'view-invoice-popup__layout'}>
            <PDFViewerPDFJSContainer
              pdfPath={pdfPath}
            />
            <div className="view-invoice-popup__info-container">
              <div className={'view-invoice-popup__info'}>

                <TextCheckbox className={'view-invoice-popup__info-item'} label={'Receipt Type'} checkboxProps={{}}/>
                {
                  Object.keys(info).map(key => {
                    return (
                      <OutputField
                        className={'view-invoice-popup__info-item'}
                        label={info[key].label}
                        value={info[key].value}
                        Icon={svgs[info[key].svgIconName]}
                        key={info[key].label}
                      />
                    )
                  })
                }
              </div>
              <div className={'view-invoice-popup__table-container'}>
                <OutputTable className={'view-invoice-popup__table'} rows={Object.values(amount)}/>
              </div>
            </div>
          </div>
        </Popup.Content>
      </Popup.Container>
    </Popup>
  );
}

export default ViewInvoicePopup;