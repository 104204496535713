import store from '@redux/store';

import {
  setCounters,
  setPaymentCounter,
} from '@slices/socket';

import {
  types,
} from './actions';

function handleEvent(event) {
  const message = JSON.parse(event.data);

  switch (message.type) {
    case types.backend.COUNTERS:
      return store.dispatch(setCounters(
        message.payload.salary_counter,
        message.payload.payment_counter
    ));

    case types.backend.UPDATE_PAYMENT_COUNTER:
      return store.dispatch(setPaymentCounter(message.payload.payment_counter));

    default:
      if (process.env.NODE_ENV === 'development') {
        console.warn('Unknown socket message:', message);
      }
  }
}

export default handleEvent;