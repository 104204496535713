import React from 'react';
import PropTypes from 'prop-types';

import {
  useState,
  useEffect,
} from 'react';

import Container from './Container';
import Header from './Header';
import Footer from './Footer';
import Content from './Content';
import Popup from './Popup';

PopupContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onBackgroundClick: PropTypes.func,
};

PopupContainer.defaultProps = {
  onBackgroundClick: new Function(),
};

function PopupContainer({isVisible, onBackgroundClick, ...props}) {
  const [isActive, setIsActive] = useState(false);

  function makePopupActive() {
    if (isVisible) {
      setIsActive(true);
    }
  }

  function handleOnContainerClick(e) {
    if (e.target !== e.currentTarget) return;

    onBackgroundClick(e);
  }

  useEffect(makePopupActive, [isVisible])

  return (
    <Popup
      {...props}
      isVisible={isVisible}
      isActive={isActive}
      onContainerClick={handleOnContainerClick}
    />
  );
}

PopupContainer.Container = Container;
PopupContainer.Header = Header;
PopupContainer.Footer = Footer;
PopupContainer.Content = Content;

export default PopupContainer;