import React from "react";
import PropTypes from "prop-types";

import DropDown from "./DropDown.js"
import Link from "@components/NavBar/Link/Link";

import {
  useEffect,
  useRef,
  useState
} from 'react';

import {
  useHistory,
} from 'react-router-dom';

import {
  useEffectOnDepsMutation,
} from '@hooks/index';

const propTypes = {
  className: PropTypes.string,
  closedItemsContainerStyle: PropTypes.object,
  name: PropTypes.string.isRequired,
  to: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape(Link.propTypes)).isRequired,
  isDark: PropTypes.bool,
  isActive: PropTypes.bool,
  shouldImmediatelyCloseOnce: PropTypes.bool,
  nestedIndex: PropTypes.number,
  SvgIcon: PropTypes.object.isRequired,
  onResize: PropTypes.func,
  onOpen: PropTypes.func,
};

const defaultProps = {
  onResize: new Function(),
  onOpen: new Function(),
};

function DropDownContainer({to, shouldImmediatelyCloseOnce, isActive, nestedIndex, onResize, onOpen, ...props}, ref) {
  const [isOpen, setIsOpen] = useState(false);
  const [closedItemsContainerStyle, setClosedItemsContainerStyle] = useState(getDefaultClosedItemsContainerStyle);
  const headerRef = useRef();
  const itemsContainerRef = useRef();

  const history = useHistory();

  const isRootAvailable = !!to;
  const isRootActive = isRootAvailable && isActive && nestedIndex === -1;

  const isOpenRef = useRef(isOpen);
  isOpenRef.current = isOpen;

  const setIsOpenAndUpdateRef = useRef(setIsOpenAndUpdate);
  setIsOpenAndUpdateRef.current = setIsOpenAndUpdate;

  function headerClickHandler() {
    if (isRootAvailable) {
      if (!isRootActive) {
        history.push(to);
        return;
      }
    }

    setIsOpenAndUpdate(!isOpen)
  }

  function resize(newIsOpenValue) {
    let newHeight = headerRef.current.offsetHeight;

    if (newIsOpenValue){
      newHeight += itemsContainerRef.current.offsetHeight;
    }

    const event = {
      newHeight,
      ref,
    };

    onResize(event);
  }

  function setIsOpenAndUpdate(newIsOpenValue) {
    setIsOpen(newIsOpenValue)
    resize(newIsOpenValue);

    if (newIsOpenValue) {
      onOpen();
    }
  }

  function getCurrentItemsListHeight() {
    return itemsContainerRef.current.offsetHeight;
  }

  function updateClosedItemsListStyle() {
    setClosedItemsContainerStyle({
      marginTop: - getCurrentItemsListHeight() + "px",
    });
  }

  function createFirstClosedItemsListStyle() {
    updateClosedItemsListStyle();
  }

  function itemsListResizeListener() {
    updateClosedItemsListStyle();
  }

  function addItemsListResizeListenerEffect() {
    window.addEventListener('resize' , itemsListResizeListener);
  }

  useEffect(createFirstClosedItemsListStyle, []);
  useEffect(addItemsListResizeListenerEffect, []);

  useEffectOnDepsMutation(
    () => handleImmediatelyClosure(shouldImmediatelyCloseOnce, isOpenRef, setIsOpenAndUpdateRef),
    [shouldImmediatelyCloseOnce],
  );

  return <DropDown
    {...props}
    closedItemsContainerStyle={closedItemsContainerStyle}
    isOpen={isOpen}
    nestedIndex={nestedIndex}
    onHeaderClick={headerClickHandler}
    ref={ref}
    headerRef={headerRef}
    itemsContainerRef={itemsContainerRef}
    />;
}

function getDefaultClosedItemsContainerStyle() {
  return {
    margin: "-999999999px 0 0 0"
  };
}

function handleImmediatelyClosure(shouldImmediatelyCloseOnce, isOpenRef, setIsOpenAndUpdateRef,) {
  if (shouldImmediatelyCloseOnce) {
    if (!isOpenRef.current) return;
    setIsOpenAndUpdateRef.current(false)
  }
}

const DropDownContainerWithRef = React.forwardRef(DropDownContainer);

DropDownContainerWithRef.propTypes = propTypes;
DropDownContainerWithRef.defaultProps = defaultProps;

export default DropDownContainerWithRef;