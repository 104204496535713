import '@components/Input/Input.scss';
import './Select.scss';

import React from 'react';
import ReactSelect, {createFilter} from 'react-select';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

import svgs from '@assets/svgs';
import utils from '@utils/index';

import {
  useMemo,
  useCallback,
  useEffect,
} from 'react';

import {
  components
} from 'react-select';

Select.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  isLoading: PropTypes.bool,
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Select.defaultProps = {
  options: [],
  onGetOptions: new Function(),
  isSearchable: false,
};

export default function Select({
                                 className,
                                 options,
                                 name,
                                 value,
                                 label,
                                 isLoading,
                                 isTouched,
                                 isSearchable,
                                 placeholder,
                                 error,
                                 onChange,
                                 onBlur,
                                 onGetOptions,
                                 ...props}) {

  const {t} = useTranslation();

  const currentOption = useMemo(
    () => getCurrentOption(options, value),
    [options, value],
  );

  const filterOption = useMemo(
    () => createFilterOption(),
    [],
  );

  const onChangeHandler = useCallback(
    (...args) => handleOnChange(...args, onChange),
    [onChange],
  );

  const onBlurHandler = useCallback(
    () => handleOnBlur(name, onBlur),
    [name, onBlur],
  );

  useEffect(
    () => getOptions(options, value, isLoading, onGetOptions),
    [],
  );

  const isErrorVisible = error && isTouched;

  return (
    <div className={className}>
      {
        label
          ? <p className={'select__label'}>{label}</p>
          : null
      }
      <ReactSelect
        {...props}
        name={name}
        value={currentOption}
        className={[
          'react-select',
          isErrorVisible ? 'react-select_with-error' : null,
        ].join(' ')}
        classNamePrefix={'react-select'}
        options={!isLoading ? options : []}
        placeholder={placeholder || t('Select')}
        error={error}
        isLoading={isLoading}
        isSearchable={isSearchable}
        filterOption={filterOption}
        isClearable={true}
        components={{DropdownIndicator, ClearIndicator}}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        onMenuClose={onBlurHandler}
      />
      {
        isErrorVisible
          ? (
            <div className={'select__error-container'}>
              <p className={'select__error'}>{t(error)}</p>
            </div>
          )
          : null
      }
    </div>
  );
}

function getCurrentOption(options, value) {
  const option = options.find(option => option.value === value);
  return option || {value: value};
}

function handleOnChange (newValue, action, onChange){

  const value = newValue ? newValue.value : '';

  onChange({
    target: {
      name: action.name,
      value: value,
    },
  });
}

function handleOnBlur(name, onBlur) {
  if (!onBlur) return;
  onBlur(utils.createEvent(null, name));
}

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <svgs.Chevron
          className={'select__chevron'}
        />
      </components.DropdownIndicator>
    )
  );
};

const ClearIndicator = ({selectProps, clearValue, ...props}) => {

  function getButtonAnimationClass() {
    if (typeof selectProps.menuIsOpen !== 'boolean') return null;

    if (selectProps.menuIsOpen) return 'react-select__close-button_visible';

    return 'react-select__close-button_hidden';
  }

  function onClear() {
    clearValue();
  }

  return (
    <svgs.Close
      className={[
        'react-select__close-button',
        getButtonAnimationClass(),
      ].join(' ')}
      onClick={onClear}
    />
  );
};

function getOptions(options, value, isLoading, onGetOptions) {
  const isInitializedWithDefaultValue = !!value;
  const isLoaded = !isInitializedWithDefaultValue && options.length;
  if (!isLoaded && !isLoading) {
    onGetOptions();
  }
}

function createFilterOption() {
  return createFilter({
    matchFrom: 'start',
  });
}