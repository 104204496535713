import './Datepicker.scss';

import React from "react";
import PropTypes from 'prop-types';

import svgs from "@assets/svgs";

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  isFocused: PropTypes.bool,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onBlur: PropTypes.func,
};

function CustomInput({className, value, placeholder, isFocused, onFocus, onChange, onBlur, onClear, ...props}, ref) {

  function getButtonAnimationClass() {
    if (typeof isFocused !== 'boolean') return null;

    if (isFocused) return 'datepicker__close-button_visible';

    return 'datepicker__close-button_hidden';
  }

  return (
    <div
      {...props}
      className={[
        "datepicker__header",
        className,
      ].join(' ')}

    >
      <input
        className={"datepicker__input"}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref}
      />
      <svgs.Calendar className={'datepicker__icon'}/>
      <svgs.Close
        className={[
          'datepicker__close-button',
          getButtonAnimationClass(),
        ].join(' ')}
        onClick={onClear}
      />
    </div>
  );
}

const CustomInputWithRef = React.forwardRef(CustomInput);

CustomInputWithRef.propTypes = propTypes;

export default CustomInputWithRef;

