import React from 'react';
import PropTypes from "prop-types";

import {
  useState,
  useRef,
  useCallback,
} from 'react';

import Input from './Input';

InputContainer.propTypes = {
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

InputContainer.defaultProps = {
  onFocus: new Function(),
  onBlur: new Function(),
  onChange: new Function(),
};

export default function InputContainer({name, onFocus, onBlur, onChange, ...props}) {

  const [isFocused, setIsFocused] = useState(null);
  const inputRef = useRef();

  function handleFocus(event) {
    setIsFocused(true);
    onFocus(event);
  }

  function handleBlur(event) {
    setIsFocused(false);
    onBlur(event);
  }

  function handleClear() {
    const event = new Event('input' );

    inputRef.current.value = '';
    inputRef.current.dispatchEvent(event);
    onChange({target: {value: '', name}});
  }

  const focusHandler = useCallback(handleFocus, [onFocus]);
  const blurHandler = useCallback(handleBlur, [onBlur]);
  const clearHandler = useCallback(handleClear, [isFocused, name]);

  return <Input
    {...props}
    name={name}
    isFocused={isFocused}
    onFocus={focusHandler}
    onBlur={blurHandler}
    onChange={onChange}
    ref={inputRef}
    onClear={clearHandler}
  />;
}