import './Bookings.scss';

import React from 'react';
import {useTranslation} from "react-i18next";
import {compose} from '@reduxjs/toolkit';

import svgs from "@assets/svgs";
import BookingColumnType from "@types/BookingColumnType";

import createBookingsColumns from "./createBookingsColumns";

import {
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import {
  useLazyGetBookingsQuery,
  useGetBankOptionsQuery,
  useGetStatusOptionsQuery,
  useGetReceiptTypeOptionsQuery,
} from '@api/bookings';

import {
  useTableObject,
} from '@hooks/index';

import {
  WithViewInvoicePopup,
} from '@hocs/index'

import {
  Title,
  PaginatedTable,
  Table,
  BlockProperty,
  SectionDropdown,
} from "@components/index";

function Bookings({onShowViewInvoicePopup}) {

  const {t} = useTranslation();

  const [
    triggerGetBookings,
    getBookingsResult,
    getBookingsLastPromiseInfo,
  ] = useLazyGetBookingsQuery();

  const {
    data: getBookingsResponse,
    isFetching: isGetBookingsFetching,
  } = getBookingsResult || {};

  const {
    bookings,
    pagination,
  } = getBookingsResponse || {};

  const {
    pageSize,
    currentPage,
    totalCount,
  } = pagination || {};

  const {
    data: bankOptions,
    isFetching: areBankOptionsFetching,
  } = useGetBankOptionsQuery();

  const {
    data: statusOptions,
    isFetching: areStatusOptionsFetching,
  } = useGetStatusOptionsQuery();

  const {
    data: receiptTypeOptions,
    isFetching: areReceiptTypeOptionsFetching,
  } = useGetReceiptTypeOptionsQuery();

  const table = useTableObject(createBookingsColumns, bookings);

  const categories = useMemo(
    () => createBookingCategories(t),
    [t],
  );

  const onSubmitHandler = useCallback(
    (fields) => handleOnSubmit(fields, getBookingsLastPromiseInfo, triggerGetBookings),
    [getBookingsLastPromiseInfo],
  );

  const onPageChangeHandler = useCallback(
    (page) => handleOnPageChange(page, getBookingsLastPromiseInfo, triggerGetBookings),
    [getBookingsLastPromiseInfo],
  );

  const onLinkClickHandler = useCallback(
    (event, id) => onShowViewInvoicePopup(id),
    [onShowViewInvoicePopup],
  );

  const tableProps = useMemo(
    () => ({
      className: 'bookings__table'
    }),
    [],
  );

  const columnsProps = useMemo (
    () => ({
      [BookingColumnType.type.BANK_NAME]: {
        options: bankOptions,
        isLoading: areBankOptionsFetching,
      },
      [BookingColumnType.type.STATUS]: {
        options: statusOptions,
        isLoading: areStatusOptionsFetching,
      },
      [BookingColumnType.type.RECEIPT_TYPE]: {
        options: receiptTypeOptions,
        isLoading: areReceiptTypeOptionsFetching,
      },
    }),
    [
      bankOptions,
      areBankOptionsFetching,
      statusOptions,
      areStatusOptionsFetching,
      receiptTypeOptions,
      areReceiptTypeOptionsFetching,
    ],
  );

  const componentsProps = useMemo(
    () => ({
      [Table.Cell.type.INVOICE_LINKS]: {
        onAddButtonClick: null,
        onLinkClick: onLinkClickHandler,
      },
    }),
    [Table.Cell.type.INVOICE_LINKS, onLinkClickHandler],
  );

  useEffect(
    () => triggerGetBookings({}),
    [],
  );

  return (
    <div className={'bookings'}>
      <Title>{t('Bookings')}</Title>
      <div className={'bookings__categories'}>
        <SectionDropdown>
        {
          categories.map((category, key) => (
            <BlockProperty
              label={t(category.label)}
              value={category.value}
              iconClassName={category.iconClassName}
              Icon={category.icon}
              key={key}
            />
          ))
        }
        </SectionDropdown>
      </div>
      <div className={'bookings__table-container'}>
        <PaginatedTable
          className={'bookings__paginated-table'}
          pageSize={pageSize}
          currentPage={currentPage || 0}
          totalCount={totalCount}
          isLoading={isGetBookingsFetching}
          table={table}
          componentsAdditionalProps={componentsProps}
          columnsAdditionalProps={columnsProps}
          tableProps={tableProps}
          onCurrentPageChange={onPageChangeHandler}
          onSubmit={onSubmitHandler}
        />
      </div>
    </div>
  );
}

function handleOnSubmit(fields, getBookingsLastPromiseInfo, triggerGetBookings) {
  triggerGetBookings({
    ...getBookingsLastPromiseInfo.lastArg,
    ...fields,
    [BookingColumnType.type.GROSS_AMOUNT]: fields[BookingColumnType.type.GROSS_AMOUNT],
    [BookingColumnType.type.NET_AMOUNT]: fields[BookingColumnType.type.NET_AMOUNT],
    page: 0,
  });
}

function handleOnPageChange(page, getBookingsLastPromiseInfo, triggerGetBookings) {
  triggerGetBookings({
    ...getBookingsLastPromiseInfo.lastArg,
    page: page,
  });
}

function createBookingCategories(t) {
  return [
    createBookingCategory(t('Wage'), '€111.00', 'bookings__wage-category-icon', svgs.Salary),
    createBookingCategory(t('Transport'), '€111.00', 'bookings__transport-category-icon', svgs.Car),
    createBookingCategory(t('Poco'), '€111.00', 'bookings__poco-category-icon', svgs.Poco),
    createBookingCategory(t('Garage'), '€111.00', 'bookings__garage-category-icon', svgs.Garage),
    createBookingCategory(t('Wage'), '€111.00', 'bookings__wage-category-icon', svgs.Salary),
    createBookingCategory(t('Transport'), '€111.00', 'bookings__transport-category-icon', svgs.Car),
    createBookingCategory(t('Poco'), '€111.00', 'bookings__poco-category-icon', svgs.Poco),
    createBookingCategory(t('Garage'), '€111.00', 'bookings__garage-category-icon', svgs.Garage),
  ];
}

function createBookingCategory (label, value, iconClassName, icon, ) {
  return {label, value, icon, iconClassName}
}

export default compose(
  WithViewInvoicePopup,
)(Bookings);