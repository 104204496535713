import i18next from "i18next";

import validators from '@yup/index';
import BookingColumnType from "@types/BookingColumnType";
import {createColumn} from "@utils/dataCreators";
import {Table} from '@components/index';

function createBookingsColumns() {
  return {

    [BookingColumnType.type.INDEX]: createColumn(
      BookingColumnType.type.INDEX,
      '№',
      'bookings__index-cell',
    ),

    [BookingColumnType.type.STATUS]: createColumn(
      BookingColumnType.type.STATUS,
      i18next.t('Status'),
      'bookings__status-cell',
      {type: Table.Cell.type.SELECT_FIELD},
    ),

    [BookingColumnType.type.INVOICE]: createColumn(
      BookingColumnType.type.INVOICE,
      i18next.t('Invoice'),
      'bookings__invoice-cell',
      {type: Table.Cell.type.INPUT_FIELD, validators: validators.text},
    ),

    [BookingColumnType.type.RECEIPT]: createColumn(
      BookingColumnType.type.RECEIPT,
      i18next.t('Receipt'),
      'bookings__receipt-cell',
      {type: Table.Cell.type.INPUT_FIELD, validators: validators.text},
    ),

    [BookingColumnType.type.BANK_NAME]: createColumn(
      BookingColumnType.type.BANK_NAME,
      i18next.t('Bank Name'),
      'bookings__bank-name-cell',
      {type: Table.Cell.type.SELECT_FIELD},

    ),

    [BookingColumnType.type.USAGE]: createColumn(
      BookingColumnType.type.USAGE,
      i18next.t('Usage'),
      'bookings__usage-cell',
      {type: Table.Cell.type.INPUT_FIELD, validators: validators.text},
    ),

    [BookingColumnType.type.DATE]: createColumn(
      BookingColumnType.type.DATE,
      i18next.t('Date'),
      'bookings__date-cell',
      {type: Table.Cell.type.DATEPICKER_FIELD},
    ),

    [BookingColumnType.type.NET_AMOUNT]: createColumn(
      BookingColumnType.type.NET_AMOUNT,
      i18next.t('Net Amount'),
      'bookings__net-amount-cell',
      {type: Table.Cell.type.INPUT_FIELD, validators: validators.text},
    ),

    [BookingColumnType.type.GROSS_AMOUNT]: createColumn(
      BookingColumnType.type.GROSS_AMOUNT,
      i18next.t('Gross Amount'),
      'bookings__gross-amount-cell',
      {type: Table.Cell.type.INPUT_FIELD, validators: validators.text},
    ),

    [BookingColumnType.type.RECEIPT_TYPE]: createColumn(
      BookingColumnType.type.RECEIPT_TYPE,
      i18next.t('Receipt Type'),
      'bookings__receipt-type-cell',
      {type: Table.Cell.type.SELECT_FIELD},
    ),
  };
}

export default createBookingsColumns;