import PropTypes from 'prop-types';

const SelectOption = PropTypes.exact({
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  label: PropTypes.string.isRequired,
});

export default SelectOption;