import * as Yup from 'yup';
import errors from './errors';

const regex = /.*^(?=.{8,20})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/;

export default Yup
  .string()
  .test(
    'strong password',
    errors.notAStrongPassword,
    (value) => regex.test(value),
  );