import './RadioButton.scss';

import React from 'react';
import PropTypes from 'prop-types';

RadioButton.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

function RadioButton({className, name, value, onChange, onFocus, onBlur, ...props}) {
  return (
    <div
      {...props}
      className={[
        'radio-button',
        className,
      ].join(' ')}
    >
      <label className={'radio-button__label'}>
        <input
          className={'radio-button__input'}
          type="radio"
          name={name}
          checked={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <span className={'radio-button__checkmark'}/>
      </label>
    </div>
  );
}

export default RadioButton;