import './TextLink.scss';

import React from 'react';
import PropTypes from 'prop-types';

TextLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
};

function TextLink({className, children, url, ...props}) {
  return (
    <a
      {...props}
      className={[
        'text-link',
        className,
      ].join(' ')}
      href={url}
    >
      {children}
    </a>
  );
}

export default TextLink;