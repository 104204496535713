import mapInvoiceFilePath from './mapInvoiceFilePath';

class InvoiceFilePathModel {
  static default = '';

  static parse(invoice) {
    if (!invoice) return InvoiceFilePathModel.default;
    return mapInvoiceFilePath(invoice.path);
  }
}

export default InvoiceFilePathModel;