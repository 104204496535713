import {createSlice} from "@reduxjs/toolkit";

import {init} from "@redux/actions";

const initialState = {
  language: 'de',
};

const locales = createSlice({
  name: 'locales',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addMatcher(
      init.match,
      function (state) {
        const savedLanguage = localStorage.getItem('language');

        if (savedLanguage) {
          state.language = savedLanguage;
        } else {
          localStorage.setItem('language', state.language);
        }

        return state;
      },
    );

  }
});

export function setLanguage(language) {
  return function setLanguageThunk(dispatch, getState) {
    const
      state = getState(),
      currentLanguage = selectLanguage(state);

    if (currentLanguage === language) return;

    localStorage.setItem('language', language);
    window.location.reload();
  }
}

export default locales.reducer;

export const {} = locales.actions;

export const selectLanguage = (state) => state.locales.language;