import React from 'react';

import OutputSelectField from "./OutputSelectField";

import {WithFormikField} from 'hocs/index';

function PropsGate({isTouched, ...props}) {
  return <OutputSelectField {...props}/>
}

export default WithFormikField(PropsGate);