import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';

import reducers from './reducers';
import middlewares from './middlewares';
import {init} from './actions';

const store = configureStore({
  reducer: reducers,
  middleware: middlewares,
  devTools: process.env.NODE_ENV !== 'production',
});

setupListeners(store.dispatch);

store.dispatch(init())

export default store;