import './Input.scss';

import React from 'react';
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

import svgs from '@assets/svgs';

const propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  label: PropTypes.node,
  placeholder: PropTypes.node,
  error: PropTypes.node,
  isTouched: PropTypes.bool,
  isFocused: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf([null]),
  ]),
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
};

const defaultProps = {
  value: '',
};

function Input({className, name, value, label, placeholder, isFocused, error, isTouched,
                 onChange, onClear, onBlur, onFocus, ...props}, ref) {

  const {t} = useTranslation();

  function getButtonAnimationClass() {
    if (typeof isFocused !== 'boolean') return null;

    if (isFocused) return 'input__close-button_visible';

    return 'input__close-button_hidden';
  }

  const isErrorVisible = error && isTouched;

  return (
    <div
      className={[
        'input',
        isFocused ? 'input_focused' : null,
        isErrorVisible ? 'input_with-error' : null,
        className,
      ].join(' ')}
    >
      {
        label
          ? <p className={'input__label'}>{label}</p>
          : null
      }
      <div className={'input__node-container'}>
        <input
          {...props}
          className={'input__node'}
          value={value}
          name={name}
          placeholder={t(placeholder)}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          ref={ref}
        />
        <svgs.Close
          className={[
            'input__close-button',
            getButtonAnimationClass(),
          ].join(' ')}
          onClick={onClear}
        />
      </div>
      {
        isErrorVisible
          ? (
            <div className={'input__error-container'}>
              <p className={'input__error'}>{t(error)}</p>
            </div>
          )
          : null
      }
    </div>
  );
}

const InputWithRef = React.forwardRef(Input);

InputWithRef.propTypes = propTypes;
InputWithRef.defaultProps = defaultProps;

export default InputWithRef;