import './AuthForm.scss';

import React from 'react';
import PropTypes from 'prop-types';

import Redirect from './Redirect';

Text.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

function Text({className, children, ...props}) {
  return (
    <h1
      {...props}
      className={[
        'auth-form__text',
        className,
      ].join(' ')}
    >
      {children}
    </h1>
  );
}

Text.Redirect = Redirect;

export default Text;