import './AuthForm.scss';

import React from 'react';
import PropTypes from 'prop-types';

import Text from './Text';
import Button from './Button';
import Checkbox from './Checkbox';
import RedirectionLink from './RedirectionLink';
import InputField from "./InputField";
import FormError from "./FormError";

AuthForm.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
  description: PropTypes.node,
  children: PropTypes.node.isRequired,
};

function AuthForm({className, title, description, children, ...props}) {
  return (
    <div
      {...props}
      className={[
        'auth-form',
        className,
      ].join(' ')}
    >
      <header className={'auth-form__header'}>
        <h1 className={'auth-form__title'}>{title}</h1>
        {
          description
            ? <p className={'auth-form__text-font auth-form__description'}>{description}</p>
            : null
        }
      </header>
      <div className={'auth-form__form'}>
        {children}
      </div>
    </div>
  );
}

AuthForm.Text = Text;
AuthForm.InputField = InputField;
AuthForm.Button = Button;
AuthForm.Checkbox = Checkbox;
AuthForm.RedirectionLink = RedirectionLink;
AuthForm.FormError = FormError;

export default AuthForm;