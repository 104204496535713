import './SectionDropdown.scss'

import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

import svgs from '@assets/svgs';
import types from '@types/index';

SectionDropdown.propTypes = {
  className: PropTypes.string,
  restChildrenContainerStyle: PropTypes.object,
  alwaysVisibleChildren: PropTypes.arrayOf(PropTypes.element).isRequired,
  restChildren: PropTypes.arrayOf(PropTypes.element).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onToggleButtonClick: PropTypes.func.isRequired,
  restChildrenContainerRef: types.ElementRef,
};

function SectionDropdown({
                           className,
                           restChildrenContainerStyle,
                           alwaysVisibleChildren,
                           restChildren,
                           isOpen,
                           onToggleButtonClick,
                           restChildrenContainerRef,
                           ...props }) {

  const {t} = useTranslation();

  return (
    <article
      {...props}
      className={[
        'section-dropdown',
        className,
      ].join(' ')}
    >
      <header className={'section-dropdown__header'}>
        <ul className={'section-dropdown__list'}>
          {
            alwaysVisibleChildren.map((child, key) => (
              <li
                className={'section-dropdown__item'}
                key={key}
              >
                {child}
              </li>
            ))
          }
        </ul>
        <div
          className={[
            'section-dropdown__rest-items-container',
            isOpen ? 'section-dropdown__rest-items-container_visible' : null,
          ].join(' ')}
          style={restChildrenContainerStyle}
          ref={restChildrenContainerRef}
        >
          <ul className={'section-dropdown__list section-dropdown__rest-items'}>
            {
              restChildren.map((child, key) => (
                <li className={'section-dropdown__item'} key={key}>{child}</li>
              ))
            }
          </ul>
        </div>
        <div className={'section-dropdown__dropdown-button-container'}>
          <button
            className={'section-dropdown__dropdown-button'}
            onClick={onToggleButtonClick}
          >
            <span className={'section-dropdown__dropdown-button-text'}>{t('Show more')}</span>
            <svgs.ArrowDown
              className={[
                'section-dropdown__arrow',
                isOpen ? 'section-dropdown__arrow_up' : null,
              ].join(' ')}
            />
          </button>
        </div>
      </header>
    </article>
  );
}

export default SectionDropdown;