import React from 'react';
import {useTranslation} from "react-i18next";

import {
  useEffect,
} from 'react';

import {
  InputField,
  SelectField,
  AutoGeneratedRadioButtonTable,
  Pagination,
} from "@components/index";

function DefaultPaymentForm({
                                    values,
                                    errors,
                                    touched,
                                    table,
                                    pagination,
                                    receiverOptions,
                                    userOptions,
                                    descriptionOptions,
                                    typeTransferOptions,
                                    isTypeTransferOptionsLoading,
                                    isReceiverOptionsLoading,
                                    isUserOptionsLoading,
                                    isDescriptionOptionsLoading,
                                    areInvoicesLoading,
                                    setFieldValue,
                                    onGetReceiverOptions,
                                    onGetTypeTransferOptions,
                                    onGetNameOptions,
                                    onGetDescriptionOptions,
                                    onCheckInvoiceHandler,
                                    onSearchInvoiceFieldsChangeHandler,
                                    onPageChangeHandler,
                                  }) {

  const {t} = useTranslation();

  useEffect(
    () => resetInvoiceId(values['description'], setFieldValue),
    [values['description']],
  );

  return (
    <>
      <SelectField
        className={'create-payment-popup__field'}
        name={'receiver'}
        label={t('Receiver')}
        options={receiverOptions}
        isLoading={isReceiverOptionsLoading}
        onGetOptions={onGetReceiverOptions}
      />
      <SelectField
        className={'create-payment-popup__field'}
        name={'name'}
        label={t('Name')}
        options={userOptions}
        isLoading={isUserOptionsLoading}
        isSearchable={true}
        dependencies={['receiver']}
        fallback={null}
        forceFallbackIfHasDefault={true}
        onFallback={onGetNameOptions}
      />
      <SelectField
        className={'create-payment-popup__field'}
        name={'description'}
        label={t('Description')}
        options={descriptionOptions}
        isLoading={isDescriptionOptionsLoading}
        dependencies={['name']}
        fallback={null}
        forceFallbackIfHasDefault={true}
        onFallback={onGetDescriptionOptions}
      />
      {
        values['receiver'] === '1' // Employee
          ? (
            <SelectField
              className={'create-payment-popup__field'}
              name={'typeTransfer'}
              label={t('Type Transfer')}
              options={typeTransferOptions}
              isLoading={isTypeTransferOptionsLoading}
              dependencies={['receiver']}
              fallback={null}
              onGetOptions={onGetTypeTransferOptions}
            />
          )
          : null
      }
      {
        values['description'] === '3' && touched['description'] // Reimbursement of receipts
          ? (
            <>
              <div className={'create-payment-popup__table-container'}>
                <AutoGeneratedRadioButtonTable
                  className={'create-payment-popup__table'}
                  isLoading={areInvoicesLoading}
                  table={table}
                  onSelectItemSubmit={onCheckInvoiceHandler}
                  onSearchFormSubmit={onSearchInvoiceFieldsChangeHandler}
                />
                <Pagination
                  className={'create-payment-popup__pagination'}
                  selectedPageIndex={pagination.currentPage}
                  fullAmount={pagination.totalCount}
                  pageSize={pagination.pageSize}
                  isLoading={areInvoicesLoading}
                  onPageNumberClick={onPageChangeHandler}
                />
              </div>
              {
                errors['checkedInvoiceId'] && touched['checkedInvoiceId']
                  ? (
                    <div className={'create-payment-popup__error-container'}>
                      <p className={'create-payment-popup__error'}>{t(errors['checkedInvoiceId'])}</p>
                    </div>
                  )
                  : null
              }
            </>
          )
          : null
      }
      <InputField
        className={'create-payment-popup__field'}
        name={'amount'}
        label={t('Amount')}
      />
      <InputField
        className={'create-payment-popup__field'}
        name={'usage'}
        label={t('Usage')}
      />
    </>
  );
}

function resetInvoiceId(description, setFieldValue) {
  if (description !== '3') {
    setFieldValue('checkedInvoiceId', null);
  }
}

export default DefaultPaymentForm;