import PropTypes from 'prop-types';
import i18next from "i18next";
import {nanoid} from 'nanoid';

import ErrorType from "./ErrorType";

const options = {

  [ErrorType.type.INVOICES_AMOUNTS_SUM_BIGGER_THEN_TRANSACTION_AMOUNT]: {
    type: ErrorType.type.INVOICES_AMOUNTS_SUM_BIGGER_THEN_TRANSACTION_AMOUNT,
    status: 400,
    code: 14,
    description: 'Connection is not available. Invoices amounts sum > transaction amount',
  },

  [ErrorType.type.SERVER_SIDE_FORM_VALIDATION_ERRORS]: {
    type: ErrorType.type.SERVER_SIDE_FORM_VALIDATION_ERRORS,
    status: 400,
    code: 15,
    description: 'Server side form validation failed',
  },

  [ErrorType.type.IMPOSSIBLE_ADVANCES_PAYMENT]: {
    type: ErrorType.type.IMPOSSIBLE_ADVANCES_PAYMENT,
    status: 400,
    code: 22,
    description: 'Only two advanced payments can be created for a user in month',
  },

  [ErrorType.type.INVALID_CREDENTIALS]: {
    type: ErrorType.type.INVALID_CREDENTIALS,
    status: 401,
    code: 0,
    description: 'Access token is wrong',
  },

  [ErrorType.type.ACCESS_TOKEN_TOO_OLD]: {
    type: ErrorType.type.ACCESS_TOKEN_TOO_OLD,
    status: 401,
    code: 18,
    description: 'Access token too old',
  },

  [ErrorType.type.NO_ACCESS_TOKEN]: {
    type: ErrorType.type.NO_ACCESS_TOKEN,
    status: 401,
    code: -1,
    description: 'Access token not found. Login is needed.'
  },

  [ErrorType.type.REFRESH_TOKEN_FAILED]: {
    type: ErrorType.type.REFRESH_TOKEN_FAILED,
    status: 401,
    code: -1,
    description: 'Access token wasn\'t refreshed',
  },

  [ErrorType.type.ERROR_INSTANCE_CREATION_FAILED]: {
    type: ErrorType.type.ERROR_INSTANCE_CREATION_FAILED,
    status: -1,
    code: -1,
    description: 'ErrorType passed in \'create\' doesn\'t exist.',
  },

  [ErrorType.type.NETWORK_CONNECTION_FAIL]: {
    type: ErrorType.type.NETWORK_CONNECTION_FAIL,
    status: -1,
    code: -1,
    description: 'No network',
    title: 'No network connection'
  },

};

const values = Object.values(options);

const statuses = values.map(error => error.status);
const codes = values.map(error => error.code);

const propType = PropTypes.shape({
  type: ErrorType.propType,
  status: PropTypes.oneOf(statuses),
  code: PropTypes.oneOf(codes),
  description: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.exact({
    field: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
  })),
});

function create(errorType) {
  const example = options[errorType];

  if (!example) {
    const fallbackError = create(ErrorType.type.ERROR_INSTANCE_CREATION_FAILED);
    return  fallbackError;
  }

  const instance = {...example};
  instance.id = nanoid();
  instance.isServerError = false;
  instance.title = example.title ? i18next.t(example.title) : '';
  instance.message = example.message ? i18next.t(example.message) : '';

  return instance;
}

const Error = {
  propType: propType,
  type: options,
  values: values,
  create: create,
};

export default Error;

