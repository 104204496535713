import i18next from "i18next";

import HistoryColumn from '@types/HistoryColumn';
import {createColumn} from "@utils/dataCreators";

function createHistoryColumns() {
  return {
    [HistoryColumn.type.NAME]: createColumn(
      HistoryColumn.type.NAME,
      i18next.t('Name'),
      'dashboard__history-name-cell',
    ),

    [HistoryColumn.type.ACTION]: createColumn(
      HistoryColumn.type.ACTION,
      i18next.t('Action'),
      'dashboard__history-action-cell',
    ),

    [HistoryColumn.type.DATE]: createColumn(
      HistoryColumn.type.DATE,
      i18next.t('Date'),
      'dashboard__history-date-cell',
    ),

  };
}

export default createHistoryColumns;