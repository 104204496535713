import React from 'react';
import PropTypes from 'prop-types';

import {
  useMemo,
  useCallback,
} from 'react';

import './ConfirmPayments.scss';
import svgs from "@assets/svgs";

ConfirmPayments.propTypes = {
  className: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    hasConfirmed: PropTypes.bool.isRequired,
  })),
  paymentId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  currentUserId : PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  onDeleteButtonClick: PropTypes.func.isRequired,
  onUserIconClick: PropTypes.func.isRequired,
};

function ConfirmPayments({className, users, paymentId, currentUserId,
                           onEditButtonClick, onDeleteButtonClick, onUserIconClick, ...props}) {

  const onDeleteButtonClickHandler = useCallback(
    () => onDeleteButtonClick(paymentId),
    [paymentId],
  );

  const onEditButtonClickHandler = useCallback(
    () => onEditButtonClick(paymentId),
    [paymentId],
  );

  return (
    <div
      {...props}
      className={[
        'confirm-payments',
        className
      ].join(' ')}
    >
      <div className={'confirm-payments__users-container'}>
      {
        users?.map(user => {

          const payload = useMemo(
            () => getPayload(paymentId, user),
            [paymentId, user],
          );

          const onUserClickHandler = useCallback(
            (event) => handleOnUserClick(event, payload, onUserIconClick),
            [payload, onUserIconClick],
          );

          return (
            <div
              className={[
                'confirm-payments__user',
                user.hasConfirmed ? 'confirm-payments__user_has-confirmed' : null,
                currentUserId === user.id && !user.hasConfirmed ? 'confirm-payments__user_style_cursor-pointer' : null,
              ].join(' ')}
              key={user.id}
              onClick={onUserClickHandler}
            >
              <p className={'confirm-payments__user-label'}>{user.name}</p>
            </div>
          );
        })
      }
      </div>
      <div className={'confirm-payments__buttons-container'}>
        <button className={'confirm-payments__button'} onClick={onEditButtonClickHandler}>
          <svgs.Edit/>
        </button>
        <button className={'confirm-payments__button'} onClick={onDeleteButtonClickHandler}>
          <svgs.Delete/>
        </button>
      </div>
    </div>
  );
}

function handleOnUserClick(event, payload, onUserIconClick) {
  if (!onUserIconClick) return;
  onUserIconClick(event, payload);
}

function getPayload(paymentId, user) {
  return {
    paymentId: paymentId,
    user: user,
  };
}

export default ConfirmPayments;