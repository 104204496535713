import mappers from '@mappers/index';
import types from '@types/index';
import tags from './tags';
import {api} from './index';
import utils from "@/utils";

const resource = 'invoice';

export const invoiceApi = api.injectEndpoints({

  endpoints: (builder) => ({

    getInvoice: builder.query({
      queryFn: async (arg, api1, extraOptions, baseQuery) => {
        const response = await baseQuery(resource + `/get-invoice?invoice_id=${arg}`);
        const mapper = utils.createResourceMapper(
          mappers.mapInvoice,
          'invoice',
        );
        return utils.handleQueryFnResponse(response, mapper);
      }
    }),

    getAvailableInvoicesByTransactionId: builder.query({
      queryFn: async (args, api1, extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: 'transaction' + '/get-list-invoices',
          params: {
            transaction_id: args.transactionId,
            page: args.page,
            'InvoiceTransaction[amount]': args[types.AvailableInvoiceColumnType.type.AMOUNT],
            'InvoiceTransaction[receiver]': args[types.AvailableInvoiceColumnType.type.RECEIVER],
            'InvoiceTransaction[invoice_number]': args[types.AvailableInvoiceColumnType.type.INVOICE_NUMBER],
          },
        });

        const mapper = utils.createPaginatedMapper(
          mappers.mapAvailableInvoices,
          'invoice',
          'invoices',
        );

        return utils.handleQueryFnResponse(response, mapper);
      },
      providesTags: [tags.AVAILABLE_INVOICES],
    }),

    getInvoiceSources: builder.query({
      queryFn: async (arg, api1, extraOptions, baseQuery) => {

        const response = await baseQuery({
          url: 'dashboard' + '/monitoring',
        });

        return utils.handleQueryFnResponse(response, mappers.mapInvoiceSource);
      }
    }),

  }),

});

export const {
  useLazyGetInvoiceQuery,
  useLazyGetAvailableInvoicesByTransactionIdQuery,
  useLazyGetInvoiceSourcesQuery,
} = invoiceApi;