import PropTypes from 'prop-types';

const options = {
  CREATE_PAYMENT: 'create_payment',
  SETTINGS_NAV_ITEMS: 'settings_nav_item',
  TRANSACTION_NAV_ITEM: 'translation_nav_item',
};

const Permission = {
  propType: PropTypes.oneOf(Object.values(options)),
  type: options,
};

export default Permission;