import utils from '@utils/index';
import types from '@types/index';

import {
  createSlice,
  createSelector,
  isRejected,
} from "@reduxjs/toolkit";

import {
  isRejectedWithUserFriendlyError,
  createIsErrorTypeMatcher,
  matchUnauthorized,
} from '@slices/matchers/index'

const initialState = {
  queue: [],
  errors: [],
  errorsLimit: 30,
  queueLimit: 5,
  currentErrorId: null,
  isCurrentErrorVisible: false,
  hideActionTimestamp: -1,
  showActionInterval: 1000,
};

const errors = createSlice({
  name: 'errors',
  initialState: initialState,
  reducers: {

    show: (state) => {
      const isUnhandledErrorAvailable = getIsUnhandledErrorAvailable(state);
      const isCurrentErrorVisible = getIsCurrentErrorVisible(state);

      if (isCurrentErrorVisible) return;
      if (!isUnhandledErrorAvailable) return;

      const nextId = state.queue.shift();

      state.currentErrorId = nextId;
      state.isCurrentErrorVisible = true;
    },

    hide: {
      reducer: (state, action) => {
        state.isCurrentErrorVisible = false;
        state.hideActionTimestamp = action.meta.timestamp;
      },
      prepare: () => ({
        meta: {
          timestamp: Date.now(),
        },
      }),
    },

  },
  extraReducers: (builder) => {

    builder.addMatcher(
      isRejected,
      function (state, action) {
        pushError(state, action.payload);
      },
    );

    builder.addMatcher(
      isRejectedWithUserFriendlyError,
      function (state, action) {
        const matchIsNoNetworkError = createIsErrorTypeMatcher(types.ErrorType.type.NETWORK_CONNECTION_FAIL);

        const isNoNetworkError = matchIsNoNetworkError(action);

        if (isNoNetworkError) {
          const isNotNetworkErrorAlreadyInQueue = !!state.queue
            .map((id) => state.errors.find((error) => error.id === id))
            .find((error) => matchIsNoNetworkError({payload: error}));

          if (isNotNetworkErrorAlreadyInQueue) return;
        }

        const isUnauthorizedError = matchUnauthorized(action);

        if (isUnauthorizedError) return;

        pushUserFriendlyError(state, action.payload);
      },
    );
  },
});

function pushUserFriendlyError(state, error) {
  if (!(error.message || error.title)) return;

  state.queue.push(error.id);

  if (state.queue.length > state.queueLimit){
    state.queue.shift();
  }
}

function pushError(state, error) {
  if (!error) return;

  let flag = state.errors.length + 1 >= state.errorsLimit;
  let counter = 0;

  state.errors = state.errors.filter((error) => {
    const isInQueue = !!state.queue.find((id) => error.id === id);

    if (isInQueue) return true;

    if (!flag) return true;

    counter++;
    flag = state.errors.length - counter + 1 >= state.errorsLimit;
    return false;
  });

  state.errors.push(error);
}


function watchQueue() {
  let isWatcherStarted = true;

  return function thunk(dispatch, getState) {
    setTimeout(
      ()=>{
        watcher()
        dispatch(show())
      },
      0
    );
    setTimeout(
      ()=>{
        dispatch(show())
      },
      2000
    );
    dispatch(show())
    return unsubscribe;

    async function watcher() {
      while (isWatcherStarted) {
        const
          state = getState(),
          isCurrentErrorVisible = selectIsCurrentErrorVisible(state),
          hideActionTimestamp = selectHideActionTimestamp(state),
          showActionInterval = selectShowActionInterval(state),
          isUnhandledErrorAvailable = selectIsUnhandledErrorAvailable(state);

        const hasIntervalPassed = hideActionTimestamp < 0 || Date.now() - hideActionTimestamp > showActionInterval;

        if (isUnhandledErrorAvailable && !isCurrentErrorVisible && hasIntervalPassed) {
          dispatch(show());
        }

        await utils.sleep(showActionInterval);
      }
    }
  }



  function unsubscribe() {
    isWatcherStarted = false;
  }
}

export const startQueueWatcher = watchQueue;

export default errors.reducer;

export const {
  show,
  hide,
} = errors.actions;

export const getIsUnhandledErrorAvailable = (state) => !!(state.queue && state.queue.length);
export const getIsCurrentErrorVisible = (state) => state.isCurrentErrorVisible;

export const selectIsCurrentErrorVisible = (state) => getIsCurrentErrorVisible(state.errors);
export const selectHideActionTimestamp = (state) => state.errors.hideActionTimestamp;
export const selectShowActionInterval = (state) => state.errors.showActionInterval;
export const selectIsUnhandledErrorAvailable = (state) => getIsUnhandledErrorAvailable(state.errors);
export const selectCurrentErrorId = (state) => state.errors.currentErrorId;
export const selectErrors = (state) => state.errors.errors;

export const selectCurrentError = createSelector(
  selectCurrentErrorId,
  selectErrors,
  (currentId, errors) => errors.find(error => error.id === currentId),
);