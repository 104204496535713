import React from 'react';
import PropTypes from 'prop-types';

import {
  useCallback,
} from 'react';

import './Pagination.scss';

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.number]).isRequired,
  payload: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  onClick: PropTypes.func.isRequired,
};

Button.defaultProps = {
  onClick: new Function(),
};

function Button ({className, children, payload, isActive, onClick, ...props}) {

  function handleOnClick(e) {
    onClick(e, payload);
  }

  const onClickHandler = useCallback(handleOnClick, [onClick]);

  return (
    <button
      {...props}
      className={[
        'pagination__number-button',
        isActive ? 'pagination__number-button_active' : null,
        className,
      ].join(' ')}
      onClick={onClickHandler}
    >
      <span className={'pagination__number-button-text'}>{children}</span>
    </button>
  );
}

export default Button;