import React from 'react';
import PropTypes from 'prop-types';

import InvoiceLinks from './InvoiceLinks';

import {
  useCallback,
} from 'react';

import {
  useHandlerWithPayload,
} from '@hooks/index';


InvoiceLinksContainer.propTypes = {
  addButtonPayload: PropTypes.object,
  onAddButtonClick: PropTypes.func.isRequired,
};

function InvoiceLinksContainer({addButtonPayload, onAddButtonClick, ...props}) {

  const onAddButtonClickHandler = useHandlerWithPayload(onAddButtonClick, addButtonPayload);

  return (
    <InvoiceLinks
      {...props}
      onAddButtonClick={onAddButtonClickHandler}
    />
  );
}

export default InvoiceLinksContainer;