import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {useTranslation} from "react-i18next";

import errors from '@yup/errors';
import validators from '@yup/index';

import FormFields from "./FormFields";

import {
  useEffect,
  useState,
  useCallback,
} from 'react';

import {
  useHistory,
} from 'react-router-dom';

import {
  AuthForm,
  ConfirmPopup,
} from '@components/index';

import {
  Formik,
  Form,
} from 'formik';

import {
  useLazyRequestPasswordChangeQuery,
} from '@api/authApi';

const ForgotPasswordSchema = Yup.object().shape({
  [FormFields.EMAIL]: Yup
    .string()
    .required(errors.required)
    .concat(validators.email),
});

const initialValues = {
  [FormFields.EMAIL]: '',
};

ForgotPasswordFragment.propTypes = {
  className: PropTypes.string,
  pathToLogin: PropTypes.string.isRequired,
};

function ForgotPasswordFragment({className, pathToLogin, ...props}) {

  const {t} = useTranslation();

  const history = useHistory();

  const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false)

  const [
    triggerRequestPasswordChange,
    result,
  ] = useLazyRequestPasswordChangeQuery();

  const {
    error,
    isSuccess,
    isFetching,
  } = result || {};

  useEffect(
    () => handleSuccess(isSuccess, isFetching, setIsSuccessPopupVisible),
    [isSuccess, isFetching],
  );

  const onConfirmCloseHandler = useCallback(
    () => handleOnConfirmPopupClose(history, pathToLogin),
    [history, pathToLogin],
  );

  return (
    <AuthForm
      {...props}
      className={className}
      title={t('forgot-password-title')}
      description={t('forgot-password-description')}
    >
      <Formik
        validationSchema={ForgotPasswordSchema}
        initialValues={initialValues}
        onSubmit={triggerRequestPasswordChange}
      >
        {({handleSubmit, setFieldError}) => {

          useEffect(
            () => handleFieldErrors(error, setFieldError),
            [error, setFieldError],
          );

          return (
            <Form>
              <AuthForm.InputField
                name={FormFields.EMAIL}
                type={'email'}
                label={t('Email')}
              />
              <AuthForm.Button
                onClick={handleSubmit}
                inputType={'submit'}
              >
                {t('Send')}
              </AuthForm.Button>
              <AuthForm.RedirectionLink
                link={t('Back to login')}
                to={pathToLogin}
              />
            </Form>
          );
        }}
      </Formik>
      <ConfirmPopup
        isVisible={isSuccessPopupVisible}
        title={t('forgot-password-title')}
        content={t('A letter was sent to your email')}
        onButtonClick={onConfirmCloseHandler}
        onCloseButtonClick={onConfirmCloseHandler}
      />
    </AuthForm>
  );
}

function handleFieldErrors(error, setFieldError) {
  if (!error || !error.fields) return;

  for (const field of error.fields) {
    if (!field.error) continue;

    if (field.name === 'form') {
      setFieldError(FormFields.EMAIL, field.error);
    } else {
      setFieldError(field.name, field.error);
    }
  }
}

function handleSuccess(isSuccess, isFetching, setIsSuccessPopupVisible) {
  if (isSuccess && !isFetching) {
    setIsSuccessPopupVisible(true)
  }
}

function handleOnConfirmPopupClose(history, pathToLogin) {
  history.push(pathToLogin);
}

export default ForgotPasswordFragment;