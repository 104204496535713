import './InvoiceLinks.scss';

import React from 'react';
import PropTypes from 'prop-types';

import svgs from '@assets/svgs';

import Link from './LinkContainer';

InvoiceLinks.propTypes = {
  className: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
  onLinkClick: PropTypes.func.isRequired,
};

function InvoiceLinks({className, links, isAddButtonVisible,
                        onAddButtonClick, onLinkClick, ...props}) {

  return (
    <div
      {...props}
      className={[
        'invoice-links',
        className,
      ].join(' ')}
    >
      {
        links.map(link => (
          <Link
            className={'invoice-links__link'}
            id={link.id}
            name={link.name}
            key={link.id}
            onClick={onLinkClick}
          />
        ))
      }
      {
        isAddButtonVisible
          ? (
            <button
              className={'invoice-links__button'}
              onClick={onAddButtonClick}
            >
              <svgs.AddButton/>
            </button>
          )
          : null
      }
    </div>
  );
}



export default InvoiceLinks;