import React from 'react';

import Main from './Main';

import {
  useSelector,
} from "react-redux";

import {
  selectPermissions,
} from "@slices/user";

function MainScreenContainer() {

  const permissions = useSelector(selectPermissions);

  return (
    <Main
      permissions={permissions}
    />
  );
}

export default MainScreenContainer;