import i18next from "i18next";

import types from "@types/index";
import validators from '@yup/index';
import {createColumn} from "@utils/dataCreators";
import {Table} from "@components/index";

function createPaymentColumns() {
  return {
    [types.PaymentColumnType.type.STATUS]: createColumn(
      types.PaymentColumnType.type.STATUS,
      i18next.t('Status'),
      'open-payments__status-cell',
      {type: Table.Cell.type.SELECT_FIELD},
    ),

    [types.PaymentColumnType.type.RECEIVER]: createColumn(
      types.PaymentColumnType.type.RECEIVER,
      i18next.t('Receiver'),
      'open-payments__receiver-cell',
      {type: Table.Cell.type.INPUT_FIELD, validator: validators.receiver},
    ),

    [types.PaymentColumnType.type.AMOUNT]: createColumn(
      types.PaymentColumnType.type.AMOUNT,
      i18next.t('Amount'),
      'open-payments__amount-cell',
      {type: Table.Cell.type.INPUT_FIELD, validator: validators.amount},
    ),

    [types.PaymentColumnType.type.DESCRIPTION]: createColumn(
      types.PaymentColumnType.type.DESCRIPTION,
      i18next.t('Description'),
      'open-payments__description-cell',
      {type: Table.Cell.type.SELECT_FIELD},
    ),

    [types.PaymentColumnType.type.USAGE]: createColumn(
      types.PaymentColumnType.type.USAGE,
      i18next.t('Usage'),
      'open-payments__usage-cell',
      {type: Table.Cell.type.INPUT_FIELD, validator: validators.text},
    ),

    [types.PaymentColumnType.type.INVOICE_PREVIEW]: createColumn(
        types.PaymentColumnType.type.INVOICE_PREVIEW,
        i18next.t('Invoice'),
        'open-payments__invoice-preview-cell',
    ),

    [types.PaymentColumnType.type.CONFIRM_PAYMENTS]: createColumn(
      types.PaymentColumnType.type.CONFIRM_PAYMENTS,
      i18next.t('Confirm Payments'),
      'open-payments__confirm-payments-cell',
    ),
  };
}

export default createPaymentColumns;