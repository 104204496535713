import './WithVisibility.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {
  useState,
} from 'react';

import {
  useEffectOnDepsMutation,
} from "@/hooks";

const propTypes  = {
  className: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
};

function WithVisibility(Component) {
  NewComponentWithVisibility.propTypes = propTypes;

  function NewComponentWithVisibility({className, isVisible, ...props}) {
    const [isInitiallyVisible] = useState(isVisible);
    const [isInitialVisibilityValue, setIsInitialVisibilityValue] = useState(true);

    useEffectOnDepsMutation(
      () => setIsInitialVisibilityValue(false),
      [isVisible],
    )

    let visibilityAnimation = isVisible
      ? 'with-visibility_visible'
      : 'with-visibility_hidden';

    return (
      <Component
        {...props}
        className={[
          'with-visibility',
          isInitiallyVisible ? 'with-visibility_initiallyVisible' : 'with-visibility_initiallyHidden',
          isInitialVisibilityValue ? null : visibilityAnimation,
          className,
        ].join(' ')}
      />
    );
  }

  return NewComponentWithVisibility;
}

export default WithVisibility;