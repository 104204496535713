import './CreatePaymentPopup.scss';

import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {useTranslation} from "react-i18next";

import types from '@types/index';
import errors from '@yup/errors';

import CreateInvoicePaymentForm from "./CreateInvoicePaymentForm";

import {
  Popup,
  Button,
} from '@components/index';

const validationSchema = Yup.object().shape({
  receiver: Yup.string().nullable().required(errors.required),
  name: Yup.string().nullable().required(errors.required),
  description: Yup.string().nullable().required(errors.required),
  usage: Yup.string().nullable().required(errors.required),
  typeTransfer: Yup.string().nullable().test('isRequiredIfEmployee', errors.required, (value, context) => {
    if (context.parent['receiver'] === '1' && !value) return;
    return true;
  }),
  amount: Yup.number().typeError(errors.shouldBeANumber).required(errors.required),
});

CreateInvoicePaymentPopup.propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  initialValues: PropTypes.object,
  receiverOptions: PropTypes.arrayOf(types.SelectOption),
  userOptions: PropTypes.arrayOf(types.SelectOption),
  descriptionOptions: PropTypes.arrayOf(types.SelectOption),
  typeTransferOptions: PropTypes.arrayOf(types.SelectOption),
  isTypeTransferOptionsLoading: PropTypes.bool,
  isFormSubmitting: PropTypes.bool,
  onFormSubmit: PropTypes.func.isRequired,
  onCloseButtonClick: PropTypes.func.isRequired,
};

CreateInvoicePaymentPopup.defaultProps = {
  onFormSubmit: new Function(),
  onCloseButtonClick: new Function(),
};

function CreateInvoicePaymentPopup({
                                     className,
                                     isVisible,
                                     isLoading,
                                     initialValues,
                                     receiverOptions,
                                     userOptions,
                                     descriptionOptions,
                                     typeTransferOptions,
                                     isTypeTransferOptionsLoading,
                                     isFormSubmitting,
                                     onFormSubmit,
                                     onGetTypeTransferOptions,
                                     onCloseButtonClick,
                                     ...props}) {

  const {t} = useTranslation();

  return (
    <Popup
      {...props}
      className={[
        'create-payment-popup',
        className,
      ].join(' ')}
      isVisible={isVisible}
      onBackgroundClick={onCloseButtonClick}
    >
      <Popup.Container className={'create-payment-popup__container'}>
        <Formik
          key={initialValues + ''}
          initialValues={initialValues ? initialValues : {
            receiver: null,
            name: null,
            typeTransfer: null,
            amount: '',
            usage: '',
            description: '',
            checkedInvoiceId: null,
          }}
          validationSchema={validationSchema}
          onSubmit={onFormSubmit}
        >
          { function ({submitForm}) {
            return (
              <>
              <Popup.Header
                className={'create-payment-popup__header'}
                title={t('Create Payment')}
                onCloseButtonClick={onCloseButtonClick}
              />
              <Popup.Content
                className={'create-payment-popup__content'}
                isLoading={isFormSubmitting || isLoading}
              >
                <CreateInvoicePaymentForm
                  receiverOptions={receiverOptions}
                  userOptions={userOptions}
                  descriptionOptions={descriptionOptions}
                  typeTransferOptions={typeTransferOptions}
                  isTypeTransferOptionsLoading={isTypeTransferOptionsLoading}
                  onGetTypeTransferOptions={onGetTypeTransferOptions}
                />
              </Popup.Content>
                <Popup.Footer>
                  <Button
                    onClick={submitForm}
                    isLoading={isFormSubmitting}
                  >
                    {t('Save')}
                  </Button>
                </Popup.Footer>
              </>
            );
          }}
        </Formik>
      </Popup.Container>
    </Popup>
  );
}

export default CreateInvoicePaymentPopup;