import mappers from '@mappers/index';
import utils from '@utils/index';
import config from "@api/config";

import LoginFormFields from '@fragments/LoginFragment/FormFields';
import ForgotPasswordFormFields from '@fragments/ForgotPasswordFragment/FormFields';
import ResetPasswordFormFields from '@fragments/ResetPasswordFragment/FormFields';

import addLanguageHeader from "./addLanguageHeader";

import {
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/dist/query/react";

const resource = 'user';

const baseFetch = fetchBaseQuery({
  baseUrl: config.baseUrl,
  prepareHeaders: (headers, api) => {
    addLanguageHeader(headers, api);
    return headers;
  },
});

export const authApi = createApi({

  reducerPath: 'userApi',

  baseQuery:  async (query, api, extra) => {
    let result;

    try {
      result = await baseFetch(query, api, extra);
    } catch (error) {
      result = {
        error: error,
      };
    }

    return result;
  },

  keepUnusedDataFor: 0,

  endpoints: (builder) => ({

    login: builder.query({
      async queryFn(arg, store, extra, baseQuery) {
        const response = await baseQuery({
          url: resource + '/login',
          params: utils.createQueryParams()
            .add('email', arg[LoginFormFields.EMAIL])
            .add('password', arg[LoginFormFields.PASSWORD])
            .finish(),
        });

        return utils.handleQueryFnResponse(response, mappers.mapAuthToken);
      },
    }),

    requestPasswordChange: builder.query({
      async queryFn(arg, store, extra, baseQuery) {
        try {
          const response = await baseQuery({
            url: resource + '/reset-password',
            params: utils.createQueryParams()
              .add('email', arg[ForgotPasswordFormFields.EMAIL])
              .finish(),
          });

          if (response.error) {
            return utils.rejectQueryFn(mappers.mapError(response.error));
          }

          return utils.fulfillQueryFn(response.data);
        } catch (error) {
          return utils.rejectQueryFn(mappers.mapError(error));
        }
      },
    }),

    changePassword: builder.query({
      async queryFn(arg, store, extra, baseQuery) {
        try {
          const response = await baseQuery({
            url: resource + '/change-password',
            params: utils.createQueryParams()
              .add('token', arg.token)
              .add('password', arg[ResetPasswordFormFields.NEW_PASSWORD])
              .add('password_confirm', arg[ResetPasswordFormFields.CONFIRM_PASSWORD])
              .finish(),
          });

          if (response.error) {
            return utils.rejectQueryFn(mappers.mapError(response.error));
          }

          return utils.fulfillQueryFn(response.data);
        } catch (error) {
          return utils.rejectQueryFn(mappers.mapError(error));
        }
      },
    }),

    refreshToken: builder.mutation({
      async queryFn(refreshToken, store, extra, baseQuery) {
        const response = await baseQuery({
          url: resource + '/refresh-token',
          params: utils.createQueryParams()
            .add('refresh_token', refreshToken)
            .finish(),
        });

        return utils.handleQueryFnResponse(response, mappers.mapAuthToken);
      },
    }),

  }),
});

export const {
  useLazyLoginQuery,
  useLazyRequestPasswordChangeQuery,
  useLazyChangePasswordQuery,
} = authApi;