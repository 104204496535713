import {
  useMemo,
} from 'react';

function useCurrentDataSelector(result) {
  if (!result) return null;

  const {
    data,
    isFetching,
    isSuccess,
  } = result;

  const currentData = useMemo(
    () => getCurrentData(data, isFetching, isSuccess),
    [data, isFetching, isSuccess],
  );

  return currentData;
}

function getCurrentData(data, isFetching, isSuccess) {
  if (isFetching) return null;
  if (!isSuccess) return null;
  return data;
}

export default useCurrentDataSelector;