import React from 'react';
import {useTranslation} from "react-i18next";
import PropTypes from 'prop-types';

import "./Title.scss";

Title.propTypes = {
  className: PropTypes.string,
  textProps: PropTypes.object,
  children: PropTypes.string.isRequired,
};

Title.defaultProps = {
  textProps: {},
};

function Title({className, children, textProps, ...props}) {

  const {t} = useTranslation();

  return (
    <div
      {...props}
      className={[
        'title-container',
        className,
      ].join(' ')}
    >
      <h2
        {...textProps}
        className={[
          'title-container__title',
          textProps.className,
        ].join(' ')}
      >
        {t(children)}
      </h2>
    </div>
  );
}

export default Title;

