import AddNewCashRegisterColumnType from "@types/AddNewCashRegisterColumnType";

import {
  createTextCell,
  createRadioButtonCell,
} from '@utils/dataCreators';

export default function mapBeingAddedToCashRegisterTransaction(transactions) {
  return transactions.map((transaction) => ({
    id: transaction.id,
    cells: {
      [AddNewCashRegisterColumnType.type.DESCRIPTION]: createTextCell(
        AddNewCashRegisterColumnType.type.DESCRIPTION,
        transaction.name + ' | ' + transaction.description + ' | ' + transaction.payment_date
      ),

      [AddNewCashRegisterColumnType.type.RADIO_BUTTON]: createRadioButtonCell(
        AddNewCashRegisterColumnType.type.RADIO_BUTTON,
        false,
      ),
    },
  }))
}