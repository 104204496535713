import store from '@redux/store';
import config from "./config";

import createOnConnectHandler from './createOnConnectHandler';
import handleEvent from './handleEvent';

import {
  setIsConnected,
} from '@slices/socket';

let lastSocket = null;

function initialize(userId) {
  store.dispatch(setIsConnected(false));

  if (lastSocket) {
    lastSocket.close()
  }

  const socket = new WebSocket(config.url);
  lastSocket = socket;

  const onConnectHandler = createOnConnectHandler(socket, userId);

  socket.addEventListener('open', onConnectHandler);
  socket.addEventListener('message', handleEvent);
}

export default initialize;