import UserModel from "@mappers/UserModel";

class ConfirmationModel {
  static default = {
    id: -1,
    name: null,
    status: -1,
  }

  static parse(response) {
    if (!response) return ConfirmationModel.default;
    return {
      id: String(response.id),
      name: response.name,
      status: response.status,
    };
  }
}

export default ConfirmationModel;