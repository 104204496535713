import React from 'react';

import {
  useDeletePaymentMutation,
} from '@api/payments';

import {
  useState,
  useCallback,
  useEffect,
} from 'react';

import {
  DeletePaymentPopup,
} from '@components/index';

function WithDeletePaymentPopup(Component) {
  function NewComponent({...props}) {
    const [popupData, setPopupData] = useState(-1);

    const [
      triggerDeletePayment,
      deletePaymentResult,
    ] = useDeletePaymentMutation();

    const {
      isSuccess,
      isLoading,
    } = deletePaymentResult || {};

    const showHandler = useCallback(
      (id) => handleShow(id, popupData, setPopupData),
      [popupData],
    );

    const hideHandler = useCallback(
      () => handleHide(setPopupData), 
      [],
    );

    const deleteHandler = useCallback(
      () => triggerDeletePayment(popupData.id), 
      [popupData.id],
    );
    
    useEffect(
      () => handleSuccess(isSuccess, isLoading, hideHandler),
      [isSuccess, isLoading, hideHandler],
    );

    return (
      <>
        <Component
          {...props}
          onShowDeletePaymentPopup={showHandler}
        />
        <DeletePaymentPopup
          isVisible={!!popupData.isVisible}
          isLoading={isLoading}
          onCloseButtonClick={hideHandler}
          onCancelButtonClick={hideHandler}
          onDeleteButtonClick={deleteHandler}
        />
      </>
    );
  }

  return NewComponent;
}

function handleShow(id, popupData, setPopupData) {
  if (id < 0) return;
  if (popupData.isVisible) return;

  setPopupData({
    id: id,
    isVisible: true,
  });
}

function handleHide(setPopupData) {
  setPopupData((prevState) => ({
    ...prevState,
    isVisible: false,
  }));
}

function handleSuccess(isSuccess, isLoading, hideHandler) {
  if (isLoading) return;
  if (!isSuccess) return;
  hideHandler();
}

export default WithDeletePaymentPopup;