import './PDFViewer.scss';

import React from 'react';
import PropTypes from 'prop-types';

import PDFViewer from "./PDFViewer";

import {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';

PDFViewerContainer.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.arrayOf(PropTypes.object),
};

/**
 * @param className
 * @param pages should be immutable or will case errors
 * @param props
 */
// TODO: refactor to handle "pages" mutation properly
function PDFViewerContainer({className, pages, ...props}) {

  const [isMagnifierVisible, setIsMagnifierVisible] = useState(false);
  const lastCursorPositionRef = useRef(null);
  const scrollContainerRef = useRef();
  const pfdRef = useRef();
  const magnifierContainerRef = useRef();
  const magnifierCanvasRef = useRef();
  const canvasesRefs = useRef([]);

  const canvases = useMemo(
    () => getCanvases(pages, canvasesRefs),
    [pages, pages?.length, canvasesRefs.current],
  );

  const onPdfMouseLeaveHandler = useCallback(
    () => setIsMagnifierVisible(false),
    [],
  );

  const onMagnifierMouseMoveHandler = useCallback(
    (e) => handleOnMagnifierMouseMove(
      e,
      pfdRef.current,
      lastCursorPositionRef,
      magnifierContainerRef.current,
      magnifierCanvasRef.current,
      pages,
      canvases,
    ),
    [
      pfdRef.current,
      lastCursorPositionRef,
      magnifierContainerRef.current,
      magnifierCanvasRef.current,
      pages,
      canvases,
    ],
  );

  const onScrollContainerScrollHandler = useCallback(
    () => handleOnScrollContainerScroll(
      lastCursorPositionRef,
      pfdRef.current,
      magnifierContainerRef.current,
      magnifierCanvasRef.current,
      pages,
      canvases,
    ),
    [
      lastCursorPositionRef,
      pfdRef.current,
      magnifierContainerRef.current,
      magnifierCanvasRef.current,
      pages,
      canvases,
    ],
  );

  const onMagnifierContainerClickHandler = useCallback(
    (e) => handleOnMagnifierContainerClick(
      e,
      canvasesRefs.current,
      mapEventIntoPoint,
      getHoveredCanvas,
      setIsMagnifierVisible
    ),
    [
      canvasesRefs.current,
      mapEventIntoPoint,
      getHoveredCanvas,
      setIsMagnifierVisible
    ],
  );

  useEffect(
    () => renderPages(canvasesRefs.current, pages),
    [canvasesRefs.current, pages],
  );

  useEffect(
    () => updateMagnifierVisibility(magnifierCanvasRef.current, isMagnifierVisible),
    [magnifierCanvasRef.current, isMagnifierVisible]
  );

  return <PDFViewer
    {...props}
    className={className}
    pages={pages}
    canvases={canvases}
    onMagnifierContainerClick={onMagnifierContainerClickHandler}
    onMagnifierMouseMove={onMagnifierMouseMoveHandler}
    onPdfMouseLeave={onPdfMouseLeaveHandler}
    onScrollContainerScroll={onScrollContainerScrollHandler}
    magnifierCanvasRef={magnifierCanvasRef}
    magnifierContainerRef={magnifierContainerRef}
    scrollContainerRef={scrollContainerRef}
    pfdRef={pfdRef}
  />;
}

function updateMagnifierVisibility(magnifierCanvas, isMagnifierVisible) {
  if (!magnifierCanvas) return;

  const display = isMagnifierVisible ? "block" : 'none';

  magnifierCanvas.style.display = display;
}

function renderPages(canvases, pages) {
  if (!canvases || !pages || !pages?.length) return;

  canvases.map((c, key) => {
    const
      scale = 1,
      currentPage = pages[key],
      currentCanvas = c.current,
      viewport = currentPage.getViewport({scale}),
      context = c.current.getContext('2d');

    currentCanvas.height = viewport.height;
    currentCanvas.width = viewport.width;

    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };

    currentPage.render(renderContext);
  });
}

function handleOnMagnifierContainerClick(e, canvases, mapEventIntoPoint, getHoveredCanvas, setIsMagnifierVisible) {

  const canvas = getHoveredCanvas(canvases, mapEventIntoPoint(e));

  if (!canvas) return;

  setIsMagnifierVisible(prevState => !prevState);
}

function handleOnScrollContainerScroll(lastCursorPositionRef, pdf, magnifierContainer, magnifier, pages, canvases) {

  if (!lastCursorPositionRef.current) return;

  renderMagnifier({
    cursorPosition: lastCursorPositionRef.current,
    pdf: pdf,
    magnifierContainer: magnifierContainer,
    magnifier: magnifier,
    pages: pages,
    canvases: canvases,
  });
}

function handleOnMagnifierMouseMove(e, pdf, lastCursorPositionRef, magnifierContainer, magnifier, pages, canvases) {
  const cursorPosition = mapEventIntoPoint(e);

  lastCursorPositionRef.current = cursorPosition;

  renderMagnifier({
    cursorPosition: lastCursorPositionRef.current,
    pdf: pdf,
    magnifierContainer: magnifierContainer,
    magnifier: magnifier,
    pages: pages,
    canvases: canvases,
  });
}

function renderMagnifier({cursorPosition, magnifier, pages, pdf, magnifierContainer, canvases}) {
  if (!pdf || !magnifier || !pages?.length || !cursorPosition) return;

  const
    magnifierContext = magnifier.getContext('2d'),
    viewport = pages[0].getViewport({scale: 1});

  const hoveredPage = getHoveredCanvas(canvases, cursorPosition);

  const cursorStyle = hoveredPage ? 'zoom-in' : 'default';
  magnifierContainer.style.cursor = cursorStyle;

  if (!hoveredPage) return;

  const
    pageBoundingClient = hoveredPage.getBoundingClientRect(),
    pdfBounding = pdf.getBoundingClientRect(),
    zoomWidth = 200,
    zoomHeight = 200;

  const coefficient = pageBoundingClient.width / viewport.width;

  magnifierContext.fillStyle = "#eee";
  magnifierContext.fillRect(0,0, magnifier.width, magnifier.height);

  magnifierContext.drawImage(
    hoveredPage,
    (cursorPosition.x - pageBoundingClient.left + 16/4 )/coefficient - zoomWidth/2,
    (cursorPosition.y - pageBoundingClient.top + 16)/coefficient - zoomHeight/2 ,
    zoomWidth,
    zoomHeight,
    0,
    0,
    zoomWidth * (1 + coefficient),
    zoomHeight,
  );

  magnifier.style.width = zoomWidth + 'px';
  magnifier.style.height = zoomHeight + 'px';
  magnifier.style.top = - pdfBounding.top + cursorPosition.y - zoomHeight/2 + "px";
  magnifier.style.left = - pdfBounding.left +  cursorPosition.x - zoomWidth/2 + "px";
}

function getHoveredCanvas(canvases, point) {
  if  (!canvases) return;

  let hoveredCanvasRef = canvases.find(canvasRef => {
    if (!canvasRef.current) return;

    return getIsPointLiesInBoundingClientArea(point, canvasRef.current.getBoundingClientRect());
  });

  if (!hoveredCanvasRef || !hoveredCanvasRef.current) return;

  return hoveredCanvasRef.current;
}

function getIsPointLiesInBoundingClientArea(point, boundaryClient) {
  return point.x >= boundaryClient.x
    && point.x <= boundaryClient.x + boundaryClient.width
    && point.y >= boundaryClient.y
    && point.y <= boundaryClient.y + boundaryClient.height;
}

function mapEventIntoPoint(e) {
  return {
    x: e.clientX,
    y: e.clientY,
  };
}

function getCanvases(pages, canvasesRefs) {
  if (!pages) return;

  return pages.map(function(p, key) {
    const oldRef = canvasesRefs.current[key];
    const ref = oldRef || React.createRef();

    canvasesRefs.current[key] = ref;

    return ref;
  });
}

export default PDFViewerContainer;