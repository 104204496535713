import {
  useMemo,
} from 'react';

import {
  useLocation,
} from 'react-router-dom';

function useQueryParams() {
  const {search} = useLocation();

  const query = useMemo(
    () => createQueryParams(search),
    [search],
  );


  return query;
}

function createQueryParams(search) {
  return new URLSearchParams(search);
}

export default useQueryParams;