import './LogPopup.scss';

import React from "react";
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

import utils from '@utils/index';
import types from '@types/index';

import {
  AutoGeneratedTable,
  Popup,
} from "@components/index";

LogPopup.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  table: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

function LogPopup({
                    isVisible,
                    isLoading,
                    table,
                    onClose,
                    ...props
                  }) {

  const {t} = useTranslation();

  return (
    <Popup
      {...props}
      isVisible={isVisible}
      onBackgroundClick={onClose}
    >
      <Popup.Container className={'log-popup__container'}>
        <Popup.Header
          className={'log-popup__header'}
          title={t('Log file')}
          onCloseButtonClick={onClose}
        />
        <Popup.Content
          className={'log-popup__content'}
          isLoading={isLoading}
        >
          <AutoGeneratedTable
            className={'log-popup__table'}
            isLoading={isLoading}
            table={table}
            onSubmit={utils.doNothingHandler}
          />
        </Popup.Content>
      </Popup.Container>
    </Popup>
  );
}

export default LogPopup;