import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

import {
  Popup,
  Button,
} from '@components/index';

import './DeletePaymentPopup.scss';

DeletePaymentPopup.propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  onCloseButtonClick: PropTypes.func.isRequired,
  onCancelButtonClick: PropTypes.func.isRequired,
  onDeleteButtonClick: PropTypes.func.isRequired,
};

function DeletePaymentPopup ({className, isVisible, isLoading,
                               onCloseButtonClick, onCancelButtonClick, onDeleteButtonClick, ...props}) {

  const {t} = useTranslation();

  return (
    <Popup
      {...props}
      isVisible={isVisible}
      isSmall={true}
      onBackgroundClick={onCloseButtonClick}
    >
      <Popup.Container>
        <Popup.Header title={t('Delete Payment')} onCloseButtonClick={onCloseButtonClick}/>
        <Popup.Content
          className={'delete-payment-popup__content'}
          isLoading={isLoading}
        >
          <p className={'delete-payment-popup__question'}>{t('Are you sure you want to delete this item?')}</p>
        </Popup.Content>
        <Popup.Footer>
          <Button
            className={'delete-payment-popup__button'}
            type={Button.type.OUTLINE}
            onClick={onCancelButtonClick}
          >
            {t('Cancel')}
          </Button>
          <Button
            className={'delete-payment-popup__button'}
            type={Button.type.DANGEROUS}
            isLoading={isLoading}
            onClick={onDeleteButtonClick}
          >
            {t('Delete')}
          </Button>
        </Popup.Footer>
      </Popup.Container>
    </Popup>
  );
}

export default DeletePaymentPopup;