import React from 'react';
import PropTypes from 'prop-types';

import Cell from "@components/Table/Cell";
import TitleCell from "@components/Table/cells/TitleCell";
import Row from "@components/Table/Row";
import HeaderRow from "@components/Table/HeaderRow";

import './Table.scss';

Table.propTypes = {
  className: PropTypes.string,
};

export default function Table({className, children, ...props}) {
  return (
    <div
      {...props}
      className={[
        'table',
        className,
      ].join(' ')}
    >
      {children}
    </div>
  );
}

Table.HeaderRow = HeaderRow;
Table.TitleCell = TitleCell;
Table.Row = Row;
Table.Cell = Cell;


