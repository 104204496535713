import PropTypes from "prop-types";

const options = {
  QUICK_DESCRIPTION: 'QUICK_DESCRIPTION',
};

const LogColumn = {
  propType: PropTypes.oneOf(Object.values(options)),
  type: options,
};

export default LogColumn;

