import mapInvoiceFilePath from "./mapInvoiceFilePath";
import mapAmount from "./mapAmount";

export default function mapInvoice(invoice) {
  return {
    id: String(invoice.id),
    filePath: mapInvoiceFilePath(invoice.file_path),
    info: {
      receiver: createField('Receiver', invoice.receiver),
      number: createField('Invoice Number', invoice.invoice_number),
      iban: createField('IBAN', invoice.iban),
      date: createField('Date', invoice.date, 'Calendar'),
      amount: createField('Amount', mapAmount(invoice.amount)),
      tax: createField('Tax', mapAmount(invoice.tax)),
      description: createField('Description', invoice.description),
    },
    amount: {
      netAmount: createField('Net Amount', mapAmount(invoice.net_amount)),
      tax: createField('Tax', mapAmount(invoice.tax)),
      bruttoAmount: createField('Brutto Amount', mapAmount(invoice.amount)),
    },
  }
}

function createField(label, value, svgIconName) {
  return {label, value, svgIconName};
}