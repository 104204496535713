import React from 'react';
import PropTypes from 'prop-types';

import {
  RadioButton,
} from '@components/index';

import '../Table.scss';

RadioButtonCell.propTypes = {
  className: PropTypes.string,
  value: PropTypes.bool.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

function RadioButtonCell({className, value, name, onChange, onBlur, onFocus, ...props}) {
  return (
    <div
      {...props}
      className={[
        'table__radio-button-container-cell',
        className,
      ].join(' ')}
    >
      <RadioButton
        {...{
          value, name, onChange, onBlur, onFocus,
        }}
      />
    </div>
  );
}

export default RadioButtonCell;