import PropTypes from 'prop-types'

const options = {
  PRIMARY: 'PRIMARY',
  DANGEROUS: 'DANGEROUS',
  OUTLINE: 'OUTLINE',
  PRIMARY_OUTLINE: 'PRIMARY_OUTLINE',
};

const ButtonType = {
  propType: PropTypes.oneOf(Object.values(options)),
  type: options,
};

export default ButtonType;