import './AuthForm.scss';

import React from 'react';
import PropTypes from 'prop-types';

import Redirect from './Redirect';

RedirectionLink.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  link: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

function RedirectionLink({className, label, link, to, ...props}) {
  return (
    <p
      {...props}
      className={[
        'auth-form__redirection-link',
        className,
      ].join(' ')}
    >
      {
        label
          ? (
            <span
              className={'auth-form__text-font'}
            >
              {label}
            </span>
          )
          : null
      }
      <Redirect
        to={to}
      >
        {link}
      </Redirect>
    </p>
  );
}

export default RedirectionLink;