function mapImportTransactionResponse(response) {
  if (!response) {
    return {
      message: '',
    };
  }

  return {
    message: response.message,
    code: response.code,
  };
}

export default mapImportTransactionResponse;