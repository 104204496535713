export const types = {
  frontend: {
    AUTH: 'auth',
  },
  backend: {
    COUNTERS: 'counters',
    UPDATE_PAYMENT_COUNTER: 'payment_counter_update',
  },
};

export function auth(userId) {
  return {
    type: types.frontend.AUTH,
    payload: {
      user: {
        id: userId,
      },
    },
  };
}