import PropTypes from 'prop-types';

const options = {
  LARGE: 'LARGE',
  MEDIUM: 'MEDIUM',
  SMALL: 'SMALL',
};

const LoaderSizeType = {
  propType: PropTypes.oneOf(Object.values(options)),
  size: options,
};

export default LoaderSizeType;