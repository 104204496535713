import './AuthForm.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {
  Checkbox as DefaultCheckbox,
} from '@components/index';

Checkbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  checkboxProps: PropTypes.object.isRequired,
};

function Checkbox({className, label, checkboxProps, ...props}) {
  return (
    <label
      {...props}
      className={[
        'auth-form__checkbox',
        className,
      ].join(' ')}
    >
      <DefaultCheckbox checkboxProps={checkboxProps}/>
      <span className={'auth-form__checkbox-label'}>{label}</span>
    </label>
  );
}

export default Checkbox;