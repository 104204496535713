import React from 'react';
import PropTypes from 'prop-types';

import './Popup.scss';

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

function Container({className, children, ...props}) {
  return (
    <div
      {...props}
      className={[
        'popup__content-container',
        className
      ].join(' ')}
    >
      {children}
    </div>
  );
}

export default Container;