import {createSlice} from "@reduxjs/toolkit";
import {nanoid} from "nanoid";

import {authApi} from "@api/authApi";

const initialState = {
  isConnected: false,
  salaryCounter: 0,
  paymentCounter: 0,
};

const socket = createSlice({
  name: 'socket',
  initialState: initialState,
  reducers: {

    setIsConnected(state, action) {
      state.isConnected = action.payload;
    },

    setCounters: {
      reducer(state, action) {
        state.salaryCounter = action.payload.salaryCounter;
        state.paymentCounter = action.payload.paymentCounter;
      },
      prepare(salaryCounter, paymentCounter) {
        return {
          payload: {
            salaryCounter,
            paymentCounter,
          },
        };
      },
    },

    setPaymentCounter(state, action) {
      state.paymentCounter = action.payload;
    },

  },
  extraReducers: (builder) => {

    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      function resetSocketData(state) {
        state = initialState;
        return state;
      },
    );

  }
});

export default socket.reducer;

export const {
  setIsConnected,
  setCounters,
  setPaymentCounter,
} = socket.actions;

export const selectSalaryCounter = (state) => state.socket.salaryCounter;
export const selectPaymentCounter = (state) => state.socket.paymentCounter;
