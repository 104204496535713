export default function createQueryParams() {
  const params = {};

  const builder = {
    add(key, value) {
      if (key === null) return;
      params[key] = parseValue(value);
      return this;
    },
    finish() {
      return params;
    }
  };

  return builder;
}

function parseValue(value) {
  if (value === null) return '';
  if (Array.isArray(value)) return JSON.stringify(value);
  if (typeof value === 'object') return JSON.stringify(value);
  return value;
}