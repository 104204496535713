import {
  useCallback,
} from 'react';

import {
  useDispatch,
} from "react-redux";

function useWithDispatchCallback(actionCreator) {
  const dispatch = useDispatch();

  const withDispatchCallback = useCallback(
    (...args) => dispatch(actionCreator(...args)),
    [dispatch],
  );

  return withDispatchCallback;
}

export default useWithDispatchCallback;