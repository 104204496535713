import React from 'react';

import DefaultPaymentPopup from './DefaultPaymentPopup';

import {
  useState,
  useCallback,
} from 'react';

import {
  useLazyGetReceiverOptionsQuery,
  useLazyGetNameOptionsQuery,
  useLazyGetCreationDescriptionOptionsQuery,
  useLazyGetTypeTransferOptionsQuery,
  useGetPaymentCreationInvoicesQuery,
} from '@api/payments';

function DefaultPaymentPopupContainer({
                                        isFormSubmitting,
                                        isVisible,
                                        initialValues,
                                        initialReceiverOption,
                                        initialUserOption,
                                        initialDescriptionOption,
                                        initialTypeTransferOption,
                                        onSubmit,
                                        onHide,
                                        title,
                                      }) {

  const [info, setInfo] = useState({
    fields: {},
    initialValues,
    initialReceiverOption,
    initialUserOption,
    initialDescriptionOption,
    initialTypeTransferOption,
  });

  const [
    triggerGetReceiverOptions,
    {
      data: receiverOptions,
      isSuccess: isReceiverOptionsSuccess,
      isFetching: isReceiverOptionsFetching,
    },
  ] = useLazyGetReceiverOptionsQuery();

  const [
    triggerGetTypeTransferOptions,
    {
      data: typeTransferOptions,
      isSuccess: isTypeTransferOptionsSuccess,
      isFetching: isTypeTransferOptionsFetching,
    },
  ] = useLazyGetTypeTransferOptionsQuery();

  const [
    triggerGetUserOptions,
    {
      data: userOptions,
      isSuccess: isUserOptionsSuccess,
      isFetching: isUserOptionsFetching,
    },
  ] = useLazyGetNameOptionsQuery();

  const [
    triggerGetCreationDescriptionOptions,
    {
      data: creationDescriptionOptions,
      isSuccess: isCreationDescriptionOptionsSuccess,
      isFetching: isCreationDescriptionOptionsFetching,
    },
  ] = useLazyGetCreationDescriptionOptionsQuery();

  const onGetUserOptionsHandler = useCallback(
    (deps) => triggerGetUserOptions({receiverType: deps.receiver}),
    [],
  );

  const onGetDescriptionOptionsHandler = useCallback(
    (deps) => triggerGetCreationDescriptionOptions({receiverName: deps.name}),
    [],
  );

  const getInvoicesHookArg = {
    ...info.fields.searchInvoiceFields,
    page: info.fields.page,
  };

  const {receiverName, description} = info.fields.searchInvoiceFields || {};

  const getInvoicesHookOptions = {
    skip: !receiverName ||description !== '3', // not "Reimbursement of receipts"
  };

  const {
    data: invoicesResponse,
    isFetching: areInvoicesFetching,
    isSuccess: isInvoicesSuccess,
  } = useGetPaymentCreationInvoicesQuery(getInvoicesHookArg, getInvoicesHookOptions);

  const {invoices, pagination} = invoicesResponse || {};

  const setInfoFieldHandler = useCallback(
    (field, value) => handleSetInfoField(field, value, setInfo),
    [],
  );

  const onPageChangeHandler = useCallback(
    (page) => setInfoFieldHandler('page', page),
    [setInfoFieldHandler],
  );

  const onSearchInvoiceFieldsChangeHandler = useCallback(
    (searchInvoiceFields) => {
      setInfoFieldHandler('searchInvoiceFields', searchInvoiceFields)
      setInfoFieldHandler('page', 0)
    },
    [setInfoFieldHandler],
  );

  const currentReceiverOptions = !isReceiverOptionsFetching && isReceiverOptionsSuccess
    ?  receiverOptions
    : [];

  const currentUserOptions = !isUserOptionsFetching && isUserOptionsSuccess
    ? userOptions
    : [];

  const currentCreationDescriptionOptions = !isCreationDescriptionOptionsFetching && isCreationDescriptionOptionsSuccess
    ? creationDescriptionOptions
    : [];

  const currentTypeTransferOptions = !isTypeTransferOptionsFetching && isTypeTransferOptionsSuccess
    ? typeTransferOptions
    : [];

  const currentInvoices = !areInvoicesFetching && isInvoicesSuccess
    ? invoices
    : [];

  const currentPagination = !areInvoicesFetching && isInvoicesSuccess
    ? pagination
    : {};

  const initialReceiverOptions = info.initialReceiverOption
    ? [info.initialReceiverOption]
    : [];

  const initialUserOptions = info.initialUserOption
    ? [info.initialUserOption]
    : [];

  const initialDescriptionOptions = info.initialDescriptionOption
    ? [info.initialDescriptionOption]
    : [];

  const initialTypeTransferOptions = info.initialTypeTransferOption
    ? [info.initialTypeTransferOption]
    : [];

  const finalReceiverOptions = receiverOptions
    ? currentReceiverOptions
    : initialReceiverOptions;

  const finalUserOptions = userOptions
    ? currentUserOptions
    : initialUserOptions;

  const finalCreationDescriptionOptions = creationDescriptionOptions
    ? currentCreationDescriptionOptions
    : initialDescriptionOptions;

  const finalTypeTransferOptions = typeTransferOptions
    ? currentTypeTransferOptions
    : initialTypeTransferOptions;

  return (
    <DefaultPaymentPopup
      isVisible={isVisible}
      title={title}
      initialValues={info.initialValues}
      receiverOptions={finalReceiverOptions}
      userOptions={finalUserOptions}
      descriptionOptions={finalCreationDescriptionOptions}
      typeTransferOptions={finalTypeTransferOptions}
      isReceiverOptionsLoading={isReceiverOptionsFetching}
      isUserOptionsLoading={isUserOptionsFetching}
      isDescriptionOptionsLoading={isCreationDescriptionOptionsFetching}
      isTypeTransferOptionsLoading={isTypeTransferOptionsFetching}
      isFormSubmitting={isFormSubmitting}
      invoices={currentInvoices}
      pagination={currentPagination || {}}
      areInvoicesLoading={areInvoicesFetching}
      onPageChange={onPageChangeHandler}
      onCloseButtonClick={onHide}
      onSearchInvoiceFieldsChange={onSearchInvoiceFieldsChangeHandler}
      onFormSubmit={onSubmit}
      onGetReceiverOptions={triggerGetReceiverOptions}
      onGetTypeTransferOptions={triggerGetTypeTransferOptions}
      onGetNameOptions={onGetUserOptionsHandler}
      onGetDescriptionOptions={onGetDescriptionOptionsHandler}
    />
  );
}

function handleSetInfoField(field, value, setInfo) {
  setInfo((prevState) => ({
    ...prevState,
    fields: {
      ...prevState.fields,
      [field]: value,
    },
  }));
}

export default DefaultPaymentPopupContainer;