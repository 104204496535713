import types from '@types/index';

export default function mapServerErrorDescription(serverError) {
  if (!serverError.data) return createNoFieldErrorText('data');
  if (!serverError.data.status && !serverError.status) return createNoFieldErrorText('data.status');
  if (serverError.data.code < 0) return createNoFieldErrorText('data.code');

  const status = serverError.data.status || serverError.status;
  const code = serverError.data.code;

  const error = types.Error.values.find(error => error.status === status && error.code === code)

  return error ? error.description : 'No description has been specified';
}

function createNoFieldErrorText(fieldName) {
  return `There is no '${fieldName}' field in the error`
}