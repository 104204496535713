import {api} from '@api/index';
import {authApi} from '@api/authApi';

import userReducer from '@slices/user';
import authReducer from '@slices/auth';
import errorsReducer from '@slices/errors';
import redirectionReducer from '@slices/redirection';
import socketReducer from '@slices/socket';
import localesReducer from '@slices/locales';

export default {
  [api.reducerPath]: api.reducer,
  [authApi.reducerPath]: authApi.reducer,
  user: userReducer,
  auth: authReducer,
  errors: errorsReducer,
  redirection: redirectionReducer,
  socket: socketReducer,
  locales: localesReducer,
};