import './Transactions.scss';

import React from 'react';
import i18next from "i18next";
import {useTranslation} from "react-i18next";
import {compose} from '@reduxjs/toolkit';
import {useLocation} from 'react-router-dom';

import types from '@types/index';
import svgs from "@assets/svgs";
import queryParams from '@assets/queryParams';

import createDefaultColumns from './createDefaultColumns';
import createCashRegisterColumns from './createCashRegisterColumns';

import {
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';

import {
  useQueryParams,
  useFiredOnceEffect,
  useTableObject,
} from '@hooks/index';

import {
  useGetBookingsStatusesQuery,
  useGetTransactionsCategoriesQuery,
  useLazyGetTransactionsQuery,
  useImportTransactionsMutation,
} from "@api/transactions";

import {
  WithConnectInvoicePopup,
  WithViewInvoicePopup,
  WithAddNewCashRegisterPopup,
} from '@hocs/index';

import {
  Button,
  HorizontalSelect,
  Table,
  Title,
  PaginatedTable,
  Loader,
  BlockProperty,
  ConfirmPopup,
  CashRegisterBlock
} from '@components/index';

const emptyBalance = {
  score: '-',
  lastImportDateTime: '-',
};

function Transactions({onShowConnectInvoicePopup, onShowViewInvoicePopup, showAddNewCashRegisterPopup}) {
  const BalanceSVG = svgs.Balance;

  const {pathname} = useLocation();

  const query = useQueryParams();

  const {t} = useTranslation();

  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [isSuccessImportPopupVisible, setIsSuccessImportPopupVisible] = useState(false);

  const {
    data: categories,
    isSuccess: areCategoriesGot,
  } = useGetTransactionsCategoriesQuery();

  const {
    data: bookingStatuses,
    isLoading: isBookingStatusesLoading,
  } = useGetBookingsStatusesQuery();

  const [
    triggerGetTransaction,
    transactionsResult,
    transactionsLastPromiseInfo,
  ] = useLazyGetTransactionsQuery();

  const {
    data: transactionsResponse,
    isFetching: areTransactionsFetching,
    isError: isTransactionsError,
  } = transactionsResult;

  const {
    transactions,
    balance,
    pagination,
  } = isTransactionsError ? {} : transactionsResponse || {};

  const [
    triggerImportTransactions,
    importTransactionsResult,
  ] = useImportTransactionsMutation();

  const {
    data: importTransaction,
    isSuccess: isImportTransactionSuccess,
    isLoading: isImportTransactionLoading,
    isError: isImportTransactionError
  } = importTransactionsResult || {};

  const tabs = useMemo(
  () => getTabs(categories, transactions, areTransactionsFetching),
  [categories, transactions, areTransactionsFetching, t],
  );

  const horizontalSelectOptions = useMemo(
    () => getHorizontalSelectOptions(tabs),
    [tabs],
  );

  const currentTab = useMemo(
    () => getCurrentTab(currentCategory, tabs),
    [currentCategory, tabs],
  );

  const currentBankId = useMemo(
    () => getCurrentBankId(tabs, currentCategory),
    [tabs, currentCategory]
  );

  const CurrentBalance = useMemo(
    () => getBalance(areTransactionsFetching, balance),
    [areTransactionsFetching, balance],
  );

  const formSubmitHandler = useCallback(
    (fields) => handleGetTransaction(transactionsLastPromiseInfo, currentBankId, 0, fields),
    [transactionsLastPromiseInfo, currentBankId],
  );

  const setCurrentPageHandler = useCallback(
    (currentPage) => handleGetTransaction(transactionsLastPromiseInfo, currentBankId, currentPage, {}),
      [transactionsLastPromiseInfo, currentBankId],
  );

  const onTabChangeHandler = useCallback(
    (e, value) => handleOnTabChange(e, value, categories),
    [categories],
  );

  const onInvoiceLinkClickHandler = useCallback(
    (event, id) => handleOnInvoiceLinkClick(event, id, onShowViewInvoicePopup),
    [onShowViewInvoicePopup],
  );

  const onInvoiceAddButtonClickHandler = useCallback(
    (event, payload) => handleOnInvoiceAddButtonClick(event, payload, onShowConnectInvoicePopup),
    [onShowConnectInvoicePopup],
  );

  const onImportButtonClickHandler = useCallback(
    () => handleOnImportButtonClick(currentTab, triggerImportTransactions),
    [currentTab],
  );

  const hideSuccessImportPopupCallback = useCallback(
    () => setIsSuccessImportPopupVisible(false),
    [],
  );

  const componentsAdditionalProps = useMemo(
    () => createComponentsAdditionalProps(onInvoiceAddButtonClickHandler, onInvoiceLinkClickHandler),
    [onInvoiceAddButtonClickHandler, onInvoiceLinkClickHandler],
  )

  const columnsAdditionalProps = useMemo(
    () => createColumnsAdditionalProps(bookingStatuses, isBookingStatusesLoading),
    [bookingStatuses, isBookingStatusesLoading],
  );

  const tableProps = useMemo(
    () => createTableProps(),
    [],
  );

  useEffect(
    () => fetchTransactionsByBankId(currentCategory),
    [currentCategory],
  );

  useEffect(
    () => handlePathnameChange(pathname, categories),
    [pathname, categories],
  );

  useEffect(
    () => handleSuccessImport(
      isImportTransactionSuccess,
      isImportTransactionLoading,
      isImportTransactionError,
      setIsSuccessImportPopupVisible,
    ),
    [isImportTransactionSuccess, isImportTransactionLoading, isImportTransactionError],
  );

  const isTriggerImportByUrlPossible = currentTab && currentTab.isImportAvailable;

  useFiredOnceEffect(
    () => handleTriggerImportByUrl(query, isTriggerImportByUrlPossible, onImportButtonClickHandler),
    [query, isTriggerImportByUrlPossible, onImportButtonClickHandler],
  );

  function getTabs(categories, transactions, areTransactionsFetching) {
    if (!categories) return;

    let rows = transactions || [];

    if (areTransactionsFetching) {
      rows = [];
    }

    return categories.map(category => {

      let columns = createDefaultColumns();

      // todo: refactor
      if (category.name === 'cash_register') {
        columns = createCashRegisterColumns();
      }

      const categoryCopy = {...category};

      if (category.name === 'cash_register' || category.name === 'all') {
        categoryCopy.label = i18next.t(category.label)
      }

      return {
        ...categoryCopy,
        table: {
          columns: columns,
          rows: rows,
        },
      };
    });
  }

  function handlePathnameChange(pathname, categories) {
    if (!categories) return;

    const foundCategory = categories.find(tab => {
      return '/transactions/' + tab.routeName === pathname;
    });

    const selectedCategory = foundCategory || tabs[0];

    setCurrentCategory(selectedCategory);
  }

  function getCurrentBankId(tabs, currentCategory) {
    if (!currentCategory) return;

    const category = categories?.find(c => c.routeName === currentCategory.routeName);

    if (!category) return;

    return category.bankId;
  }

  function handleGetTransaction(lastPromiseInfo, currentBankId, currentPage, fields) {

    const isId = !currentPage || currentPage > 0 || currentBankId === 0;

    if (!isId) return;

    let page = 0;

    if (typeof currentPage === 'number') {
      page = currentPage;
    }

    setCurrentPage(page);

    const options = {
      ...lastPromiseInfo.lastArg,
      ...fields,
      bankId: currentBankId,
      page: page,
    };

    triggerGetTransaction(options);
  }

  function handleOnTabChange(e, value, categories) {
    if (!tabs) return;

    const category = categories.find(tab => tab.routeName === value);
    setCurrentCategory(category);
  }

  function getCurrentTab(currentCategory, tabs) {
    if (!tabs) return;
    if (!currentCategory) return;

    return tabs.find(tab => tab.routeName === currentCategory.routeName);
  }

  function fetchTransactionsByBankId(currentCategory) {
    if (!currentCategory) return;

    handleGetTransaction({}, currentCategory.bankId, 0, {})
  }

  function getHorizontalSelectOptions(tabs) {
    if (!tabs) return;

    return tabs.map(tab => ({
      value: tab.routeName,
      label: tab.label,
    }));
  }

  function renderBalance(balance) {

    const comment = (
      <span>
        {t('Last import')}
        {': '}
        <span className={'transactions__last-import-date'}>{balance.lastImportDateTime}</span>
      </span>
    )

    return (props) => (
      <BlockProperty
        {...props}
        className={'transactions__balance'}
        iconClassName={'transactions__balance-icon'}
        label={t('Balance')}
        value={balance.score}
        comment={comment}
        Icon={svgs.Balance}
      />
    );
  }

  function renderCashRegisterBalance(balance) {

    const comment = (
      <span>
        {t('Last import')}
        {': '}
        <span className={'transactions__last-import-date'}>{balance.lastImportDateTime}</span>
      </span>
    )

    return (props) => (
      <CashRegisterBlock
        {...props}
        className={'transactions__balance'}
        iconClassName={'transactions__balance-icon'}
        label1={t('Start Cash Register Balance')}
        label2={t('Current Cash Register Balance')}
        value1={balance.startBalance}
        value2={balance.score}
        comment={comment}
        Icon={svgs.CashRegister}
      />
    );
  }

  function getBalance(areTransactionsFetching, balance) {
    if (areTransactionsFetching || !balance) {
      return renderBalance(emptyBalance);
    }

    if(currentCategory.isCashRegister){
      return renderCashRegisterBalance(balance);
    }

    return renderBalance(balance);
  }

  return (
    <div className={'transactions'}>
      <Title className={'transactions__title'}>Transactions</Title>
      <CurrentBalance/>
      <div className={'transactions__table-container'}>
        {
          areCategoriesGot && currentTab
            ? (
              <>
                <header className={'transactions__header'}>
                  <HorizontalSelect
                    localPath={'/transactions'}
                    options={horizontalSelectOptions}
                    value={currentTab.routeName}
                    onChange={onTabChangeHandler}
                  />
                  <div className={'transactions__buttons-container'}>
                    {
                      currentTab.isDepositAvailable
                        ? (
                          <Button
                            className={'transactions__header-button'}
                            onClick={showAddNewCashRegisterPopup}
                          >
                            {t('Deposit')}
                          </Button>
                        )
                        : null
                    }
                    {
                      currentTab.isImportAvailable
                        ? (
                          <Button
                            className={'transactions__header-button'}
                            isLoading={isImportTransactionLoading}
                            Icon={BalanceSVG}
                            onClick={onImportButtonClickHandler}
                          >
                            {t('Import')}
                          </Button>
                        )
                        : null
                    }
                  </div>
                </header>
                <PaginatedTable
                  key={currentCategory.routeName}
                  className={'transactions__table-wrapper'}
                  table={currentTab.table}
                  currentPage={currentPage}
                  totalCount={pagination?.totalCount}
                  pageSize={pagination?.pageSize}
                  isLoading={areTransactionsFetching}
                  tableProps={tableProps}
                  componentsAdditionalProps={componentsAdditionalProps}
                  columnsAdditionalProps={columnsAdditionalProps}
                  onSubmit={formSubmitHandler}
                  onCurrentPageChange={setCurrentPageHandler}
                />
                <ConfirmPopup
                  isVisible={isSuccessImportPopupVisible}
                  title={t('Import was successful')}
                  content={importTransaction ? importTransaction.message : ''}
                  onButtonClick={hideSuccessImportPopupCallback}
                  onCloseButtonClick={hideSuccessImportPopupCallback}
                />
              </>
            )
            : (
              <div className={'transactions__loader-container'}>
                <Loader/>
              </div>
            )
        }
      </div>
    </div>
  );
}

function handleOnInvoiceAddButtonClick(event, payload, onShowConnectInvoicePopup) {
  const {transactionId, openAmount} = payload;
  onShowConnectInvoicePopup(transactionId, openAmount);
}

function handleOnInvoiceLinkClick(event, id, onShowViewInvoicePopup) {
  onShowViewInvoicePopup(Number(id));
}

function handleOnImportButtonClick(currentTab, triggerImportTransactions) {
  if (!currentTab) return;
  if (!currentTab.isImportAvailable) return;

  triggerImportTransactions({
    bankId: currentTab.importBankId,
    href: window.location.href,
  });
}

function handleSuccessImport(
  isImportTransactionSuccess,
  isImportTransactionLoading,
  isImportTransactionError,
  setIsSuccessImportPopupVisible) {

  if (isImportTransactionLoading) return;

  if (isImportTransactionSuccess) {
    setIsSuccessImportPopupVisible(true);
  }
}

function createComponentsAdditionalProps(onInvoiceAddButtonClickHandler, onInvoiceLinkClickHandler) {
  return {
    [Table.Cell.type.INVOICE_LINKS]: {
      onAddButtonClick: onInvoiceAddButtonClickHandler,
      onLinkClick: onInvoiceLinkClickHandler,
    },
  };
}

function createColumnsAdditionalProps(bookingStatuses, isBookingStatusesLoading) {
  return {
    [types.DefaultTransactionColumnType.type.BOOKING]: {
      options: bookingStatuses,
      isLoading: isBookingStatusesLoading,
    },
  };
}

function createTableProps() {
  return {
    className: 'transactions__table',
  };
}

function handleTriggerImportByUrl(query, isTriggerImportByUrlPossible, onImportButtonClick) {
  if (!isTriggerImportByUrlPossible) return;

  const isTriggerRequired = query.get(queryParams.TRIGGER_IMPORT_ACTION);

  if (!isTriggerRequired) return;

  onImportButtonClick();

  return true;
}

export default compose(
  WithAddNewCashRegisterPopup,
  WithViewInvoicePopup,
  WithConnectInvoicePopup,
)(Transactions);