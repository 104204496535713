import './LoginFragment.scss';

import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {useTranslation} from "react-i18next";

import errors from '@yup/errors';
import validators from '@yup/index';

import FormFields from './FormFields';

import {
  useCurrentErrorSelector,
  useFormError,
} from '@hooks/index';

import {
  useEffect,
} from 'react';

import {
  Formik,
  Form,
} from 'formik';

import {
  useHistory,
} from 'react-router-dom';

import {
  AuthForm,
} from '@components/index';

import {
  useLazyLoginQuery,
} from '@api/authApi';

const LoginSchema = Yup.object().shape({
  [FormFields.EMAIL]: Yup
    .string()
    .required(errors.required)
    .concat(validators.email),
  [FormFields.PASSWORD]: Yup
    .string()
    .required(errors.required),
});

const initialValues = {
  [FormFields.EMAIL]: '',
  [FormFields.PASSWORD]: '',
  'form': '',
};

LoginFragment.propTypes = {
  className: PropTypes.string,
  pathToRegistration: PropTypes.string.isRequired,
  pathToForgotPassword: PropTypes.string.isRequired,
};

function LoginFragment({className, pathToRegistration, pathToForgotPassword, pathToMainScreen, ...props}) {

  const {t} = useTranslation();

  const history = useHistory();

  const [
    triggerLoginQuery,
    result,
  ] = useLazyLoginQuery();

  const {
    isSuccess,
    isFetching,
  } = result || {};

  const error = useCurrentErrorSelector(result);

  useEffect(
    () => handleSuccessLogin(isSuccess, isFetching, pathToMainScreen, history),
    [isSuccess, isFetching, pathToMainScreen, history],
  );

  return (
    <AuthForm
      {...props}
      className={[
        'login-fragment',
        className,
      ].join(' ')}
      title={t('login-title')}
      description={t('login-description')}
    >
      <Formik
        validationSchema={LoginSchema}
        initialValues={initialValues}
        onSubmit={triggerLoginQuery}
      >
        {({errors, touched, handleSubmit, setFieldError}) => {

          useFormError(error, setFieldError);

          return (
            <Form>
              <AuthForm.InputField
                name={FormFields.EMAIL}
                type={'email'}
                autoComplete={'email'}
                label={t('Email')}
              />
              <AuthForm.InputField
                name={FormFields.PASSWORD}
                type={'password'}
                autoComplete={'current-password'}
                label={
                  <span className={'login-fragment__password-label'}>
                  <span>{t('Password')}</span>
                  <AuthForm.Text.Redirect to={pathToForgotPassword}>{t('login-forgot-password')}</AuthForm.Text.Redirect>
                </span>
                }
              />
              <AuthForm.FormError errors={errors} touched={touched}/>
              <AuthForm.Button
                onClick={handleSubmit}
                inputType={'submit'}
              >
                {t('Login')}
              </AuthForm.Button>
              {/* todo: return link to registration page */}
              {/*<AuthForm.RedirectionLink*/}
              {/*  label={t('login-no-account')}*/}
              {/*  link={t('Registration')}*/}
              {/*  to={pathToRegistration}*/}
              {/*/>*/}
            </Form>
          );
        }}
      </Formik>
    </AuthForm>
  );
}

function handleServerErrorUpdate(error, setFieldError) {
  if (!error || !error.fields) return;

  for (const field of error.fields) {
    if (!field.error) continue;
    setFieldError(field.name, field.error);
  }
}

function handleSuccessLogin(isSuccess, isFetching, pathToMainScreen, history) {
  if (!pathToMainScreen) return;

  if (isSuccess && !isFetching) {
    history.push(pathToMainScreen);
  }
}

export default LoginFragment;
