import i18next from "i18next";

import MissingDocumentColumn from '@types/MissingDocumentColumn';
import {createColumn} from "@utils/dataCreators";

function createMissingDocumentsColumns() {
  return {
    [MissingDocumentColumn.type.EMPLOYEE]: createColumn(
      MissingDocumentColumn.type.EMPLOYEE,
      i18next.t('Employee'),
      'dashboard__employee-cell',
    ),

    [MissingDocumentColumn.type.MISSING_FILES]: createColumn(
      MissingDocumentColumn.type.MISSING_FILES,
      i18next.t('Missing files'),
      'dashboard__missing-files-cell',
    ),
  };
}

export default createMissingDocumentsColumns;