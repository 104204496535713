import React from 'react';
import {nanoid} from 'nanoid';

import FormFields from "@components/AddNewCashRegisterPopup/FormFields";

import {
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';

import {
  useGetTransactionsBankOptionsQuery,
  useLazyGetBeingAddedToCashRegisterTransactionsQuery,
  useAddNewCashRegisterMutation,
} from '@api/transactions';

import {
  useCurrentErrorSelector,
  useCurrentDataSelector,
} from '@hooks/index';

import {
  AddNewCashRegisterPopup,
} from '@components/index';

function WithAddNewCashRegisterPopup(Component) {
  function NewComponentWithAddNewCashRegisterPopup(props) {

    const [popupInfo, setPopupInfo] = useState({});

    const getTransactionsBankOptionsResult = useGetTransactionsBankOptionsQuery();

    const {
      isFetching: areBankOptionsFetching,
    } = getTransactionsBankOptionsResult || {};

    const currentBankOptions = useCurrentDataSelector(getTransactionsBankOptionsResult);

    const [
      triggerGetTransactions,
      getTransactionsResult,
      lastGetTransactionPromiseInfo,
    ] = useLazyGetBeingAddedToCashRegisterTransactionsQuery();

    const {
      isFetching: isGetTransactionsFetching,
    } = getTransactionsResult;

    const currentTransactionsResponse = useCurrentDataSelector(getTransactionsResult);

    const {
      transactions,
      pagination,
    } = currentTransactionsResponse || {};

    const [
      triggerMoveTransactionIntoCashRegister,
      moveTransactionIntoCashRegisterResult,
    ] = useAddNewCashRegisterMutation();

    const {
      isSuccess: isMoveTransactionIntoCashRegisterSuccess,
      isLoading: isMoveTransactionIntoCashRegisterLoading,
    } = moveTransactionIntoCashRegisterResult;

    const moveTransactionIntoCashRegisterError = useCurrentErrorSelector(moveTransactionIntoCashRegisterResult);

    const showCallback = useCallback(
      () => handleShow(setPopupInfo),
      [],
    );

    const hideCallback = useCallback(
      () => handleHide(setPopupInfo),
      [],
    );

    const onGetTransactionsHandler = useCallback(
      (fields) => handleOnGetTransactions(fields, lastGetTransactionPromiseInfo, triggerGetTransactions),
      [lastGetTransactionPromiseInfo],
    );

    const handleOnPageChangeHandler = useCallback(
      (page) => handleOnPageChange(page, lastGetTransactionPromiseInfo, triggerGetTransactions),
      [lastGetTransactionPromiseInfo],
    );

    const onConnectButtonClickHandler = useCallback(
      (fields) => handleOnConnectButtonClick(fields, triggerMoveTransactionIntoCashRegister),
      [],
    );

    const hideCallbackRef = useRef(hideCallback);

    useEffect(
      () => handleSuccess(
        isMoveTransactionIntoCashRegisterSuccess,
        isMoveTransactionIntoCashRegisterLoading,
        hideCallbackRef,
      ),
      [
        isMoveTransactionIntoCashRegisterSuccess,
        isMoveTransactionIntoCashRegisterLoading,
      ],
    );

    return (
      <>
        <Component
          {...props}
          showAddNewCashRegisterPopup={showCallback}
        />
        <AddNewCashRegisterPopup
          key={popupInfo.key}
          isVisible={!!popupInfo.isVisible}
          bankOptions={currentBankOptions || []}
          areBankOptionsLoading={areBankOptionsFetching}
          transactions={transactions}
          pagination={pagination}
          areTransactionsLoading={isGetTransactionsFetching}
          isConnectButtonLoading={isMoveTransactionIntoCashRegisterLoading}
          onCloseButtonClick={hideCallback}
          onGetTransactions={onGetTransactionsHandler}
          onPageChange={handleOnPageChangeHandler}
          onConnectButtonClick={onConnectButtonClickHandler}
          submitError={moveTransactionIntoCashRegisterError}
        />
      </>
    );
  }

  return NewComponentWithAddNewCashRegisterPopup;
}

function handleShow(setPopupInfo) {
  setPopupInfo({
    key: nanoid(),
    isVisible: true,
  });
}

function handleHide(setPopupInfo) {
  setPopupInfo((prevState) => ({
    ...prevState,
    isVisible: false,
  }));
}

function handleOnGetTransactions(fields, lastGetTransactionPromiseInfo, triggerGetTransactions) {
  triggerGetTransactions({
    ...lastGetTransactionPromiseInfo.lastArg,
    ...fields,
  });
}
function handleOnPageChange(page, lastGetTransactionPromiseInfo, triggerGetTransactions) {
  triggerGetTransactions({
    ...lastGetTransactionPromiseInfo.lastArg,
    page: page,
  });
}

function handleSuccess(
  isMoveTransactionIntoCashRegisterSuccess,
  isMoveTransactionIntoCashRegisterLoading,
  hideCallbackRef,
) {
  if (isMoveTransactionIntoCashRegisterLoading) return;

  if (isMoveTransactionIntoCashRegisterSuccess) {
    hideCallbackRef.current();
  }
}

function handleOnConnectButtonClick(fields, triggerMoveTransactionIntoCashRegister) {
  triggerMoveTransactionIntoCashRegister(fields);
}

export default WithAddNewCashRegisterPopup;